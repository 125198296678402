import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";


class ApproveTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firestoreData: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
    };
  }

  componentDidMount() {}


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    // if(data.videoName === '') {
    //     alert("Select Video");
    //     document.getElementById("videoName").focus();
    //     return;
    // }

    // if(data.menu === 'Select') {
    //     alert("Select Status");
    //     document.getElementById("menu").focus();
    //     return;
    // }

    // firebase.firestore().collection('Videos').add({
    //     Name: data.videoName,
    //     Status: data.menu
    // })
    // .then(function(docRef) {
    //     comp.setState({
    //       success_msg: true
    //     })
    // })

    // data.videoName = ""
    // data.menu = "Select"
    // document.getElementById("create-form").reset()
    
}

// videoUpload = e =>{
        
//   if(e.target.files[0] === 0){
//      alert("Add Video");
//      return;
//  }

//  const ref = firebase.storage().ref("/Videos/");
//  const file = e.target.files[0];
//  const name = e.target.files[0] + Date();
//  const metadata = {
//  contentType: file.type
//  };
//  const task = ref.child(name).put(file, metadata);
//  task
//  .then(snapshot => snapshot.ref.getDownloadURL())
//  .then((url) => {
//    this.setState({
//      videoName: url
//    })
//  })
//  .catch(console.error);         
// }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Team" breadcrumbItem="Approve Team" />
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>User Id</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                    </tr>
                                                </thead>
                                                {/* <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.UserId}</td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.MobileNumber}</td>
                                                        <td>{rowData.DateofBirth}</td>
                                                        <td>{rowData.Followers}</td>
                                                        <td>{rowData.Following}</td>
                                                        <td>{rowData.Subscription}</td>
                                                        {rowData.SuperStar === "Yes" ?
                                                        <td>{rowData.SuperStar} <br /><br /><Button onClick = {this.superstarNo.bind(this, rowData)} color = "danger">No</Button></td> :
                                                        <td>{rowData.SuperStar} <br /><br /><Button onClick = {this.superstarYes.bind(this, rowData)} color = "success">Yes</Button></td> }
                                                        <td>{rowData.Referal} <br /> <br /><span style = {{color: "#ff0d00", cursor: "pointer"}} onClick = {this.referralOpen.bind(this, rowData)}><b>{rowData.ReferralCount}</b></span></td>
                                                        {rowData.Status === undefined || rowData.Status === "InActive" ?
                                                         <td><Button onClick = {this.unBlockUser.bind(this, rowData)} color = "success">UnBlock</Button></td> :
                                                         <td><Button onClick = {this.blockUser.bind(this, rowData)} color = "danger">Block</Button></td> }
                                                        <td><Button onClick = {this.moveToProfile.bind(this, rowData)} color = "primary">View Profile</Button></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody> */}
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default ApproveTeam;
import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";
// import GoogleMap from 'google-map-react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from 'react-google-maps';
import { compose, withProps } from 'recompose';

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}


class AgentRealtimeReport extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.funcMap = {
        '1': this.fetchList,
    };
    this.state = {
      firebaseData: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      agents: [],
      agentId: [],
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      banks: [],
      agents: [],
      products: [],
      bank: '',
      product: '',
      infoData: [],
      infoType: '',
      moveToView: false,
      moveToEdit: false,
      applicationId: '',
      startDate: '',
      endDate: '',
      markers: [
        {
            id:1,
            latitude: 25.0391667,
            longitude: 121.525,
            shelter:'marker 1'
    
        },
        {
            id: 2,
            latitude: 24.0391667,
            longitude: 110.525,
            shelter: 'marker 2'
    
        },
        {
            id: 3,
            latitude: 20.0391667,
            longitude: 100.525,
            shelter: 'marker 3'
    
        }
    ]
    };
  }

  componentDidMount() {
    let comp = this
    // firebase.firestore().collection("Agents")
    // .get().then((querySnapshot) => {
    //     var agents = [];
    //     querySnapshot.forEach(function(doc) {
    //         console.log(doc.data())
    //         if(doc.data().LocationCoordinates !== undefined) {
    //         const lock = {
    //             Name: doc.data().Name,
    //             Status: doc.data().Status,
    //             LocationCoordinates: doc.data().LocationCoordinates,
    //             PushId: doc.id
    //         }
    //         agents.push(lock)
    //     }
    //     });
    //     comp.setState({
    //       agents: agents
    //     })
    // });
    comp.fetchList()
  }

  async fetchList() {
      let comp = this
  
      var config = {
        method: 'get',
        url: API_BASE_URL + '/auth/agents',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        },
      };

      let agents = []
      
      axios(config)
      .then(function (response) {
          console.log(response.data)
          if(response.status == 200) {
            for(let i=0; i< response.data.length; i++) {
              if(response.data[i].locationcoordinates !== undefined) {
                const lock = {
                    Name: response.data[i].name,
                    Status: response.data[i].status,
                    LocationCoordinates: response.data[i].locationcoordinates,
                    PushId: response.data[i]._id
                }
                agents.push(lock)
            }
          }
          comp.setState({
            firebaseData: agents,
          })
        }
      })
      .catch(function (error) {
        console.log(error);
        comp.handleNon200Response(error, "1");
      });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  renderMarkers = (map, maps) => {
      console.log(this.state.agents)
      let marker
    for(let i=0; i< this.state.agents.length; i++) {
        console.log(maps[i].LocationCoordinates)
        console.log(maps[i].LocationCoordinates)
        marker = new maps.Marker({
        position: { lat: parseFloat(maps[i].LocationCoordinates.latitude), lng: parseFloat(maps[i].LocationCoordinates.longitude) },
        map,
        title: maps[i].Name
        })
    }
    return marker
 };

  agentsOnChange = agentId => {
    this.setState({ agentId });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }





  render() {

    const MapWithAMarkerClusterer = compose(
        withProps({
            googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBJzptPqnoVkUgJfL9x6kBnruQj_3VPQcA&v=3.exp&libraries=geometry,drawing,places",
            loadingElement: <div style={{ height: `100%` }} />,
            containerElement: <div style={{ height: `100%` }} />,
            mapElement: <div style={{ height: `100%` }} />,
        }),
        withScriptjs,
        withGoogleMap
    )(props =>
        <GoogleMap
            defaultZoom={6}
            defaultCenter={{ lat: 17.3850, lng: 78.4867 }}
        >
            {this.state.agents.map(marker => {
                //const onClick = props.onClick.bind(this, marker)
                return (
                    <Marker
                        key={marker.id}
                        position={{ lat: marker.LocationCoordinates.latitude, lng: marker.LocationCoordinates.longitude }}
                    >
                        <InfoWindow>
                            <div>
                                {marker.Name}
                            </div>
                        </InfoWindow>
                    </Marker>
                )
            })}
        </GoogleMap>
    );
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>

            <Breadcrumbs title="Reports" breadcrumbItem="Agent Realtime Report" />
                    <Row id = "case-list">
                        <Col lg={12}>
                            <Card className="w-full h-512 rounded-8 shadow-1">
                                <div style={{height: `500px`, width: '100%'}}>
                                <MapWithAMarkerClusterer />
                                    {/* <GoogleMap
                                    bootstrapURLKeys={{
                                        key: "AIzaSyBJzptPqnoVkUgJfL9x6kBnruQj_3VPQcA"
                                    }}
                                    defaultZoom={8}
                                    defaultCenter={[12.9716, 77.5946]}
                                    yesIWantToUseGoogleMapApiInternals
                                    // onGoogleApiLoaded={({ map, maps }) => {this.renderMarkers(map, maps)}}
                                    >
                                    {this.state.agents.map(marker => (
                                        <Marker
                                          position={{ lat: marker.LocationCoordinates.latitude, lng: marker.LocationCoordinates.longitude }}
                                          key={marker.id}
                                        />
                                    ))}
                                    </GoogleMap> */}
                                </div>
                            </Card>
                        </Col>
                    </Row>
          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default AgentRealtimeReport;
import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";


class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firestoreData: [],
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      pushid: '',

      applicationId: '',
      customerName: '',
      fi: '',
      productName: '',
      residenceAddress: '',
      officeAddress: '',
      permanentAddress: '',
      fiDate: '',
      fiFlag: '',
      fiTime: '',
      dob: '',
      loanAmount: '',
      agentName: '',
      menu: 'Select',

    };
  }

  componentDidMount() {
    let comp = this
    firebase.firestore().collection("LoanApplication").orderBy("TimeStamp")
    .onSnapshot(function(querySnapshot) {
        var task = [];
        querySnapshot.forEach(function(doc) {
          task.push(doc.data())
        });
        comp.setState({
          firebaseData: task
        })
    });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    if(data.applicationId === '') {
        alert("Enter Application Id");
        document.getElementById("applicationId").focus();
        return;
    }

    if(data.customerName === '') {
        alert("Enter Customer Name");
        document.getElementById("customerName").focus();
        return;
    }

    if(data.fi === '') {
        alert("Enter FI to be conducted");
        document.getElementById("fi").focus();
        return;
    }

    if(data.productName === '') {
        alert("Enter Product Name");
        document.getElementById("productName").focus();
        return;
    }

    if(data.residenceAddress === '') {
        alert("Enter Residence Address");
        document.getElementById("residenceAddress").focus();
        return;
    }

    if(data.fiDate === '') {
        alert("Select FI Date");
        document.getElementById("fiDate").focus();
        return;
    }

    if(data.fiTime === '') {
        alert("Enter FI Time");
        document.getElementById("fiTime").focus();
        return;
    }

    if(data.dob === '') {
        alert("Select Date of Birth");
        document.getElementById("dob").focus();
        return;
    }

    if(data.loanAmount === '') {
        alert("Enter Loan Amount");
        document.getElementById("loanAmount").focus();
        return;
    }

    if(data.menu === 'Select') {
        alert("Select Status");
        document.getElementById("menu").focus();
        return;
    }


    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  
  
     firebase.firestore().collection("LoanApplication").doc(data.applicationId).set({
        ApplicationId: data.applicationId,
        CustomerName: data.customerName,
        FI: data.fi,
        ProductName: data.productName,
        ResidenceAddress: data.residenceAddress,
        OfficeAddress: data.officeAddress,
        PermanentAddress: data.permanentAddress,
        FIDate: data.fiDate,
        FITime: data.fiTime,
        FIFlag: data.fiFlag,
        DateOfBirth: data.dob,
        LoanAmount: data.loanAmount,
        AgentName: data.agentName,
        TimeStamp: timestamp,
        Status: data.menu
      })
    .then(function(docRef) {
        comp.setState({
            applicationId: '',
            customerName: '',
            fi: '',
            productName: '',
            residenceAddress: '',
            officeAddress: '',
            permanentAddress: '',
            fiDate: '',
            fiFlag: '',
            fiTime: '',
            dob: '',
            loanAmount: '',
            agentName: '',
            menu: 'Select',
        })
        document.getElementById("create-form").reset()

        alert("Task Added Successfully")
    })
} 

updateRow(e) {
  e.preventDefault();

  let data = this.state

  let comp = this


  if(data.applicationId === '') {
      alert("Enter Application Id");
      document.getElementById("applicationId").focus();
      return;
  }

  if(data.customerName === '') {
      alert("Enter Customer Name");
      document.getElementById("customerName").focus();
      return;
  }

  if(data.fi === '') {
      alert("Enter FI to be conducted");
      document.getElementById("fi").focus();
      return;
  }

  if(data.productName === '') {
      alert("Enter Product Name");
      document.getElementById("productName").focus();
      return;
  }

  if(data.residenceAddress === '') {
      alert("Enter Residence Address");
      document.getElementById("residenceAddress").focus();
      return;
  }

  if(data.fiDate === '') {
      alert("Select FI Date");
      document.getElementById("fiDate").focus();
      return;
  }

  if(data.fiTime === '') {
      alert("Enter FI Time");
      document.getElementById("fiTime").focus();
      return;
  }

  if(data.dob === '') {
      alert("Select Date of Birth");
      document.getElementById("dob").focus();
      return;
  }

  if(data.loanAmount === '') {
      alert("Enter Loan Amount");
      document.getElementById("loanAmount").focus();
      return;
  }

  if(data.menu === 'Select') {
      alert("Select Status");
      document.getElementById("menu").focus();
      return;
  }


  const timestamp = firebase.firestore.FieldValue.serverTimestamp();


   firebase.firestore().collection("LoanApplication").doc(data.applicationId).set({
      ApplicationId: data.applicationId,
      CustomerName: data.customerName,
      FI: data.fi,
      ProductName: data.productName,
      ResidenceAddress: data.residenceAddress,
      OfficeAddress: data.officeAddress,
      PermanentAddress: data.permanentAddress,
      FIDate: data.fiDate,
      FITime: data.fiTime,
      FIFlag: data.fiFlag,
      DateOfBirth: data.dob,
      LoanAmount: data.loanAmount,
      AgentName: data.agentName,
      TimeStamp: timestamp,
      Status: data.menu
    })
  .then(function(docRef) {
      comp.setState({
          applicationId: '',
          customerName: '',
          fi: '',
          productName: '',
          residenceAddress: '',
          officeAddress: '',
          permanentAddress: '',
          fiDate: '',
          fiFlag: '',
          fiTime: '',
          dob: '',
          loanAmount: '',
          agentName: '',
          menu: 'Select',
      })
      document.getElementById("create-form").reset()

      alert("Task Updated Successfully")
  })

  document.getElementById("submit").style.display = "block"
  document.getElementById("update").style.display = "none"
}

editRow(key) {

  let data = this.state
  document.getElementById("submit").style.display = "none"
  document.getElementById("update").style.display = "block"

  this.setState({
    applicationId: key.ApplicationId,
    customerName: key.CustomerName,
    fi: key.FI,
    productName: key.ProductName,
    residenceAddress: key.ResidenceAddress,
    officeAddress: key.OfficeAddress,
    permanentAddress: key.PermanentAddress,
    fiDate: key.FIDate,
    fiTime: key.FITime,
    fiFlag: key.FIFlag,
    dob: key.DateOfBirth,
    loanAmount: key.LoanAmount,
    agentName: key.AgentName,
    menu: key.Status
  })


  
}

delete(key) {
  if (window.confirm('Are you sure you want to delete the loan application?')) {
      firebase.firestore().collection('LoanApplication').doc(key.ApplicationId).delete()
      alert("Successfully Deleted!")
    } else {}
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Tasks" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Application Id<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="applicationId"
                                                          placeholder="Application Id"
                                                          type="text"
                                                          className="form-control"
                                                          id="applicationId"
                                                          value = {this.state.applicationId}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Customer Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="customerName"
                                                          placeholder="Customer Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="customerName"
                                                          value = {this.state.customerName}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">FI to be conducted<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="fi"
                                                          placeholder="FI to be conducted"
                                                          type="text"
                                                          className="form-control"
                                                          id="fi"
                                                          value = {this.state.fi}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Product Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="productName"
                                                          placeholder="Product Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="productName"
                                                          value = {this.state.productName}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Residence Address<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="residenceAddress"
                                                          placeholder="Residence Address"
                                                          type="text"
                                                          className="form-control"
                                                          id="residenceAddress"
                                                          value = {this.state.residenceAddress}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Office Address</Label>
                                                        <Input
                                                          name="officeAddress"
                                                          placeholder="Office Address"
                                                          type="text"
                                                          className="form-control"
                                                          id="officeAddress"
                                                          value = {this.state.officeAddress}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Permanent Address</Label>
                                                        <Input
                                                          name="permanentAddress"
                                                          placeholder="Permanent Address"
                                                          type="text"
                                                          className="form-control"
                                                          id="permanentAddress"
                                                          value = {this.state.permanentAddress}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">FI Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="fiDate"
                                                          placeholder="FI Date"
                                                          type="date"
                                                          className="form-control"
                                                          id="fiDate"
                                                          value = {this.state.fiDate}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">FI Time<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="fiTime"
                                                          placeholder="FI Time"
                                                          type="text"
                                                          className="form-control"
                                                          id="fiTime"
                                                          value = {this.state.fiTime}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">FI Flag</Label>
                                                        <Input
                                                          name="fiFlag"
                                                          placeholder="FI Flag"
                                                          type="text"
                                                          className="form-control"
                                                          id="fiFlag"
                                                          value = {this.state.fiFlag}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Date Of Birth<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="dob"
                                                          placeholder="Date of Birth"
                                                          type="date"
                                                          className="form-control"
                                                          id="dob"
                                                          value = {this.state.dob}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Loan Amount<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="loanAmount"
                                                          placeholder="Loan Amount"
                                                          type="number"
                                                          className="form-control"
                                                          id="loanAmount"
                                                          value = {this.state.loanAmount}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Agent Name</Label>
                                                        <Input
                                                          name="agentName"
                                                          placeholder="Agent Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="agentName"
                                                          value = {this.state.agentName}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Status<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.menu} id = "menu" name = "menu" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "Select">Select Status</option>
                                                        <option value = "Active">Active</option>
                                                        <option value = "InActive">InActive</option>
                                                    
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                                <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                    Bank Successfully Created!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                  <SweetAlert
                                                    title="Great"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ update_msg: false })}
                                                  >
                                                    Bank Updated Successfully!
                                                  </SweetAlert>
                                                ) : null}     
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                                          <Col>
                                          <Card>
                                            <CardBody>
                                              

                                          <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>FI to be conducted</th>
                                                            <th>Product Name</th>
                                                            <th>Address</th>
                                                            <th>Loan Amount</th>
                                                            <th>Status</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.ApplicationId}</td>
                                                        <td>{rowData.CustomerName}</td>
                                                        <td>{rowData.FI}</td>
                                                        <td>{rowData.ProductName}</td> 
                                                        <td>{rowData.ResidenceAddress}</td> 
                                                        <td>{rowData.LoanAmount}</td>
                                                        <td>{rowData.Status}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Tasks;
import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
// import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';


class Blank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
    };
  }


  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* <Breadcrumbs title="Assign" breadcrumbItem="High Priority Assign" /> */}
            <Row>
                            <Col lg={12}>
                        
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Blank;
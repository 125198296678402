import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";


class DataMigration extends Component {
  constructor(props) {
    super(props);
    // this.submit = this.handleSubmit.bind(this);
    this.funcMap = {
        '2': '',
    };
    this.state = {
      bank: '',
      menu: '',
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      pushid: '',
      dataId: ''
    };
  }

  componentDidMount() {
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  defaultState() {
    this.setState({
      bank: '',
      menu: '',
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  bankMigration(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    firebase.firestore().collection("Bank")
    .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            if(doc.data().PushId !== undefined) {
                console.log("PushId", doc.data().PushId)
                var jsonData = JSON.stringify({
                  "name": doc.data().Name,
                  "status": doc.data().Status,
                  "pushid": doc.data().PushId,
                });
      
                var config = {
                  method: 'post',
                  url: API_BASE_URL + "/auth/bank",
                  headers: { 
                    'apikey': API_KEY, 
                    'Authorization': 'Bearer ' + getCookie('at'),
                    'Content-Type': 'application/json'
                  },
                  data : jsonData
                };
            
                axios(config)
                .then(function (response) {
                  // if(response.status == 201) {
                  //   comp.defaultState()
                  //   comp.fetchList()
                  //   alert("Added Successfully")
                  // }
                })
                .catch(function (error) {
                  console.log(error.response.data)
                  comp.handleNon200Response(error, "2")
                });
            }
       
        });
        alert("Done")
    });
} 


loanProductMigration(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    firebase.firestore().collection("LoanProduct")
    .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            if(doc.data().PushId !== undefined) {
                console.log("PushId", doc.data().PushId)
                var jsonData = JSON.stringify({
                  "name": doc.data().Name,
                  "status": doc.data().Status,
                  "pushid": doc.data().PushId,
                });
      
                var config = {
                  method: 'post',
                  url: API_BASE_URL + "/auth/loanproduct",
                  headers: { 
                    'apikey': API_KEY, 
                    'Authorization': 'Bearer ' + getCookie('at'),
                    'Content-Type': 'application/json'
                  },
                  data : jsonData
                };
            
                axios(config)
                .then(function (response) {
                  // if(response.status == 201) {
                  //   comp.defaultState()
                  //   comp.fetchList()
                  //   alert("Added Successfully")
                  // }
                })
                .catch(function (error) {
                  console.log(error.response.data)
                  comp.handleNon200Response(error, "2")
                });
            }
       
        });
        alert("Done")
    });
} 

stateMigration(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    firebase.firestore().collection("State")
    .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            if(doc.data().PushId !== undefined) {
                console.log("PushId", doc.data().PushId)
                var jsonData = JSON.stringify({
                  "name": doc.data().Name,
                  "status": doc.data().Status,
                  "pushid": doc.data().PushId,
                });
      
                var config = {
                  method: 'post',
                  url: API_BASE_URL + "/auth/state",
                  headers: { 
                    'apikey': API_KEY, 
                    'Authorization': 'Bearer ' + getCookie('at'),
                    'Content-Type': 'application/json'
                  },
                  data : jsonData
                };
            
                axios(config)
                .then(function (response) {
                  // if(response.status == 201) {
                  //   comp.defaultState()
                  //   comp.fetchList()
                  //   alert("Added Successfully")
                  // }
                })
                .catch(function (error) {
                  console.log(error.response.data)
                  comp.handleNon200Response(error, "2")
                });
            }
       
        });
        alert("Done")
    });
} 

salesMigration(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    firebase.firestore().collection("Sales")
    .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            if(doc.data().UserId !== undefined) {
                console.log("UserId", doc.data().UserId)

                var jsonData = JSON.stringify({
                    bank: doc.data().Bank,
                    branch: doc.data().Branch,
                    emailId: doc.data().EmailId,
                    location: doc.data().Location,
                    mobileNumber: doc.data().MobileNumber,
                    name: doc.data().Name,
                    password: doc.data().Password,
                    status: doc.data().Status,
                    timestamp: new Date(doc.data().TimeStamp),
                    userid: doc.data().UserId
                  });
              
                  var config = {
                    method: 'post',
                    url: API_BASE_URL + "/auth/sales",
                    headers: { 
                      'apikey': API_KEY, 
                      'Authorization': 'Bearer ' + getCookie('at'),
                      'Content-Type': 'application/json'
                    },
                    data : jsonData
                  };
            
                axios(config)
                .then(function (response) {
                  // if(response.status == 201) {
                  //   comp.defaultState()
                  //   comp.fetchList()
                  //   alert("Added Successfully")
                  // }
                })
                .catch(function (error) {
                  console.log(error.response.data)
                  comp.handleNon200Response(error, "2")
                });
            }
       
        });
        alert("Done")
    });
}

agentsMigration(e) {
  e.preventDefault();

  console.log("AGENTS=========================")

  let data = this.state

  let comp = this

  let agentsNumbersList = []

  var config = {
    method: 'get',
    url: API_BASE_URL + '/auth/agents',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json'
    },
  //   data: jsonData
  };
  
  axios(config)
  .then(function (response) {
      console.log(response.data)
      if(response.status == 200) {
        for(let i=0; i< response.data.length; i++) {
          agentsNumbersList.push(response.data[i].number)
        }
      }
  })


  setTimeout(function() {
    console.log("Agents Number List", agentsNumbersList.length)

    // let lastVisibleFromSession = JSON.parse(sessionStorage.getItem("lastVisible"))
    // console.log("lastVisibleFromSession", lastVisibleFromSession)

    firebase.firestore().collection("Agents")
    .orderBy("UserId", "asc")
    // .limit(1700)
    // .startAfter(lastVisibleFromSession)
    .onSnapshot(function(querySnapshot) {

      let remainingCount = 0

      // var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
      // console.log("last", lastVisible);
      // sessionStorage.setItem("lastVisible", JSON.stringify(lastVisible));

        querySnapshot.forEach(function(doc) {
            if(doc.data().UserId !== undefined) {
              if(agentsNumbersList.indexOf(doc.data().Number) == -1) {
                console.log("UserId", doc.data().UserId)

                remainingCount++
  
                var jsonData = JSON.stringify({
                  addressproof: doc.data().AddressProof,
                  adressproofimage: doc.data().AddressProofImage,
                  agentid: doc.data().AgentId == undefined ? Math.floor((Math.random() * (3000-2000 + 1) + 2000)) : doc.data().AgentId,
                  approvalstatus: doc.data().ApprovalStatus,
                  date: new Date(),
                  email: doc.data().Email,
                  imei: '',
                  idproof: doc.data().IdProof,
                  idproofimage: doc.data().IdProofImage,
                  joiningdate: new Date(doc.data().JoiningDate),
                  messagingtoken: doc.data().MessagingToken,
                  name: doc.data().Name,
                  number: doc.data().Number,
                  password: doc.data().Password,
                  state: doc.data().State,
                  userid: doc.data().UserId.substring(2, doc.data().UserId.length),
                  });
  
  
              
                  var config = {
                    method: 'post',
                    url: API_BASE_URL + "/api/registeration",
                    headers: { 
                      'apikey': API_KEY, 
                      'Authorization': 'Bearer ' + getCookie('at'),
                      'Content-Type': 'application/json'
                    },
                    data : jsonData
                  };
            
                axios(config)
                .then(function (response) {
                  // if(response.status == 201) {
                  //   comp.defaultState()
                  //   comp.fetchList()
                  //   alert("Added Successfully")
                  // }
                })
                .catch(function (error) {
                  console.log("UserId", doc.data().UserId)
                  console.log("AgentId", doc.data().AgentId)
                  console.log(error.response.data)
                  console.log("Error", error.response.data.data.keyValue)
                  comp.handleNon200Response(error, "2")
                });
              } 
            }
       
        });
        alert("Done")
        console.log("remainingCount", remainingCount)
    });
  }, 5000)
}

attendanceMigration(e) {
  e.preventDefault();

  console.log("===========ATTENDANCE==============")

  let data = this.state

  let comp = this

  let attendanceList = []

  var config = {
    method: 'get',
    url: API_BASE_URL + '/auth/attendance',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json'
    },
  //   data: jsonData
  };
  
  axios(config)
  .then(function (response) {
      console.log(response.data)
      if(response.status == 200) {
        for(let i=0; i< response.data.length; i++) {
          attendanceList.push(response.data[i].pushid)

        //   var config = {
        //     method: 'delete',
        //     url: API_BASE_URL + "/auth/attendance/" + response.data[i]._id ,
        //     headers: { 
        //       'apikey': API_KEY, 
        //       'Authorization': 'Bearer ' + getCookie('at'),
        //       'Content-Type': 'application/json'
        //     },
        //     // data : jsonData
        //   };
    
        // axios(config)
        // .then(function (response) {
        //   // if(response.status == 201) {
        //   //   comp.defaultState()
        //   //   comp.fetchList()
        //   //   alert("Added Successfully")
        //   // }
        // })
        //               .catch(function (error) {
        //                   // console.log("PushId", doc.data().PushId)
        //                   console.log(error.response.data)
        //                   // comp.handleNon200Response(error, "2")
        //                 });
        // }
        // alert("Deleted")
        }
      }
  })

  setTimeout(function() {
    console.log("attendanceList", attendanceList.length)

    firebase.firestore().collection("Attendance")
    // .limit(3000)
    .onSnapshot(function(querySnapshot) {
      let remainingCount = 0
        querySnapshot.forEach(function(doc) {
          if(attendanceList.indexOf(doc.data().PushId) == -1) {
            if(doc.data().PushId !== undefined) {
                console.log("PushId", doc.data().PushId)

                remainingCount++

                var jsonData = JSON.stringify({
                    allowance: doc.data().Allowance == '' ? 0 : doc.data().Allowance,
                    date: new Date(),
                    image: doc.data().Image,
                    in: doc.data().In,
                    inaddress: doc.data().InAddress,
                    inlat: doc.data().InLat,
                    inlong: doc.data().InLong,
                    intime: doc.data().InTime,
                    intimedate: doc.data().InTimeDate == undefined ? new Date() : new Date(doc.data().InTimeDate),
                    intimephoto: doc.data().InTimePhoto,
                    mobilenumber: doc.data().MobileNumber,
                    name: doc.data().Name,
                    out: doc.data().Out,
                    outaddress: doc.data().OutAddress,
                    outlat: doc.data().OutLat,
                    outlong: doc.data().OutLong,
                    outtime: doc.data().OutTime,
                    outtimedate: doc.data().OutTimeDate == undefined ? new Date() : new Date(doc.data().OutTimeDate),
                    outtimephoto: doc.data().OutTimePhoto,
                    pushid: doc.data().PushId,
                    timestamp: new Date(doc.data().TimeStamp),
                    type: doc.data().Type,
                    userid: doc.data().Userid == '' ? 'userid': doc.data().Userid
                  });
              
                  var config = {
                    method: 'post',
                    url: API_BASE_URL + "/auth/attendance",
                    headers: { 
                      'apikey': API_KEY, 
                      'Authorization': 'Bearer ' + getCookie('at'),
                      'Content-Type': 'application/json'
                    },
                    data : jsonData
                  };
            
                axios(config)
                .then(function (response) {
                  // if(response.status == 201) {
                  //   comp.defaultState()
                  //   comp.fetchList()
                  //   alert("Added Successfully")
                  // }
                })
                .catch(function (error) {
                  console.log("PushId", doc.data().PushId)
                  console.log(error.response.data)
                  comp.handleNon200Response(error, "2")
                });
            }
          }
        });
        alert("Done")
        console.log("remainingCount", remainingCount)
    });
  }, 10000)
}


webUserMigration(e) {
  e.preventDefault();

  console.log("===========WEB_USER==============")

  let data = this.state

  let comp = this

  let webUserList = []

  var config = {
    method: 'get',
    url: API_BASE_URL + '/auth/webUser',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json'
    },
  //   data: jsonData
  };
  
  axios(config)
  .then(function (response) {
      console.log(response.data)
      if(response.status == 200) {
        for(let i=0; i< response.data.length; i++) {
          webUserList.push(response.data[i].username)
        }
      }
  })

  setTimeout(function() {
    console.log("webUserList", webUserList.length)

    firebase.firestore().collection("WebUser")
    // .limit(3000)
    .onSnapshot(function(querySnapshot) {
      let remainingCount = 0
        querySnapshot.forEach(function(doc) {
          if(webUserList.indexOf(doc.data().UserName) == -1) {
            if(doc.data().UserName !== undefined) {
                console.log("UserName", doc.data().UserName)

                remainingCount++

                var jsonData = JSON.stringify({
                  username: doc.data().UserName,
                  name: doc.data().Name,
                  password: doc.data().Password,
                  role: doc.data().Role,
                  position: doc.data().Position,
                  status: doc.data().Status,
                  state: doc.data().State
                });
                
                var config = {
                  method: 'post',
                  url: API_BASE_URL + "/auth/webUser",
                  headers: { 
                    'apikey': API_KEY, 
                    'Authorization': 'Bearer ' + getCookie('at'),
                    'Content-Type': 'application/json'
                  },
                  data : jsonData
                };
            
                axios(config)
                .then(function (response) {
                  // if(response.status == 201) {
                  //   comp.defaultState()
                  //   comp.fetchList()
                  //   alert("Added Successfully")
                  // }
                })
                .catch(function (error) {
                  console.log(error.response.data)
                  comp.handleNon200Response(error, "2")
                });
            }
          }
        });
        alert("Done")
        console.log("remainingCount", remainingCount)
    });
  }, 10000)
}


  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Data Migration" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xl = {4}>
                                                <Button color="primary" onClick = {this.bankMigration.bind(this)} id = "submit" type="submit">Bank Migration</Button>
                                            </Col>
                                            <Col xl = {4}>
                                                <Button color="primary" onClick = {this.loanProductMigration.bind(this)} id = "submit" type="submit">Loan Product Migration</Button>
                                            </Col>
                                            <Col xl = {4}>
                                                <Button color="primary" onClick = {this.stateMigration.bind(this)} id = "submit" type="submit">State Migration</Button>
                                            </Col>
                                        </Row>
                                        <br />
                                        <br />
                                        <Row>
                                            <Col xl = {4}>
                                                <Button color="primary" onClick = {this.salesMigration.bind(this)} id = "submit" type="submit">Sales Migration</Button>
                                            </Col>
                                            <Col xl = {4}>
                                                <Button color="primary" onClick = {this.webUserMigration.bind(this)} id = "submit" type="submit">Web Users Migration</Button>
                                            </Col>
                                            <Col xl = {4}>
                                                <Button color="primary" onClick = {this.agentsMigration.bind(this)} id = "submit" type="submit">Agents Migration</Button>
                                            </Col>
                                        </Row>
                                        <br />
                                        <br />
                                        <Row>
                                            <Col xl = {4}>
                                                <Button color="primary" onClick = {this.attendanceMigration.bind(this)} id = "submit" type="submit">Attendance Migration</Button>
                                            </Col>
                                        </Row>

                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>
          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default DataMigration;
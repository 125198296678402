import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faMarker, faDownload, faPrint, faFileDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import GoogleMap from 'google-map-react';
import { Tooltip } from "bootstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import CasePdf from "./casePdf";
import { jsPDF } from "jspdf";
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { pdf,PDFViewer } from '@react-pdf/renderer';
import Printer, { print } from 'react-pdf-print'
import NewWindow from 'react-new-window'
import CasePdfMail from "./casePdfMail";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";
import ModalBody from "reactstrap/lib/ModalBody";
import Modal from "reactstrap/lib/Modal";

const ids = ['0']



class ViewCaseDetails extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.enableToClient = this.enableToClient.bind(this);
    this.disableToClient = this.disableToClient.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.enableToClient,
        '3': this.disableToClient
    };
    this.state = {
      firebaseData: [],
      photos: [],
      menu: 'Select',
      success_msg: false,
      agents: [],
      home: false,
      q: '',
      pdf: false,

    qAddressConfirmed: '',
    qStayConfirmed: '',
    qPersonMet: '',
    qPersonRelationship: '',
    qPersonStability: '',
    qTotalAgriLand: '',
    qLandDetails: '',
    qIrrigationSource: '',
    qCropType: '',
    qesidenceOwnership: '',
    qFamilyMembersCount: '',
    qEarningMembersCount: '',
    qOfficeAddress: '',
    officeAddress: '',
    qResidenceProofId: '',
    qAgricultureLending: '',
    qInterior: '',
    qExterior: '',
    qNeighbour1: '',
    qNeighbour2: '',
    qCpvStatus: '',
    qRejectReason: '',
    qAgentRemarks: '',
    agentSignature: '',
    qPersonMet: '',
    qPersonMetDesignation: '',
    qCoOffice: '',
    qAddressConfirmed: '',
    qPersonDesignation: '',
    qResidentAddress: '',
    qNature: '',
    qOfficeOwnership: '',
    qWorkingSince: '',
    qApproxIncome: '',
    qNumberEmployees: '',
    qStocks: '',
    qBusinessProof: '',
    qBusinessActivity: '',
    qSignBoardText: '',
    qOfficeSeenText: '',
    qVehicleDetails: '',
    qStability: '',
    qustomerSignature: '',
    qTpc1: '',
    qTpc1Check: '',
    qTpc2: '',
    qTpc2Check: '',
    qCpvStatus: '',
    qRejectReason: '',
    qAgentRemarks: '',
    agentSignature: '',
    qAddressConfirmed: '',
    qPersonMet: '',
    qPersonRelationship: '',
    qPersonStability: '',
    qResidenceOwnership: '',
    qFamilyMembersCount: '',
    qEarningMembersCount: '',
    qSpouseWorking: '',
    qEarningMembers: '',
    qNbfcName: '',
    qLoanAmount: '',
    qPurpose: '',
    qResidenceStatus: '',
    qPermanentAddress: '',
    qContactPerson: '',
    qRent: '',
    qVerifiedFrom: '',
    qArea: '',
    qAssetDetails: '',
    qAssetAppliedFor: '',
    qAssetToBeUsedBy: '',
    qEaseOfLocate: '',
    qLandmark: '',
    qTypeOfResidence: '',
    qLocalityOfResidence: '',
    qAreaofResidence: '',
    qResidenceConstruction: '',
    qCommentOnExterior: '',
    qCommentOnInterior: '',
    qAssetSeenAtResidence: '',
    qTwoWheeler: '',
    qTwoWheelerModelName: '',
    qTwoWheelerYear: '',
    qCar: '',
    qCarModelName: '',
    qCarYear: '',
    qVehicleUsedBy: '',
    qPersonNameVerification: '',
    qApplicantDetailsConfirmed: '',
    qNegativeFeedback: '',
    qResidenceLocked: '',
    qCpvStatus: '',
    qRejectReason: '',
    productName: '',
    customerName: '',
    amount: '',
    bank: '',
    agentName: '',
    fi: '',

    markers: [],
    markerLatitude: '',
    markerLongitude: '',
    myLatLong: {},

    submittedAt: "",
    applicationId: "",
    fiDate: "",
    fiTime: "",
    fiFlag: "",
    dob: "",
    residentAddresss: "",
    permanentAddress: "",
    mobileNumber: "",
    qIncome: '',
    qAge: '',
    qEducation: '',
    qOccupation: '',
    qLandDetails: '',
    qBranchDistance: '',
    showToClient: '',
    branchName: '',
    viewReport: false

    };
  }

  componentDidMount() {
   let comp = this

   comp.fetchList()

 }

 async fetchList() {

   let comp = this

    let caseId = sessionStorage.getItem("caseId")

    console.log(caseId)

    var config = {
      method: "get",
      url: API_BASE_URL + "/auth/case/" + caseId,
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
      },
    }

    axios(config)
      .then(function (response) {

        if (response.status == 200) {

         let newPhotos = []

         if(response.data.photos != undefined) {
            for(let i=0; i< response.data.photos.length; i++) {
               const photDict = {
                  Photo: response.data.photos[i] 
               }
               newPhotos.push(photDict)
            }
         }

         console.log("newPhotos", newPhotos)
         console.log("showToClient", response.data.showToClient)



         comp.setState({
            qAddressConfirmed:response.data.qaddressconfirmed,
            qStayConfirmed: response.data.qstayconfirmed,
            qPersonMet:response.data.qpersonmet,
            qPersonRelationship:response.data.qpersonrelationship,
            qPersonStability:response.data.qpersonstability,
            qTotalAgriLand:response.data.qtotalagriland,
            qLandDetails:response.data.qlanddetails,
            qIrrigationSource:response.data.qirrigationsource,
            qCropType:response.data.qcroptype,
            qesidenceOwnership:response.data.residenceownership,
            qAge: response.data.qage,
            qEducation: response.data.qeducation,
            qFamilyMembersCount:response.data.qfamilymemberscount,
            qEarningMembersCount:response.data.qearningmemberscount,
            qOfficeAddress:response.data.qofficeaddress,
            officeAddress:response.data.officeaddress,
            qResidenceProofId:response.data.qresidenceproofid,
            qAgricultureLending:response.data.qagriculturelending,
            qInterior:response.data.qinterior,
            qExterior:response.data.qexterior,
            qNeighbour1:response.data.qneighbour1,
            qNeighbour2:response.data.qneighbour2,
            qCpvStatus:response.data.qcpvstatus,
            qRejectReason:response.data.qrejectreason,
            qAgentRemarks:response.data.qagentremarks,
            agentSignature:response.data.agentsignature,

            qPersonMet: response.data.qpersonmet,
            qPersonMetDesignation: response.data.qpersonmetdesignation,
            qCoOffice: response.data.qcooffice,
            qAddressConfirmed: response.data.qaddressconfirmed,
            qPersonDesignation: response.data.qpersondesignation,
            qResidentAddress: response.data.qresidentaddress,
            qNature: response.data.qnature,
            qOfficeOwnership: response.data.qofficeownership,
            qWorkingSince: response.data.qworkingsince,
            qApproxIncome: response.data.qapproxincome,
            qNumberEmployees: response.data.qnumberemployees,
            qStocks: response.data.qstocks,
            qBusinessProof: response.data.qbusinessproof,
            qBusinessActivity: response.data.qbusinessactivity,
            qSignBoardText: response.data.qsignboardtext,
            qOfficeSeenText: response.data.qofficeseentext,
            qVehicleDetails: response.data.qvehicledetails,
            qStability: response.data.qstability,
            qustomerSignature: response.data.customersignature,
            qTpc1: response.data.qtpc1,
            qTpc1Check: response.data.qtpc1check,
            qTpc2: response.data.qtpc2,
            qTpc2Check: response.data.qtpc2check,
            qCpvStatus: response.data.qcpvstatus,
            qRejectReason: response.data.qrejectreason,
            qAgentRemarks: response.data.qagentremarks,
            agentSignature: response.data.agentsignature,

            qAddressConfirmed: response.data.qaddressconfirmed,
            qPersonMet: response.data.qpersonmet,
            qPersonRelationship: response.data.qpersonrelationship,
            qPersonStability: response.data.qpersonstability,
            qResidenceOwnership: response.data.qresidenceownership,
            qFamilyMembersCount: response.data.qfamilymemberscount,
            qEarningMembersCount: response.data.qearningmemberscount,
            qSpouseWorking: response.data.qspouseworking,
            qEarningMembers: response.data.qearningmembers,
            qNbfcName: response.data.qnbfcname,
            qLoanAmount: response.data.qloanamount,
            qPurpose: response.data.qpurpose,
            qResidenceStatus: response.data.qresidencestatus,
            qPermanentAddress: response.data.qpermanentaddress,
            qContactPerson: response.data.qcontactperson,
            qRent: response.data.qrent,
            qVerifiedFrom: response.data.qverifiedfrom,
            qArea: response.data.qarea,
            qAssetDetails: response.data.qassetdetails,
            qAssetAppliedFor: response.data.qassetappliedfor,
            qAssetToBeUsedBy: response.data.qassettobeusedby,
            qEaseOfLocate: response.data.qeaseoflocate,
            qLandmark: response.data.qlandmark,
            qTypeOfResidence: response.data.qtypeofresidence,
            qLocalityOfResidence: response.data.qlocalityofresidence,
            qAreaofResidence: response.data.qareaofresidence,
            qResidenceConstruction: response.data.Qqresidenceconstruction,
            qCommentOnExterior: response.data.qcommentonexterior,
            qCommentOnInterior: response.data.qcommentoninterior,
            qAssetSeenAtResidence: response.data.qassetseenatresidence,
            qTwoWheeler: response.data.qtwowheeler,
            qTwoWheelerModelName: response.data.qtwowheelermodelname,
            qTwoWheelerYear: response.data.qtwowheeleryear,
            qCar: response.data.qcar,
            qCarModelName: response.data.qcarmodelname,
            qCarYear: response.data.qcaryear,
            qVehicleUsedBy: response.data.qvehicleusedby,
            qPersonNameVerification: response.data.qpersonnameverification,
            qApplicantDetailsConfirmed: response.data.qapplicantdetailsconfirmed,
            qNegativeFeedback: response.data.qnegativefeedback,
            qOccupation: response.data.qoccupation,
            qLandDetails: response.data.qlandDetails,
            qBranchDistance: response.data.qbranchdistance,
            qIncome: response.data.qincome,
            qResidenceLocked: response.data.qresidencelocked,
            qCpvStatus: response.data.qcpvstatus,
            qRejectReason: response.data.qrejectreason,
            productName: response.data.productname,
            fi: response.data.fi,

            customerName: response.data.customername,
            amount: response.data.loanamount,
            bank: response.data.bank,
            agentName: response.data.agentname,

            submittedAt: response.data.submittedat,
            applicationId: response.data.applicationid,
            fiDate: response.data.fidate,
            fiTime: response.data.fitime,
            fiFlag: response.data.fiflag,
            dob: response.data.dateofbirth,
            residentAddresss: response.data.residenceaddress,
            permanentAddress: response.data.permanentaddress,
            mobileNumber: response.data.mobilenumber,
            photos: newPhotos,
            showToClient: response.data.showToClient,
            branchName: response.data.branchName
        })



         if(response.data.locationcoordinates !== undefined) {
            comp.setState({
               markerLatitude: response.data.locationcoordinates.split(',')[0],
               markerLongitude: response.data.locationcoordinates.split(',')[1],
            })
         }
         
        }
      })
      .catch(function (error) {
        console.log(error)
        comp.handleNon200Response(error, "1")
      })


   //  firebase.firestore().collection("Case").doc(caseId)
   //  .onSnapshot(function(doc) {
   //     console.log(response.data)
        
   //  });

   //  firebase.firestore().collection("Case").doc(caseId).collection("Photo")
   //  .onSnapshot(function(querySnapshot) {
   //    var photos = [];
   //       querySnapshot.forEach(function(doc) {
   //          console.log(response.data)
   //          photos.push(response.data)
   //       })
   //       comp.setState({
   //          photos: photos
   //       })
   //  })
  }

  async moveToPdf() {
   let comp = this
      if (window.confirm('Are you sure want to download?')) {
      const doc = <CasePdfMail data={comp.state} />
      const asPdf = pdf(doc); // {} is important, throws without an argument
      const blob = await asPdf.toBlob();
      saveAs(blob, `Case_${comp.state.applicationId}.pdf`);
      }
   }

   openReport() {
      let comp = this
      comp.setState({
         viewReport: true
      })
   }

   closeReport() {
      let comp = this
      comp.setState({
         viewReport: false
      })
    }

  handleNon200Response = (error, functionIndex) => {
   if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
 }

 enableToClient() {
   let data = this.state
   let comp = this
   let caseId = sessionStorage.getItem("caseId")

   let jsonData = JSON.stringify({
         showToClient: "YES"
     })

     var config = {
         method: "put",
         url: API_BASE_URL + "/auth/case/" + caseId,
         headers: {
           apikey: API_KEY,
           Authorization: "Bearer " + getCookie("at"),
           "Content-Type": "application/json",
         },
         data: jsonData,
       }
   
       axios(config)
         .then(function (response) {
           if (response.status == 200) {
             alert("Enabled Successfully!!")     
             comp.fetchList()     
           }
         })
         .catch(function (error) {
           console.log(error)
           comp.handleNon200Response(error, "2")
     })
}

disableToClient() {
   let data = this.state
   let comp = this
   let caseId = sessionStorage.getItem("caseId")

   let jsonData = JSON.stringify({
         showToClient: "NO"
     })

     var config = {
         method: "put",
         url: API_BASE_URL + "/auth/case/" + caseId,
         headers: {
           apikey: API_KEY,
           Authorization: "Bearer " + getCookie("at"),
           "Content-Type": "application/json",
         },
         data: jsonData,
       }
   
       axios(config)
         .then(function (response) {
           if (response.status == 200) {
             alert("Disabled Successfully!!")     
             comp.fetchList()   
           }
         })
         .catch(function (error) {
           console.log(error)
           comp.handleNon200Response(error, "3")
     })
   }


renderMarkers = (map, maps) => {

   let marker = new maps.Marker({
      position: { lat: parseFloat(this.state.markerLatitude), lng: parseFloat(this.state.markerLongitude) },
      map,
      title: this.state.customerName
   });
   return marker;
};


  agentsOnChange = agentId => {
    this.setState({ agentId });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

//   moveToPdf() {
//      this.setState({
//         pdf: true
//      })
//   }

  render() {
     var fromComp = sessionStorage.getItem("fromComp")

     if(this.state.pdf) {
        return (
           <CasePdf data = {this.state} />
        )
     }
    return (
      <React.Fragment>

        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Case" breadcrumbItem="View Case" />
            <Row>
               <Col md = "6">
                  <FontAwesomeIcon id = "mainBack" style = {{color :"black", fontSize: "15px", cursor: "pointer"}} onClick={() => this.setState({ home: true })} icon={faArrowLeft}/>  <br/><br />
                     {this.state.home && fromComp === "verify" ?
                     <Redirect to = "/verified-case" /> : null }

                        {this.state.home && fromComp === "verifyHP" ?
                     <Redirect to = "/verified-hp-case" /> : null }


                        {this.state.home && fromComp === "case" ?
                     <Redirect to = "/case-report" /> : null }

                        {this.state.home && fromComp === "hpCase" ?
                     <Redirect to = "/hp-case" /> : null }
               </Col>
               <Col md = "2"> 
                  {/* {this.state.showToClient == '' || this.state.showToClient == "NO" ?
                     <Button type = "submit" onClick = {this.enableToClient.bind(this)} color = "danger">Enable to Client</Button> :
                     <Button type = "submit" onClick = {this.disableToClient.bind(this)} color = "danger">Disable to Client</Button>
                  } */}
               </Col>
               <Col md = "2"  style = {{color : "#000", cursor: "pointer"}} onClick={this.openReport.bind(this)}>
                  <Button color = "primary"><FontAwesomeIcon style = {{color : "white", cursor: "pointer"}} icon={faEye} />&nbsp; View Report</Button>
               </Col>
               <Col md = "2"  style = {{color : "#000", cursor: "pointer"}} onClick={this.moveToPdf.bind(this)}>
                  <Button color = "primary"><FontAwesomeIcon style = {{color : "white", cursor: "pointer"}} icon={faFileDownload} />&nbsp; Download</Button>
               </Col>
            </Row>

            <Modal isOpen={this.state.viewReport} toggle={this.closeReport.bind(this)}>
               <ModalBody>
                  <Row>
                        <PDFViewer style={{ width: '100%', height: '90vh' }}>
                             <CasePdfMail data={this.state} />
                        </PDFViewer>
                  </Row>
               </ModalBody>
            </Modal>

            <br />

                    <Row>
                        <Col md = "3"><b>Cutomer Name: </b> {this.state.customerName}</Col>
                        <Col md = "3"><b>Product: </b> {this.state.productName}</Col>
                        <Col md = "3"><b>Reference Number: </b> {this.state.applicationId}</Col>
                        <Col md = "3"><b>FI Type: </b> {this.state.fi}</Col>

                    </Row>
                    <br />
            {this.state.fi === "RV" && this.state.productName.toLowerCase() === "agriloan" ?
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <tbody>
                                                    <tr>
                                                        <th>Address Confirmed</th>
                                                        <td>{this.state.qAddressConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Stay Confirmed</th>
                                                        <td>{this.state.qStayConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Name of Person Met</th>
                                                        <td>{this.state.qPersonMet}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Relation With Applicant</th>
                                                        <td>{this.state.qPersonRelationship}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Stability</th>
                                                        <td>{this.state.qStability}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Total Agri Land</th>
                                                        <td>{this.state.qTotalAgriLand}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Land Details (PATTA No)</th>
                                                        <td>{this.state.qLandDetails}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Source of Irrigation</th>
                                                        <td>{this.state.qIrrigationSource}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Type of Crops</th>
                                                        <td>{this.state.qCropType}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Ownership Residence</th>
                                                        <td>{this.state.qResidenceOwnership}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>TFM</th>
                                                        <td>{this.state.qFamilyMembersCount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Earning</th>
                                                        <td>{this.state.qEarningMembers}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Office Address</th>
                                                        <td>{this.state.qOfficeAddress}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Residence Proof Sighted</th>
                                                        <td>{this.state.qResidenceProofId}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Agriculture Lending (If Any)</th>
                                                        <td>{this.state.qAgricultureLending}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Interior</th>
                                                        <td>{this.state.qInterior}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Exterior</th>
                                                        <td>{this.state.qExterior}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Application Occupation</th>
                                                        <td>{this.state.qOccupation === "Agriculture" ? this.state.qLandDetails : this.state.qIncome}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Neighbour Check1</th>
                                                        <td>{this.state.qNeighbour1}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Neighbour Check 2</th>
                                                        <td>{this.state.qNeighbour2}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Branch Name</th>
                                                        <td>{this.state.branchName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>CPV Status</th>
                                                        <td>{this.state.qCpvStatus}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Reject Reason</th>
                                                        <td>{this.state.qRejectReason}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Agent Remarks</th>
                                                        <td>{this.state.qAgentRemarks}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Agent Signature</th>
                                                        <td>{this.state.agentSignature !== "" ? 
                                                            <a href = {this.state.agentSignature}><img src = {this.state.agentSignature} wisth = "50" height = "50" /></a> : "No" }
                                                        </td>
                                                     </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                <br />

                                <Card>
                                 <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                 {this.state.qCpvStatus === "Positive" ?
                                 <CardBody>
                                    <p>Visit Date and Time: {new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')}, Address Confirmation: {this.state.qAddressConfirmed}, Stay Confirmed: {this.state.qStayConfirmed}, Name of Person Met: {this.state.qPersonMet}, Relation With Applicant: {this.state.qPersonRelationship} ,
                                       Total Agri Land: {this.state.qTotalAgriLand}, Land Details (PATTA No): {this.state.qLandDetails}, Source of Irrigation: {this.state.qIrrigationSource} ,
                                       Type of Crops: {this.state.qCropType}, Ownership Residence: {this.state.qResidenceOwnership}, TFM: {this.state.qFamilyMembersCount}, Earning: {this.state.qEarningMembersCount} ,
                                       Neighbour Check1: {this.state.qNeighbour1}, Agent Remarks: {this.state.qAgentRemarks}, Branch Name: {this.state.branchName} , Latitude {this.state.markerLatitude}, Longitude: {this.state.markerLongitude}</p>
                                 </CardBody> :
                                 <CardBody>
                                    <p>Visit Date and Time: {new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')}, Agent Remarks: {this.state.qAgentRemarks}, Branch Name: {this.state.branchName} , Latitude {this.state.markerLatitude}, Longitude: {this.state.markerLongitude}</p>
                                 </CardBody>}
                              </Card>
                              <br />

                              <Card>
                                       <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                       <CardBody>
                                          <Row>
                                             {this.state.photos.map((rowData, index) => (
                                             <Col md = "4">
                                                <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "300" height = "300" /></a> <br />
                                             </Col>
                                             ))}
                                          </Row>
                                       </CardBody>
                                    </Card>

                            </Col>
                        </Row> : null }


                        {this.state.fi === "BV" ?
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <tbody>
                                                    <tr>
                                                        <th>Person Met</th>
                                                        <td>{this.state.qPersonMet}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Person Met Designation</th>
                                                        <td>{this.state.qPersonMetDesignation}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Name of Co. /Officer</th>
                                                        <td>{this.state.qCoOffice}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Address Confirmation</th>
                                                        <td>{this.state.qAddressConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Applicant Designation</th>
                                                        <td>{this.state.qPersonDesignation}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Business Address</th>
                                                        <td>{this.state.officeAddress}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Nature of Business</th>
                                                        <td>{this.state.qNature}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Office Ownership</th>
                                                        <td>{this.state.qOfficeOwnership}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Working Since</th>
                                                        <td>{this.state.qWorkingSince}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Approx Income</th>
                                                        <td>{this.state.qApproxIncome}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Number of Employees</th>
                                                        <td>{this.state.qNumberEmployees}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Stocks</th>
                                                        <td>{this.state.qStocks}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Nature of Business Proofs</th>
                                                        <td>{this.state.qBusinessProof}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Business Activity</th>
                                                        <td>{this.state.qBusinessActivity}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Name Mentioned on SignBoard</th>
                                                        <td>{this.state.qSignBoardText === '' ? 'No' : this.state.qSignBoardText }</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Proof of Office Seen</th>
                                                        <td>{this.state.qOfficeSeenText === '' ? 'No' : this.state.qOfficeSeenText }</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Distance from Branch (in KM)</th>
                                                        <td>{this.state.qBranchDistance}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Vehicle Details</th>
                                                        <td>{this.state.qVehicleDetails}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Stability</th>
                                                        <td>{this.state.qStability}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Customer Sign</th>
                                                        <td>{this.state.qustomerSignature}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>TPC 1 Name</th>
                                                        <td>{this.state.qTpc1}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>TPC 1 Check with</th>
                                                        <td>{this.state.qTpc1Check}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>TPC 2 Name</th>
                                                        <td>{this.state.qTpc2}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>TPC 2 Check with</th>
                                                        <td>{this.state.qTpc2Check}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Verifier Feedback</th>
                                                        <td>{this.state.qCpvStatus}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Branch Name</th>
                                                        <td>{this.state.branchName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Reject Reason</th>
                                                        <td>{this.state.qRejectReason}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Agent Remarks</th>
                                                        <td>{this.state.qAgentRemarks}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Agent Signature</th>
                                                        <td>{this.state.agentSignature !== "" ? 
                                                            <a href = {this.state.agentSignature}><img src = {this.state.agentSignature} wisth = "50" height = "50" /></a> : "NO" }
                                                        </td>
                                                     </tr>
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                                <br />

                              <Card>
                                 <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                 {this.state.qCpvStatus === "Positive" ?
                                 <CardBody>
                                    <p>Visit Date and Time: {new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')}, Person Met: {this.state.qPersonMet}, Person Met Designation: {this.state.qPersonMetDesignation}, Name of Co. /Officer: {this.state.qCoOffice},  
                                       Address Confirmation: {this.state.qAddressConfirmed}, Applicant Designation: {this.state.qPersonDesignation}, Nature of Business: {this.state.qNature}
                                       Office Ownership: {this.state.qOfficeOwnership}, Working Since: {this.state.qWorkingSince}, Number of Employees: {this.state.qNumberEmployees}, Stocks: {this.state.qStocks}
                                       , Business Activity: {this.state.qBusinessActivity}, Name Mentioned on SignBoard: {this.state.qSignBoardText}, Distance from Branch (in KM): {this.state.qBranchDistance} ,
                                       TPC 1 Name: {this.state.qTpc1} ,
                                       TPC 2 Name: {this.state.qTpc2} , Agent Remarks: {this.state.qAgentRemarks}, Branch Name: {this.state.branchName} , Latitude: {this.state.markerLatitude}, Longitude: {this.state.markerLongitude}</p>
                                 </CardBody> :
                                 <CardBody>
                                    <p>Visit Date and Time: {new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')}, Agent Remarks: {this.state.qAgentRemarks}, Branch Name: {this.state.branchName} , Latitude: {this.state.markerLatitude}, Longitude: {this.state.markerLongitude}</p>
                                 </CardBody>}
                              </Card>
                              <br />

                              <Card>
                                       <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                       <CardBody>
                                          <Row>
                                             {this.state.photos.map((rowData, index) => (
                                                <Col md = "4">
                                                   <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "300" height = "300" /></a> <br />
                                                </Col>
                                             ))}
                                          </Row>
                                       </CardBody>
                                    </Card>
                            </Col>
                        </Row> : null }


                        {this.state.fi === "RV" && this.state.productName.toLowerCase() !== "agriloan" ?
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <tbody>
                                                    <tr>
                                                        <th>Address Confirmed</th>
                                                        <td>{this.state.qAddressConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Stay Confirmed</th>
                                                        <td>{this.state.qStayConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Name of Person Met</th>
                                                        <td>{this.state.qPersonMet}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Relation With Applicant</th>
                                                        <td>{this.state.qPersonRelationship}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Stability</th>
                                                        <td>{this.state.qPersonStability}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Ownership Residence</th>
                                                        <td>{this.state.qResidenceOwnership}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Age</th>
                                                        <td>{this.state.qAge}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Education</th>
                                                        <td>{this.state.qEducation}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>TFM</th>
                                                        <td>{this.state.qFamilyMembersCount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Earning</th>
                                                        <td>{this.state.qEarningMembersCount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Spouse Working</th>
                                                        <td>{this.state.qSpouseWorking}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Earning Members</th>
                                                        <td>{this.state.qEarningMembers}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>NBFC/Bank Name</th>
                                                        <td>{this.state.qNbfcName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Loan Amount</th>
                                                        <td>{this.state.qLoanAmount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Purpose</th>
                                                        <td>{this.state.qPurpose}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Resident Status</th>
                                                        <td>{this.state.qResidenceStatus}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Permanent Address</th>
                                                        <td>{this.state.qPermanentAddress}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Contact Person</th>
                                                        <td>{this.state.qContactPerson}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Rent Per Month</th>
                                                        <td>{this.state.qRent}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Verified From</th>
                                                        <td>{this.state.qVerifiedFrom}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Area (In Sqft)</th>
                                                        <td>{this.state.qArea}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset Details</th>
                                                        <td>{this.state.qAssetDetails}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset Applied For</th>
                                                        <td>{this.state.qAssetAppliedFor}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset To be Used By</th>
                                                        <td>{this.state.qAssetToBeUsedBy}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Ease of Locate Address</th>
                                                        <td>{this.state.qEaseOfLocate}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Landmark</th>
                                                        <td>{this.state.qLandmark}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Type of Residence</th>
                                                        <td>{this.state.qTypeOfResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Locality of Residence</th>
                                                        <td>{this.state.qLocalityOfResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Area of Residence</th>
                                                        <td>{this.state.qAreaofResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Residence Construction</th>
                                                        <td>{this.state.qResidenceConstruction}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Comment on Exterior</th>
                                                        <td>{this.state.qCommentOnExterior}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Comment on Interior</th>
                                                        <td>{this.state.qCommentOnInterior}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset seen at Residence</th>
                                                        <td>{this.state.qAssetSeenAtResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Two Wheeler</th>
                                                        <td>{this.state.qTwoWheeler}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Model Name</th>
                                                        <td>{this.state.qTwoWheelerModelName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Year of Manufacture</th>
                                                        <td>{this.state.qTwoWheelerYear}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car</th>
                                                        <td>{this.state.qCar}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car Model</th>
                                                        <td>{this.state.qCarModelName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car Manufacturing Year</th>
                                                        <td>{this.state.qCarYear}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Vehicle to be Used By</th>
                                                        <td>{this.state.qVehicleUsedBy}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Third Party</th>
                                                        <td>{this.state.qPersonNameVerification}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Applicant Details Confirmed</th>
                                                        <td>{this.state.qApplicantDetailsConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Negative Feedback (If Any)</th>
                                                        <td>{this.state.qNegativeFeedback}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Application Occupation</th>
                                                        <td>{this.state.qOccupation === "Agriculture" ? this.state.qLandDetails : this.state.qIncome}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Distance from Branch</th>
                                                        <td>{this.state.qBranchDistance}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Resident Locked During Visit</th>
                                                        <td>{this.state.qResidenceLocked}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>CPV Status</th>
                                                        <td>{this.state.qCpvStatus}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Branch Name</th>
                                                        <td>{this.state.branchName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Reject Reason</th>
                                                        <td>{this.state.qRejectReason}</td>
                                                     </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                <br />

                                 <Card>
                                    <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                    {this.state.qCpvStatus === "Positive" ?
                                    <CardBody>
                                       <p>Visit Date and Time:{new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Address Confirmation: {this.state.qAddressConfirmed} , Stay Confirmed: {this.state.qStayConfirmed} , Name of Person Met: {this.state.qPersonMet} , Relation With Applicant: {this.state.qPersonRelationship} , 
                                          Ownership Residence: {this.state.qResidenceOwnership} ,  Age: {this.state.qAge} ,  Education: {this.state.qEducation} , TFM: {this.state.qFamilyMembersCount} , Earning: {this.state.qEarningMembersCount} , 
                                          Rent Per Month: {this.state.qRent} , Area (In Sqft): {this.state.qArea} , Landmark: {this.state.qLandmark} , Type of Residence: {this.state.qTypeOfResidence} ,  Locality of Residence: {this.state.qLocalityOfResidence} , 
                                          Residence Construction: {this.state.qResidenceConstruction} , Third Party: {this.state.qPersonNameVerification} ,
                                          Applicant Details Confirmed: {this.state.qApplicantDetailsConfirmed} , Agent Remarks: {this.state.qAgentRemarks} , Branch Name: {this.state.branchName} , Latitude {this.state.markerLatitude} , Longitude: {this.state.markerLongitude}</p>
                                    </CardBody> :
                                    <CardBody>
                                       <p>Visit Date and Time:{new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Landmark: {this.state.qLandmark} ,  
                                          Agent Remarks: {this.state.qAgentRemarks} , Branch Name: {this.state.branchName} , Latitude {this.state.markerLatitude} , Longitude: {this.state.markerLongitude}</p>
                                     </CardBody> }
                                 </Card>

                                 <br />


                                 <Card>
                                       <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                       <CardBody>
                                          <Row>
                                             {this.state.photos.map((rowData, index) => (
                                                <Col md = "4">
                                                   <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "300" height = "300" /></a> <br />
                                                </Col>
                                             ))}
                                          </Row>
                                       </CardBody>
                                    </Card>
                            </Col>
                        </Row> : null }

                        {this.state.fi === "PV" && this.state.productName.toLowerCase() !== "agriloan" ?
                        <Row>
                            <Col lg={12}>
                            <Card>
                                    <CardBody id = "user-list">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <tbody>
                                                    <tr>
                                                        <th>Address Confirmation</th>
                                                        <td>{this.state.qAddressConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Name of Person Met</th>
                                                        <td>{this.state.qPersonMet}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Relation With Applicant</th>
                                                        <td>{this.state.qPersonRelationship}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Stability</th>
                                                        <td>{this.state.qPersonStability}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Ownership Residence</th>
                                                        <td>{this.state.qResidenceOwnership}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>TFM</th>
                                                        <td>{this.state.qFamilyMembersCount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Earning</th>
                                                        <td>{this.state.qEarningMembersCount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Spouse Working</th>
                                                        <td>{this.state.qSpouseWorking}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Earning Members</th>
                                                        <td>{this.state.qEarningMembers}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>NBFC/Bank Name</th>
                                                        <td>{this.state.qNbfcName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Loan Amount</th>
                                                        <td>{this.state.qLoanAmount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Purpose</th>
                                                        <td>{this.state.qPurpose}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Resident Status</th>
                                                        <td>{this.state.qResidenceStatus}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Permanent Address</th>
                                                        <td>{this.state.qPermanentAddress}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Contact Person</th>
                                                        <td>{this.state.qContactPerson}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Rent Per Month</th>
                                                        <td>{this.state.qRent}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Verified From</th>
                                                        <td>{this.state.qVerifiedFrom}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Area (In Sqft)</th>
                                                        <td>{this.state.qArea}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset Details</th>
                                                        <td>{this.state.qAssetDetails}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset Applied For</th>
                                                        <td>{this.state.qAssetAppliedFor}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset To be Used By</th>
                                                        <td>{this.state.qAssetToBeUsedBy}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Ease of Locate Address</th>
                                                        <td>{this.state.qEaseOfLocate}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Landmark</th>
                                                        <td>{this.state.qLandmark}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Type of Residence</th>
                                                        <td>{this.state.qTypeOfResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Locality of Residence</th>
                                                        <td>{this.state.qLocalityOfResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Area of Residence</th>
                                                        <td>{this.state.qAreaofResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Residence Construction</th>
                                                        <td>{this.state.qResidenceConstruction}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Comment on Exterior</th>
                                                        <td>{this.state.qCommentOnExterior}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Comment on Interior</th>
                                                        <td>{this.state.qCommentOnInterior}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset seen at Residence</th>
                                                        <td>{this.state.qAssetSeenAtResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Two Wheeler</th>
                                                        <td>{this.state.qTwoWheeler}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Model Name</th>
                                                        <td>{this.state.qTwoWheelerModelName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Year of Manufacture</th>
                                                        <td>{this.state.qTwoWheelerYear}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car</th>
                                                        <td>{this.state.qCar}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car Model</th>
                                                        <td>{this.state.qCarModelName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car Manufacturing Year</th>
                                                        <td>{this.state.qCarYear}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Vehicle to be Used By</th>
                                                        <td>{this.state.qVehicleUsedBy}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Third Party</th>
                                                        <td>{this.state.qPersonNameVerification}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Applicant Details Confirmed</th>
                                                        <td>{this.state.qApplicantDetailsConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Negative Feedback (If Any)</th>
                                                        <td>{this.state.qNegativeFeedback}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Resident Locked During Visit</th>
                                                        <td>{this.state.qResidenceLocked}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>CPV Status</th>
                                                        <td>{this.state.qCpvStatus}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Branch Name</th>
                                                        <td>{this.state.branchName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Reject Reason</th>
                                                        <td>{this.state.qRejectReason}</td>
                                                     </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                <br />

                                 <Card>
                                    <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                    {this.state.qCpvStatus === "Positive" ?
                                    <CardBody>
                                       <p>Visit Date and Time: {new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Address Confirmation: {this.state.qAddressConfirmed} , Name of Person Met: {this.state.qPersonMet} , Relation With Applicant: {this.state.qPersonRelationship} , 
                                          Ownership Residence: {this.state.qResidenceOwnership} , TFM: {this.state.qFamilyMembersCount} , Earning: {this.state.qEarningMembersCount} , 
                                          Rent Per Month: {this.state.qRent} , Area (In Sqft): {this.state.qArea} , Landmark: {this.state.qLandmark} , Type of Residence: {this.state.qTypeOfResidence} ,  Locality of Residence: {this.state.qLocalityOfResidence} , 
                                          Residence Construction: {this.state.qResidenceConstruction} , Third Party: {this.state.qPersonNameVerification} ,
                                          Applicant Details Confirmed: {this.state.qApplicantDetailsConfirmed} , Agent Remarks: {this.state.qAgentRemarks} , Branch Name: {this.state.branchName} , Latitude {this.state.markerLatitude} , Longitude: {this.state.markerLongitude}</p>
                                    </CardBody> :
                                    <CardBody>
                                       <p>Visit Date and Time: {new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Landmark: {this.state.qLandmark} , Agent Remarks: {this.state.qAgentRemarks} , Branch Name: {this.state.branchName} , Latitude {this.state.markerLatitude} , Longitude: {this.state.markerLongitude}</p>
                                    </CardBody> }
                                 </Card>

                                 <br />

                              <Card>
                                       <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                       <CardBody>
                                          <Row>
                                             {this.state.photos.map((rowData, index) => (
                                                <Col md = "4">
                                                   <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "300" height = "300" /></a> <br />
                                                </Col>
                                             ))}
                                          </Row>
                                       </CardBody>
                                    </Card>
                            </Col>
                        </Row> : null }

                        {this.state.fi !== "PV" && this.state.fi !== "BV" && this.state.fi !== "RV" ?
                        <Row>
                            <Col lg={12}>
                            <Card>
                                    <CardBody id = "user-list">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <tbody>
                                                    <tr>
                                                        <th>Address Confirmation</th>
                                                        <td>{this.state.qAddressConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Name of Person Met</th>
                                                        <td>{this.state.qPersonMet}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Relation With Applicant</th>
                                                        <td>{this.state.qPersonRelationship}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Stability</th>
                                                        <td>{this.state.qPersonStability}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Ownership Residence</th>
                                                        <td>{this.state.qResidenceOwnership}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>TFM</th>
                                                        <td>{this.state.qFamilyMembersCount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Earning</th>
                                                        <td>{this.state.qEarningMembersCount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Spouse Working</th>
                                                        <td>{this.state.qSpouseWorking}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Earning Members</th>
                                                        <td>{this.state.qEarningMembers}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>NBFC/Bank Name</th>
                                                        <td>{this.state.qNbfcName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Loan Amount</th>
                                                        <td>{this.state.qLoanAmount}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Purpose</th>
                                                        <td>{this.state.qPurpose}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Resident Status</th>
                                                        <td>{this.state.qResidenceStatus}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Permanent Address</th>
                                                        <td>{this.state.qPermanentAddress}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Contact Person</th>
                                                        <td>{this.state.qContactPerson}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Rent Per Month</th>
                                                        <td>{this.state.qRent}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Verified From</th>
                                                        <td>{this.state.qVerifiedFrom}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Area (In Sqft)</th>
                                                        <td>{this.state.qArea}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset Details</th>
                                                        <td>{this.state.qAssetDetails}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset Applied For</th>
                                                        <td>{this.state.qAssetAppliedFor}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset To be Used By</th>
                                                        <td>{this.state.qAssetToBeUsedBy}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Ease of Locate Address</th>
                                                        <td>{this.state.qEaseOfLocate}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Landmark</th>
                                                        <td>{this.state.qLandmark}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Type of Residence</th>
                                                        <td>{this.state.qTypeOfResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Locality of Residence</th>
                                                        <td>{this.state.qLocalityOfResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Area of Residence</th>
                                                        <td>{this.state.qAreaofResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Residence Construction</th>
                                                        <td>{this.state.qResidenceConstruction}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Comment on Exterior</th>
                                                        <td>{this.state.qCommentOnExterior}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Comment on Interior</th>
                                                        <td>{this.state.qCommentOnInterior}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Asset seen at Residence</th>
                                                        <td>{this.state.qAssetSeenAtResidence}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Two Wheeler</th>
                                                        <td>{this.state.qTwoWheeler}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Model Name</th>
                                                        <td>{this.state.qTwoWheelerModelName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Year of Manufacture</th>
                                                        <td>{this.state.qTwoWheelerYear}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car</th>
                                                        <td>{this.state.qCar}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car Model</th>
                                                        <td>{this.state.qCarModelName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Car Manufacturing Year</th>
                                                        <td>{this.state.qCarYear}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Vehicle to be Used By</th>
                                                        <td>{this.state.qVehicleUsedBy}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Third Party</th>
                                                        <td>{this.state.qPersonNameVerification}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Applicant Details Confirmed</th>
                                                        <td>{this.state.qApplicantDetailsConfirmed}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Negative Feedback (If Any)</th>
                                                        <td>{this.state.qNegativeFeedback}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Resident Locked During Visit</th>
                                                        <td>{this.state.qResidenceLocked}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>CPV Status</th>
                                                        <td>{this.state.qCpvStatus}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Branch Name</th>
                                                        <td>{this.state.branchName}</td>
                                                     </tr>
                                                     <tr>
                                                        <th>Reject Reason</th>
                                                        <td>{this.state.qRejectReason}</td>
                                                     </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                <br />

                                 <Card>
                                    <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                    {this.state.qCpvStatus === "Positive" ?
                                    <CardBody>
                                       <p>Visit Date and Time: {new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Address Confirmation: {this.state.qAddressConfirmed} , Name of Person Met: {this.state.qPersonMet} , Relation With Applicant: {this.state.qPersonRelationship} , 
                                          Ownership Residence: {this.state.qResidenceOwnership} , TFM: {this.state.qFamilyMembersCount} , Earning: {this.state.qEarningMembersCount} , 
                                          Rent Per Month: {this.state.qRent} , Area (In Sqft): {this.state.qArea} , Landmark: {this.state.qLandmark} , Type of Residence: {this.state.qTypeOfResidence} ,  Locality of Residence: {this.state.qLocalityOfResidence} , 
                                          Residence Construction: {this.state.qResidenceConstruction} , Third Party: {this.state.qPersonNameVerification} ,
                                          Applicant Details Confirmed: {this.state.qApplicantDetailsConfirmed} , Agent Remarks: {this.state.qAgentRemarks} , Branch Name: {this.state.branchName} , Latitude {this.state.markerLatitude} , Longitude: {this.state.markerLongitude}</p>
                                    </CardBody> :
                                    <CardBody>
                                       <p>Visit Date and Time: {new Date(this.state.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Landmark: {this.state.qLandmark}, Agent Remarks: {this.state.qAgentRemarks} , Branch Name: {this.state.branchName} , Latitude {this.state.markerLatitude} , Longitude: {this.state.markerLongitude}</p>
                                    </CardBody> }
                                 </Card>

                                 <br />

                              <Card>
                                       <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                       <CardBody>
                                          <Row>
                                             {this.state.photos.map((rowData, index) => (
                                                <Col md = "4">
                                                   <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "300" height = "300" /></a> <br />
                                                </Col>
                                             ))}
                                          </Row>
                                       </CardBody>
                                    </Card>
                            </Col>
                        </Row> : null }

                        


                        {this.state.markerLatitude !== '' && this.state.markerLongitude !== '' ?
                        <Card className="w-full h-512 rounded-8 shadow-1">
                           <br />
                           <Row style = {{padding: "10px"}}>
                              <Col md = "3">
                                 <h5>Latitude: {this.state.markerLatitude}</h5>
                              </Col>
                              <Col md = "3">
                                 <h5>Longitude: {this.state.markerLongitude}</h5>
                              </Col>
                           </Row>
                        <div style={{height: `400px`, width: '100%'}}>
                        <GoogleMap
                           bootstrapURLKeys={{
                              key: "AIzaSyBJzptPqnoVkUgJfL9x6kBnruQj_3VPQcA"
                           }}
                           defaultZoom={8}
                           defaultCenter={[this.state.markerLatitude, this.state.markerLongitude]}
                           yesIWantToUseGoogleMapApiInternals
                           onGoogleApiLoaded={({ map, maps }) => {this.renderMarkers(map, maps)}}
                        >
                        </GoogleMap>
                        </div>
                     </Card> : null }

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default ViewCaseDetails;
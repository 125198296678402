import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';


class HighPriorityAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      agents: [],
      agentId: [],
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
    };
  }

  componentDidMount() {

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }
    
    let comp = this
    firebase.firestore().collection("LoanApplication")
    .onSnapshot(function(querySnapshot) {
        var loans = [];
        querySnapshot.forEach(function(doc) {
          loans.push(doc.data())
        });
        comp.setState({
          firebaseData: loans
        })
    });

    if(state.includes("SUPER ADMIN")) {
      firebase.firestore().collection("Agents")
      .where("ApprovalStatus", "==", "Approved")
      .get().then((querySnapshot) => {
          var ags = [];
          querySnapshot.forEach(function(doc) {
            if(doc.data().AgentId !== undefined) {
              const dict = {
                label: (doc.data().AgentId) + "-" + doc.data().Name,
                value: doc.data().UserId
              }
              ags.push(dict)
            }
          });
          comp.setState({
            agents: ags,
          })
      });
    } else {

      firebase.firestore().collection("Agents")
      .where("ApprovalStatus", "==", "Approved")
      .where("State", "array-contains-any", state.split(",").slice(0, -1))
      .get().then((querySnapshot) => {
          var ags = [];
          querySnapshot.forEach(function(doc) {
            console.log(doc.data())
            if(doc.data().AgentId !== undefined) {
              const dict = {
                label: (doc.data().AgentId) + "-" + doc.data().Name,
                value: doc.data().UserId
              }
              ags.push(dict)
            }
          });
          comp.setState({
            agents: ags,
          })
      });
    }
  }

  agentsOnChange = agentId => {
    this.setState({ agentId });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    // if(data.videoName === '') {
    //     alert("Select Video");
    //     document.getElementById("videoName").focus();
    //     return;
    // }

    // if(data.menu === 'Select') {
    //     alert("Select Status");
    //     document.getElementById("menu").focus();
    //     return;
    // }

    // firebase.firestore().collection('Videos').add({
    //     Name: data.videoName,
    //     Status: data.menu
    // })
    // .then(function(docRef) {
    //     comp.setState({
    //       success_msg: true
    //     })
    // })

    // data.videoName = ""
    // data.menu = "Select"
    // document.getElementById("create-form").reset()
    
}

handleAssignAgent(key) {
  let data = this.state
  if(data.agentId.length === 0) {
      alert("Select Agent");
      return;
  }

  let name = data.agentId.label.split("-")

    firebase.firestore().collection("LoanApplication").doc(key.ApplicationId).update({
      AssignedAgentName: name[0],
      AssignedAgentId: data.agentId.value
    })
        alert("Agent Assigned Successfully!!")

        this.setState({
          agentId: []
        })

        document.getElementById(`agentId${key.ApplicationId}`).value = ""
  
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Assign" breadcrumbItem="High Priority Assign" />
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>FI</th>
                                                            <th>Product Name</th>
                                                            <th>Address</th>
                                                            <th>Assign</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.ApplicationId}</td>
                                                        <td>{rowData.CustomerName}</td>
                                                        <td>{rowData.FI}</td>
                                                        <td>{rowData.ProductName}</td>
                                                        <td>{rowData.ResidenceAddress}</td>
                                                        <td> {rowData.AssignedAgentName !== undefined ?
                                                        <p>Asssigned Agent: {rowData.AssignedAgentName}</p> : <p></p> }
                                                        <Select
                                                          className="select1-selection"
                                                          classNamePrefix="select"
                                                          isDisabled={this.state.isDisabled}
                                                          isLoading={this.state.isLoading}
                                                          isClearable={this.state.isClearable}
                                                          isRtl={this.state.isRtl}
                                                          isSearchable={this.state.isSearchable}
                                                          options={this.state.agents}
                                                          id={`agentId${rowData.ApplicationId}`}
                                                          name = "agentId"
                                                          placeholder = "Select Agents"
                                                          // value = {this.state.agentId}
                                                          onChange = {this.agentsOnChange}
                                                        />
                                                         <br /> <Button color="primary" onClick = {this.handleAssignAgent.bind(this, rowData)}  type="submit">Submit</Button></td>
                                                    </tr>
                                                ))}
                                                  <tr><td></td></tr>
                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default HighPriorityAssign;
import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";


class QueuedAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firestoreData: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
    };
  }

  componentDidMount() {}


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    // if(data.videoName === '') {
    //     alert("Select Video");
    //     document.getElementById("videoName").focus();
    //     return;
    // }

    // if(data.menu === 'Select') {
    //     alert("Select Status");
    //     document.getElementById("menu").focus();
    //     return;
    // }

    // firebase.firestore().collection('Videos').add({
    //     Name: data.videoName,
    //     Status: data.menu
    // })
    // .then(function(docRef) {
    //     comp.setState({
    //       success_msg: true
    //     })
    // })

    // data.videoName = ""
    // data.menu = "Select"
    // document.getElementById("create-form").reset()
    
}

// videoUpload = e =>{
        
//   if(e.target.files[0] === 0){
//      alert("Add Video");
//      return;
//  }

//  const ref = firebase.storage().ref("/Videos/");
//  const file = e.target.files[0];
//  const name = e.target.files[0] + Date();
//  const metadata = {
//  contentType: file.type
//  };
//  const task = ref.child(name).put(file, metadata);
//  task
//  .then(snapshot => snapshot.ref.getDownloadURL())
//  .then((url) => {
//    this.setState({
//      videoName: url
//    })
//  })
//  .catch(console.error);         
// }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Assign" breadcrumbItem="Queued" />
            {/* <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md = "4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Import<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="videoName"
                                                          placeholder="Model Name"
                                                          type="file"
                                                          className="form-control"
                                                          id="videoName"
                                                          onChange = {this.videoUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Status<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" id = "menu" name = "menu" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "Select">Select Status</option>
                                                        <option value = "Active">Active</option>
                                                        <option value = "InActive">InActive</option>
                                                    
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                              </Form>
                                            <Button color="primary" onClick = {this.handleSubmit.bind(this)}  type="submit">Submit</Button>
                                            {this.state.success_msg ? (
											<SweetAlert
												title="Good job!"
												success
												confirmBtnBsStyle="success"
												onConfirm={() => this.setState({ success_msg: false })}
											>
												Successfully Created!
											</SweetAlert>
										) : null}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default QueuedAssign;
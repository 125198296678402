import React, { Component } from "react";
import { Card, CardBody, CardTitle, Media } from "reactstrap";
import { Link } from "react-router-dom";
import firebase from '../../firebase'

class ActivityComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: []
        };
    }

    componentDidMount() {
        let comp = this
        
        firebase.firestore().collection("Attendance")
        .orderBy("InTimeDate", "desc").limit(10)
        .onSnapshot(function(querySnapshot) {
            let newdata = []
            querySnapshot.forEach(function(doc) { 
                newdata.push(doc.data())
            })
    
            comp.setState({
                notifications: newdata
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-5">
                            Notifications
                        </CardTitle>
                        {this.state.notifications.map((rowData, index) => (
                        <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                                </div>
                                <Media>
                                    {/* <div className="mr-3">
                                        <h5 className="font-size-14">22 Nov <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i></h5>
                                    </div> */}
                                    <Media body>
                                        <div>
                                            {rowData.Name + "(" + rowData.Userid + ") -" + rowData.Date}
                                        </div>
                                        <br />
                                    </Media>
                                </Media>
                            </li>
                        </ul>
                        ))}
                        {/* <div className="text-center mt-4"><Link to="" className="btn btn-primary waves-effect waves-light btn-sm">View More <i className="mdi mdi-arrow-right ml-1"></i></Link></div> */}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default ActivityComp;

import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';

function timeDifference(date1,date2) {

    var diff =(date1.getTime() - date2.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));

}


class ViewTATDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      agents: [],
      agentId: [],
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      banks: [],
      products: [],
      bank: '',
      product: ''
    };
  }

  componentDidMount() {
    let comp = this

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    firebase.firestore().collection("Case")
    .where("Status", "==", "Completed")
    .where("State", "array-contains-any", state.split(",").slice(0, -1))
    .get().then((querySnapshot) => {
    let newData = []
        querySnapshot.forEach(function(doc) { 
            if(doc.data().CreatedAt !== undefined && doc.data().Submitted !== undefined) {
                var timeDiff = timeDifference(doc.data().CreatedAt, doc.data().SubmittedAt)

                if(timeDiff <=3) {
                    newData.push(doc.data())
                }
            }
        })

        comp.setState({
            firebaseData: newData
        })
    })
  }

  agentsOnChange = agentId => {
    this.setState({ agentId });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    let data = this.state

    let comp = this

    if(data.bank === '') {
      alert("Select Bank");
      document.getElementById("bank").focus();
      return;
    }

    if(data.product === '') {
      alert("Select Product");
      document.getElementById("product").focus();
      return;
    }

    firebase.firestore().collection("Case")
    .where("Status", "==", "Completed")
    .where("Bank", "==", data.bank)
    .where("ProductName", "==", data.product)
    .where("State", "array-contains-any", state.split(",").slice(0, -1))
    .get().then((querySnapshot) => {
    let newData = []
        querySnapshot.forEach(function(doc) { 
            if(doc.data().CreatedAt !== undefined && doc.data().AssignedAt !== undefined) {
                var timeDiff = timeDifference(doc.data().CreatedAt, doc.data().AssignedAt)

                if(timeDiff <=3) {
                    newData.push(doc.data())
                }
            }
        })

        comp.setState({
            firebaseData: newData
        })
    })
}

handleAssignAgent(key) {
  let data = this.state
  if(data.agentId.length === 0) {
      alert("Select Agent");
      return;
  }

  let name = data.agentId.label.split("-")

    firebase.firestore().collection("LoanApplication").doc(key.ApplicationId).update({
      AssignedAgentName: name[0],
      AssignedAgentId: data.agentId.value
    })
        alert("Agent Assigned Successfully!!")

        this.setState({
          agentId: []
        })

        document.getElementById(`agentId${key.ApplicationId}`).value = ""
  
}

  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    td8 = tr[i].getElementsByTagName("td")[8];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
      txtValue7 = td7.textContent || td7.innerText;
      txtValue8 = td8.textContent || td8.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7+ txtValue8;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Case" breadcrumbItem="View TAT Details" />
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                      <Row>
                                            <Col md = "3">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            {/* <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData, "User Report")} type="submit">Excel</Button> */}
                                            {/* <ReactHTMLTableToExcel  
                                                className="btn btn-primary"  
                                                table="dataTableExcel"  
                                                filename="Delivery Agent Report"  
                                                sheet="Sheet"  
                                                buttonText="Excel" />   */}
                                            </Col>
                                        </Row>
                                        <br />

                                          <Row>
                                              <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.bank} id = "bank" name = "bank" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Bank</option>
                                                          {this.state.banks.map((rowData, index) => (
                                                            <option value = {rowData.Name}>{rowData.Name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
           
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Products<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.product} id = "product" name = "product" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Product</option>
                                                          {this.state.products.map((rowData, index) => (
                                                            <option value = {rowData.Name}>{rowData.Name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                              <Button color="primary" onClick = {this.handleSubmit.bind(this)} type="submit">Submit</Button>
                                              </Col>
                                              </Row>

                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>Bank</th>
                                                            <th>Product</th>
                                                            <th>Case Type</th>
                                                            <th>TAT Time</th>
                                                            <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.ApplicationId}</td>
                                                        <td>{rowData.CustomerName}</td>
                                                        <td>{rowData.Bank}</td>
                                                        <td>{rowData.ProductName}</td>
                                                        <td>{rowData.FI}</td>
                                                        <td>{rowData.TATTime}</td>
                                                        <td>{rowData.Status}</td>
                                                     </tr>
                                                ))}                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default ViewTATDetails;
import React from 'react';
import { Image, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import logo from "../../assets/images/logo_color.png"

const convertToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        return base64String;
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error converting image to base64:', error);
    }
  };

function CasePdfMail(props) {

    const styles = StyleSheet.create({
        body: {
          paddingTop: 55,
          paddingBottom: 65,
          paddingHorizontal: 35,
          borderBottomColor: 'black',
          borderBottomWidth: 1,
          borderTopColor: 'black',
          borderTopWidth: 1,
        },
        title: {
          fontSize: 15,
          fontWeight: 700,
          color: 'black',
          padding: "10px",
        },
        subtitle: {
          fontSize: 12,
          fontWeight: 700,
          color: 'black',
          margin: '10px',
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 16,
          bottom: 30,
          right:0,
          color: 'grey',
          width: '100%',
          marginRight: 35,
        },
        header: {
          position: 'absolute',
          fontSize: 14,
          top: 10,
          left: 32,
          color: 'grey',
        },
        bold: {
          fontWeight: 700,
          color: '#000',
          fontSize: 12,
        },
        border: {
          borderTopColor: 'black',
          borderTopWidth: 1,
          width: '100%',
          border: '1px solid black'
        },
        ul_title:{
          color: 'black',
          paddingBottom: 5,
        //   borderBottomColor: 'black',
        //   borderBottomWidth: 1,
        //   borderTopColor: 'black',
        //   borderTopWidth: 1,
          marginBottom: 10,
          fontSize: 12
        },
        small: {
            fontSize: 12,
            lineHeight: 1.4,
            color: 'grey',
        },

        smallA: {
          fontSize: 10,
          lineHeight: 1.4,
          marginTop: 8
      },
        pageBackground: {
          position: 'absolute',
          height: '100%',
          width: '110%',
          opacity: 0.7,
        },
        tableHeader: {
          borderTopColor: '#7b162b',
          borderTopWidth: 1,
          borderBottomColor: '#7b162b',
          borderBottomWidth: 1,
          width: '100%',
          padding: '15px 0',
          marginTop: 20,
          color: '#7b162b',
        },
        col1: {
          fontSize: 12,
          fontWeight: 600,
          padding: '10px',
          flex:1,
          borderTopColor: 'black',
          borderTopWidth: 1,
          borderBottomColor: 'black',
          borderBottomWidth: 1,
          borderLeftColor: 'black',
          borderLeftWidth: 1,
          borderRightColor: 'black',
          borderRightWidth: 1
        },
        col1A: {
            fontSize: 12,
            padding: '10px',
            flex:1,
            borderTopColor: 'black',
            borderTopWidth: 1,
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            borderRightColor: 'black',
            borderRightWidth: 1
          },
        col2: {
            fontSize: 12,
            fontWeight: 600,
            padding: '10px',
            flex:1,
            borderTopColor: 'black',
            borderTopWidth: 1,
            // border: '1px solid black'
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            borderRightColor: 'black',
            borderRightWidth: 1
        },
        col2A: {
            fontSize: 12,
            padding: '10px',
            flex:1,
            borderTopColor: 'black',
            borderTopWidth: 1,
            // border: '1px solid black'
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            borderRightColor: 'black',
            borderRightWidth: 1
          },
          image: {
            width: "50%",
            padding: 10
          },
          centerImage: {
            alignItems: "center",
            flexGrow: 1
          },
          pageBackground: {
            position: 'absolute',
            height: '100%',
            width: '110%',
            opacity: 0.7,
          },
      });
    
    return (
    <Document>
      <Page wrap style={styles.body}>
      <View style={{ display:'flex',flexDirection:'row',marginBottom: 10 }}>
            <View>
                <Text style={styles.title}>Adsum Advisory Services Private Limited</Text>
            </View>
            <Image src = {logo} style={{width: 120, height : 50, marginLeft: 100}} />
        </View>
        <View style={{ display:'flex',flexDirection:'row',marginBottom: 5 }}>
            <View>
                <Text style={styles.title}>CPV Status: {props.data.qCpvStatus}</Text>
            </View>
        </View>
        <View style={{ display:'flex',flexDirection:'row',marginBottom: 5 }}>
            <View>
                <Text style={styles.subtitle}>Customer: {props.data.customerName}</Text>
            </View>
        </View>
        <View style={{ display:'flex',flexDirection:'row',marginBottom: 5 }}>
            <View>
                <Text style={styles.subtitle}>Product Name: {props.data.productName}</Text>
            </View>
        </View>
        <View style={{ display:'flex',flexDirection:'row',marginBottom: 5 }}>
            <View>
                <Text style={styles.subtitle}>Bank: {props.data.bank}</Text>
            </View>
        </View>
        <View style={{ display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginBottom: 30 }}>
            <View>
                <Text style={styles.subtitle}>{props.data.fi === "BV" ? "BUSINESS " : "RESIDENT "} VERIFICATION REPORT</Text>
                <Text style={styles.subtitle}>(Strictly Private & Confidential)</Text>
            </View>
        </View>

        <View>
            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                <Text style={styles.col1}>Reference No</Text>
                <Text style={styles.col1A}>{props.data.applicationId}</Text>
            </View>
            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                <Text style={styles.col1}>Applicant Name</Text>
                <Text style={styles.col1A}>{props.data.customerName}</Text>
                <Text style={styles.col1}>Mobile Number</Text>
                <Text style={styles.col1A}>{props.data.mobileNumber}</Text>
            </View>

            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                <Text style={styles.col1}>FI Date</Text>
                <Text style={styles.col1A}>{props.data.fiDate}</Text>
                <Text style={styles.col1}>FI Time</Text>
                <Text style={styles.col1A}>{props.data.fiTime}</Text>
            </View>

            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                <Text style={styles.col1}>FI Flag</Text>
                <Text style={styles.col1A}>{props.data.fiFlag}</Text>
                <Text style={styles.col1}>Date of Birth</Text>
                <Text style={styles.col1A}>{props.data.dob}</Text>
            </View>

            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                <Text style={styles.col1}>Date</Text>
                <Text style={styles.col1A}>{(props.data.submittedAt).split(' ').slice(0, 4).join(' ')}</Text>
                <Text style={styles.col1}>Branch</Text>
                <Text style={styles.col1A}>{props.data.branchName}</Text>
            </View>

            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                <Text style={styles.col1}>Resident Address</Text>
                <Text style={styles.col1A}>{props.data.residentAddresss}</Text>
            </View>
            
            <Text break></Text>

            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                <Text style={styles.col1}>The following information is to be obtained from the applicant or anybody residing at the address </Text>
            </View>

            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                <Text style={styles.col1}>Address Confirmed</Text>
                <Text style={styles.col1A}>{props.data.qAddressConfirmed}</Text>
                <Text style={styles.col1}>Stay Confirmed</Text>
                <Text style={styles.col1A}>{props.data.qStayConfirmed}</Text>
            </View>

            {props.data.fi === "RV" && props.data.productName.toLowerCase() === "agriloan" ? <> 
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Name of Person Met</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMet}</Text>
                    <Text style={styles.col1}>Relation With Applicant</Text>
                    <Text style={styles.col1A}>{props.data.qPersonRelationship}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Stability (in Yrs)</Text>
                    <Text style={styles.col1A}>{props.data.qStability}</Text>
                    <Text style={styles.col1}>Total Agri Land</Text>
                    <Text style={styles.col1A}>{props.data.qTotalAgriLand}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Land Details (PATTA No)</Text>
                    <Text style={styles.col1A}>{props.data.qLandDetails}</Text>
                    <Text style={styles.col1}>Source of Irrigation</Text>
                    <Text style={styles.col1A}>{props.data.qIrrigationSource}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Type of Crops</Text>
                    <Text style={styles.col1A}>{props.data.qCropType}</Text>
                    <Text style={styles.col1}>Ownership Residence</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceOwnership}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>TFM</Text>
                    <Text style={styles.col1A}>{props.data.qFamilyMembersCount}</Text>
                    <Text style={styles.col1}>Earning</Text>
                    <Text style={styles.col1A}>{props.data.qEarningMembers}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Office Address</Text>
                    <Text style={styles.col1A}>{props.data.qOfficeAddress}</Text>
                    <Text style={styles.col1}>Residence Proof Sighted</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceProofId}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Agriculture Lending (If Any)</Text>
                    <Text style={styles.col1A}>{props.data.qAgricultureLending}</Text>
                    <Text style={styles.col1}>Interior</Text>
                    <Text style={styles.col1A}>{props.data.qInterior}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Exterior</Text>
                    <Text style={styles.col1A}>{props.data.qExterior}</Text>
                    <Text style={styles.col1}>Neighbour Check1</Text>
                    <Text style={styles.col1A}>{props.data.qNeighbour1}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Neighbour Check 2</Text>
                    <Text style={styles.col1A}>{props.data.qNeighbour2}</Text>
                    <Text style={styles.col1}>CPV Status</Text>
                    <Text style={styles.col1A}>{props.data.qCpvStatus}</Text>
                </View>


                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Reject Reason</Text>
                    <Text style={styles.col1A}>{props.data.qRejectReason}</Text>
                    <Text style={styles.col1}>Agent Remarks</Text>
                    <Text style={styles.col1A}>{props.data.qAgentRemarks}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Agent Name</Text>
                    <Text style={styles.col1A}>{props.data.agentName}</Text>
                </View>
            </> : null}

            {props.data.fi === "BV" ? <> 
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Person Met</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMet}</Text>
                    <Text style={styles.col1}>Person Met Designation</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMetDesignation}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Name of Co. /Officer</Text>
                    <Text style={styles.col1A}>{props.data.qCoOffice}</Text>
                    <Text style={styles.col1}>Business Address</Text>
                    <Text style={styles.col1A}>{props.data.officeAddress}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Applicant Designation</Text>
                    <Text style={styles.col1A}>{props.data.qPersonDesignation}</Text>
                    <Text style={styles.col1}>Nature of Business</Text>
                    <Text style={styles.col1A}>{props.data.qNature}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Office Ownership</Text>
                    <Text style={styles.col1A}>{props.data.qOfficeOwnership}</Text>
                    <Text style={styles.col1}>Working Since</Text>
                    <Text style={styles.col1A}>{props.data.qWorkingSince}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Approx Income</Text>
                    <Text style={styles.col1A}>{props.data.qApproxIncome}</Text>
                    <Text style={styles.col1}>Number of Employees</Text>
                    <Text style={styles.col1A}>{props.data.qNumberEmployees}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Stocks</Text>
                    <Text style={styles.col1A}>{props.data.qStocks}</Text>
                    <Text style={styles.col1}>Nature of Business Proofs</Text>
                    <Text style={styles.col1A}>{props.data.qBusinessProof}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Business Activity</Text>
                    <Text style={styles.col1A}>{props.data.qBusinessActivity}</Text>
                    <Text style={styles.col1}>Name Mentioned on SignBoard</Text>
                    <Text style={styles.col1A}>{props.data.qSignBoardText === '' ? 'No' : props.data.qSignBoardText}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Proof of Office Seen</Text>
                    <Text style={styles.col1A}>{props.data.qOfficeSeenText === '' ? 'No' : props.data.qOfficeSeenText}</Text>
                    <Text style={styles.col1}>Vehicle Details</Text>
                    <Text style={styles.col1A}>{props.data.qVehicleDetails}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Distance from Branch (in KM)</Text>
                    <Text style={styles.col1A}>{props.data.qBranchDistance}</Text>
                    <Text style={styles.col1}>Stability (in Yrs)</Text>
                    <Text style={styles.col1A}>{props.data.qStability}</Text>
                </View>


                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Customer Sign</Text>
                    <Text style={styles.col1A}>{props.data.qustomerSignature}</Text>
                    <Text style={styles.col1}>TPC 1 Name</Text>
                    <Text style={styles.col1A}>{props.data.qTpc1}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>TPC 1 Check with</Text>
                    <Text style={styles.col1A}>{props.data.qTpc1Check}</Text>
                    <Text style={styles.col1}>TPC 2 Name</Text>
                    <Text style={styles.col1A}>{props.data.qTpc2}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>TPC 2 Check with</Text>
                    <Text style={styles.col1A}>{props.data.qTpc2Check}</Text>
                    <Text style={styles.col1}>Verifier Feedback</Text>
                    <Text style={styles.col1A}>{props.data.qCpvStatus}</Text>
                </View>

                <Text break></Text>


                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Reject Reason</Text>
                    <Text style={styles.col1A}>{props.data.qRejectReason}</Text>
                    <Text style={styles.col1}>Agent Remarks</Text>
                    <Text style={styles.col1A}>{props.data.qAgentRemarks}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Agent Name</Text>
                    <Text style={styles.col1A}>{props.data.agentName}</Text>
                </View>
            </> : null }

            {props.data.fi === "RV" && props.data.productName.toLowerCase() !== "agriloan" ? <>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Person Met</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMet}</Text>
                    <Text style={styles.col1}>Person Met Designation</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMetDesignation}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Relation With Applicant</Text>
                    <Text style={styles.col1A}>{props.data.qPersonRelationship}</Text>
                    <Text style={styles.col1}>Stability (in Yrs)</Text>
                    <Text style={styles.col1A}>{props.data.qStability}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Age</Text>
                    <Text style={styles.col1A}>{props.data.qAge}</Text>
                    <Text style={styles.col1}>Education</Text>
                    <Text style={styles.col1A}>{props.data.qEducation}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Ownership Residence</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceOwnership}</Text>
                    <Text style={styles.col1}>TFM</Text>
                    <Text style={styles.col1A}>{props.data.qFamilyMembersCount}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Earning</Text>
                    <Text style={styles.col1A}>{props.data.qEarningMembersCount}</Text>
                    <Text style={styles.col1}>Spouse Working</Text>
                    <Text style={styles.col1A}>{props.data.qSpouseWorking}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Rent Per Month</Text>
                    <Text style={styles.col1A}>{props.data.qRent}</Text>
                    <Text style={styles.col1}>Verified From</Text>
                    <Text style={styles.col1A}>{props.data.qVerifiedFrom}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Area (In Sqft)</Text>
                    <Text style={styles.col1A}>{props.data.qArea}</Text>
                    <Text style={styles.col1}>Ease of Locate Address</Text>
                    <Text style={styles.col1A}>{props.data.qEaseOfLocate}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Landmark</Text>
                    <Text style={styles.col1A}>{props.data.qLandmark}</Text>
                    <Text style={styles.col1}>Type of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qTypeOfResidence}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Locality of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qLocalityOfResidence}</Text>
                    <Text style={styles.col1}>Area of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qAreaofResidence}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Residence Construction</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceConstruction}</Text>
                    <Text style={styles.col1}>Comment on Exterior</Text>
                    <Text style={styles.col1A}>{props.data.qCommentOnExterior}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Comment on Interior</Text>
                    <Text style={styles.col1A}>{props.data.qCommentOnInterior}</Text>
                    <Text style={styles.col1}>Asset seen at Residence</Text>
                    <Text style={styles.col1A}>{props.data.qAssetSeenAtResidence}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Third Party</Text>
                    <Text style={styles.col1A}>{props.data.qPersonNameVerification}</Text>
                    <Text style={styles.col1}>Applicant Details Confirmed</Text>
                    <Text style={styles.col1A}>{props.data.qApplicantDetailsConfirmed}</Text>
                </View>

                <Text break></Text>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Negative Feedback (If Any)</Text>
                    <Text style={styles.col1A}>{props.data.qNegativeFeedback}</Text>
                    <Text style={styles.col1}>Application Occupation</Text>
                    <Text style={styles.col1A}>{props.data.qOccupation === "Agriculture" ? props.data.qLandDetails : props.data.qIncome}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Resident Locked During Visit</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceLocked}</Text>
                    <Text style={styles.col1}>Reject Reason</Text>
                    <Text style={styles.col1A}>{props.data.qRejectReason}</Text>
                </View>
            </> : null }


            {props.data.fi === "PV" && props.data.productName.toLowerCase() !== "agriloan" ? <> 
            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Person Met</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMet}</Text>
                    <Text style={styles.col1}>Person Met Designation</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMetDesignation}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Relation With Applicant</Text>
                    <Text style={styles.col1A}>{props.data.qPersonRelationship}</Text>
                    <Text style={styles.col1}>Stability (in Yrs)</Text>
                    <Text style={styles.col1A}>{props.data.qStability}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Age</Text>
                    <Text style={styles.col1A}>{props.data.qAge}</Text>
                    <Text style={styles.col1}>Education</Text>
                    <Text style={styles.col1A}>{props.data.qEducation}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Ownership Residence</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceOwnership}</Text>
                    <Text style={styles.col1}>TFM</Text>
                    <Text style={styles.col1A}>{props.data.qFamilyMembersCount}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Earning</Text>
                    <Text style={styles.col1A}>{props.data.qEarningMembersCount}</Text>
                    <Text style={styles.col1}>Spouse Working</Text>
                    <Text style={styles.col1A}>{props.data.qSpouseWorking}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Rent Per Month</Text>
                    <Text style={styles.col1A}>{props.data.qRent}</Text>
                    <Text style={styles.col1}>Verified From</Text>
                    <Text style={styles.col1A}>{props.data.qVerifiedFrom}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Area (In Sqft)</Text>
                    <Text style={styles.col1A}>{props.data.qArea}</Text>
                    <Text style={styles.col1}>Ease of Locate Address</Text>
                    <Text style={styles.col1A}>{props.data.qEaseOfLocate}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Landmark</Text>
                    <Text style={styles.col1A}>{props.data.qLandmark}</Text>
                    <Text style={styles.col1}>Type of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qTypeOfResidence}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Locality of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qLocalityOfResidence}</Text>
                    <Text style={styles.col1}>Area of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qAreaofResidence}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Residence Construction</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceConstruction}</Text>
                    <Text style={styles.col1}>Comment on Exterior</Text>
                    <Text style={styles.col1A}>{props.data.qCommentOnExterior}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Comment on Interior</Text>
                    <Text style={styles.col1A}>{props.data.qCommentOnInterior}</Text>
                    <Text style={styles.col1}>Asset seen at Residence</Text>
                    <Text style={styles.col1A}>{props.data.qAssetSeenAtResidence}</Text>
                </View>


                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Third Party</Text>
                    <Text style={styles.col1A}>{props.data.qPersonNameVerification}</Text>
                    <Text style={styles.col1}>Applicant Details Confirmed</Text>
                    <Text style={styles.col1A}>{props.data.qApplicantDetailsConfirmed}</Text>
                </View>

                <Text break></Text>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Negative Feedback (If Any)</Text>
                    <Text style={styles.col1A}>{props.data.qNegativeFeedback}</Text>
                    <Text style={styles.col1}>Application Occupation</Text>
                    <Text style={styles.col1A}>{props.data.qOccupation === "Agriculture" ? props.data.qLandDetails : props.data.qIncome}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Resident Locked During Visit</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceLocked}</Text>
                    <Text style={styles.col1}>Reject Reason</Text>
                    <Text style={styles.col1A}>{props.data.qRejectReason}</Text>
                </View>
            </> : null }

            {props.data.fi !== "PV" && props.data.fi !== "BV" && props.data.fi !== "RV" ? <>
            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Person Met</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMet}</Text>
                    <Text style={styles.col1}>Person Met Designation</Text>
                    <Text style={styles.col1A}>{props.data.qPersonMetDesignation}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Relation With Applicant</Text>
                    <Text style={styles.col1A}>{props.data.qPersonRelationship}</Text>
                    <Text style={styles.col1}>Stability (in Yrs)</Text>
                    <Text style={styles.col1A}>{props.data.qStability}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Age</Text>
                    <Text style={styles.col1A}>{props.data.qAge}</Text>
                    <Text style={styles.col1}>Education</Text>
                    <Text style={styles.col1A}>{props.data.qEducation}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Ownership Residence</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceOwnership}</Text>
                    <Text style={styles.col1}>TFM</Text>
                    <Text style={styles.col1A}>{props.data.qFamilyMembersCount}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Earning</Text>
                    <Text style={styles.col1A}>{props.data.qEarningMembersCount}</Text>
                    <Text style={styles.col1}>Spouse Working</Text>
                    <Text style={styles.col1A}>{props.data.qSpouseWorking}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Rent Per Month</Text>
                    <Text style={styles.col1A}>{props.data.qRent}</Text>
                    <Text style={styles.col1}>Verified From</Text>
                    <Text style={styles.col1A}>{props.data.qVerifiedFrom}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Area (In Sqft)</Text>
                    <Text style={styles.col1A}>{props.data.qArea}</Text>
                    <Text style={styles.col1}>Ease of Locate Address</Text>
                    <Text style={styles.col1A}>{props.data.qEaseOfLocate}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>   
                    <Text style={styles.col1}>Landmark</Text>
                    <Text style={styles.col1A}>{props.data.qLandmark}</Text>
                    <Text style={styles.col1}>Type of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qTypeOfResidence}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Locality of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qLocalityOfResidence}</Text>
                    <Text style={styles.col1}>Area of Residence</Text>
                    <Text style={styles.col1A}>{props.data.qAreaofResidence}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Residence Construction</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceConstruction}</Text>
                    <Text style={styles.col1}>Comment on Exterior</Text>
                    <Text style={styles.col1A}>{props.data.qCommentOnExterior}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Comment on Interior</Text>
                    <Text style={styles.col1A}>{props.data.qCommentOnInterior}</Text>
                    <Text style={styles.col1}>Asset seen at Residence</Text>
                    <Text style={styles.col1A}>{props.data.qAssetSeenAtResidence}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Third Party</Text>
                    <Text style={styles.col1A}>{props.data.qPersonNameVerification}</Text>
                    <Text style={styles.col1}>Applicant Details Confirmed</Text>
                    <Text style={styles.col1A}>{props.data.qApplicantDetailsConfirmed}</Text>
                </View>

                <Text break></Text>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Negative Feedback (If Any)</Text>
                    <Text style={styles.col1A}>{props.data.qNegativeFeedback}</Text>
                    <Text style={styles.col1}>Application Occupation</Text>
                    <Text style={styles.col1A}>{props.data.qOccupation === "Agriculture" ? props.data.qLandDetails : props.data.qIncome}</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                    <Text style={styles.col1}>Resident Locked During Visit</Text>
                    <Text style={styles.col1A}>{props.data.qResidenceLocked}</Text>
                    <Text style={styles.col1}>Reject Reason</Text>
                    <Text style={styles.col1A}>{props.data.qRejectReason}</Text>
                </View>
             </> : null }
        </View>

        {props.data.fi === "RV" && props.data.productName.toLowerCase() === "agriloan" ? <>
        <Text break></Text>
            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                    <Text style={styles.col2}>Case Summary</Text>
            </View>

            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                {props.data.qCpvStatus === "Positive" ?
                <Text style={styles.col2A}>
                    Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')}, Address Confirmation: {props.data.qAddressConfirmed}, Name of Person Met: {props.data.qPersonMet}, Relation With Applicant: {props.data.qPersonRelationship},
                    Total Agri Land: {props.data.qTotalAgriLand}, Land Details (PATTA No): {props.data.qLandDetails}, Source of Irrigation: {props.data.qIrrigationSource},
                    Type of Crops: {props.data.qCropType}, Ownership Residence: {props.data.qResidenceOwnership}, TFM: {props.data.qFamilyMembersCount}, Earning: {props.data.qEarningMembersCount},
                    Neighbour Check1: {props.data.qNeighbour1}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
                </Text> :
                <Text style={styles.col2A}>
                    Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
                </Text> }
            </View>

            {props.data.photos.length > 0 ? 
            <>
            {console.log("Phots", props.data.phots)}
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                        <Text style={styles.col2}>Applicant Photos</Text>
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(0, 2).map((rowData, index) => (
                  <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(2, 4).map((rowData, index) => (
                  <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(4, 6).map((rowData, index) => ( 
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
            </> : null }

                <Image src = "https://firebasestorage.googleapis.com/v0/b/locationtec-f56ff.appspot.com/o/Mail%2F%5Bobject%20File%5DTue%20Jan%2025%202022%2022%3A27%3A24%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=c40dfdb2-c30a-44f7-9959-f11523a191a9" 
                style = {{height: 50, width: 50}} /> 
        </> : null }

        {props.data.fi === "BV" ? <>
        <Text break></Text>
            <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                    <Text style={styles.col2}>Case Summary</Text>
            </View>

             <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
                {props.data.qCpvStatus === "Positive" ?
                <Text style={styles.col2A}>
                    Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')} || Person Met: {props.data.qPersonMet}, Person Met Designation: {props.data.qPersonMetDesignation}, Name of Co. /Officer: {props.data.qCoOffice}  
                    Address Confirmation: {props.data.qAddressConfirmed}, Applicant Designation: {props.data.qPersonDesignation}, Business Adddress: {props.data.officeAddress}, Nature of Business: {props.data.qNature}
                    Office Ownership: {props.data.qOfficeOwnership}, Working Since: {props.data.qWorkingSince}, Number of Employees: {props.data.qNumberEmployees}, Stocks: {props.data.qStocks}
                   , Business Activity: {props.data.qBusinessActivity}, Name Mentioned on SignBoard: {props.data.qSignBoardText},
                    TPC 1 Name: {props.data.qTpc1},
                    TPC 2 Name: {props.data.qTpc2}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude: {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
                </Text> :
                <Text style={styles.col2A}>
                    Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')}, Agent Remarks: {props.data.qAgentRemarks}, Latitude: {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
                </Text> }
            </View>

            {props.data.photos.length > 0 ? 
            <>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                        <Text style={styles.col2}>Applicant Photos</Text>
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(0, 2).map((rowData, index) => (
                  <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(2, 4).map((rowData, index) => (
                  <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(4, 6).map((rowData, index) => (
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
            </> : null }

                <Image src = "https://firebasestorage.googleapis.com/v0/b/locationtec-f56ff.appspot.com/o/Mail%2F%5Bobject%20File%5DTue%20Jan%2025%202022%2022%3A27%3A24%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=c40dfdb2-c30a-44f7-9959-f11523a191a9" 
            style = {{height: 50, width: 50}} /> 
        </> : null }

        {props.data.fi === "RV" && props.data.productName.toLowerCase() !== "agriloan" ? <>
        <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                <Text style={styles.col2}>Case Summary</Text>
        </View>

        <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
            {props.data.qCpvStatus === "Positive" ?
            <Text style={styles.col2A}>
            Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')} , Address Confirmation: {props.data.qAddressConfirmed}, Name of Person Met: {props.data.qPersonMet}, Relation With Applicant: {props.data.qPersonRelationship}, 
                Ownership Residence: {props.data.qResidenceOwnership}, Age: {props.data.qAge} ,  Education: {props.data.qEducation} , TFM: {props.data.qFamilyMembersCount}, Earning: {props.data.qEarningMembersCount}, 
                Rent Per Month: {props.data.qRent}, Area (In Sqft): {props.data.qArea}, Landmark: {props.data.qLandmark}, Type of Residence: {props.data.qTypeOfResidence},  Locality of Residence: {props.data.qLocalityOfResidence}, 
                Residence Construction: {props.data.qResidenceConstruction}, Name of the Person: {props.data.qPersonNameVerification},
                Applicant Details Confirmed: {props.data.qApplicantDetailsConfirmed}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
            </Text> :
             <Text style={styles.col2A}>
                Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')} , Landmark: {props.data.qLandmark}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
             </Text> }
        </View>

        {props.data.photos.length > 0 ? 
            <>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                        <Text style={styles.col2}>Applicant Photos</Text>
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(0, 2).map((rowData, index) => (
                  <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(2, 4).map((rowData, index) => (
                  <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(4, 6).map((rowData, index) => (
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
            </> : null }

        <Image src = "https://firebasestorage.googleapis.com/v0/b/locationtec-f56ff.appspot.com/o/Mail%2F%5Bobject%20File%5DTue%20Jan%2025%202022%2022%3A27%3A24%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=c40dfdb2-c30a-44f7-9959-f11523a191a9" 
            style = {{height: 50, width: 50}} /> 
        </> : null }

        {props.data.fi === "PV" && props.data.productName.toLowerCase() !== "agriloan" ? <>
        <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                <Text style={styles.col2}>Case Summary</Text>
        </View>

        <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
        {props.data.qCpvStatus === "Positive" ?
            <Text style={styles.col2A}>
            Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')}, Address Confirmation: {props.data.qAddressConfirmed}, Name of Person Met: {props.data.qPersonMet}, Relation With Applicant: {props.data.qPersonRelationship}, 
                Ownership Residence: {props.data.qResidenceOwnership}, TFM: {props.data.qFamilyMembersCount}, Earning: {props.data.qEarningMembersCount}, 
                Rent Per Month: {props.data.qRent}, Area (In Sqft): {props.data.qArea}, Landmark: {props.data.qLandmark}, Type of Residence: {props.data.qTypeOfResidence},  Locality of Residence: {props.data.qLocalityOfResidence}, 
                Residence Construction: {props.data.qResidenceConstruction}, Name of the Person: {props.data.qPersonNameVerification},
                Applicant Details Confirmed: {props.data.qApplicantDetailsConfirmed}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
            </Text> :
            <Text style={styles.col2A}>
                Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')}, Landmark: {props.data.qLandmark}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
            </Text> }
        </View>

        {props.data.photos.length > 0 ? 
            <>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                        <Text style={styles.col2}>Applicant Photos</Text>
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(0, 2).map((rowData, index) => (
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(2, 4).map((rowData, index) => (
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(4, 6).map((rowData, index) => (
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
            </> : null }

        <Image src = "https://firebasestorage.googleapis.com/v0/b/locationtec-f56ff.appspot.com/o/Mail%2F%5Bobject%20File%5DTue%20Jan%2025%202022%2022%3A27%3A24%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=c40dfdb2-c30a-44f7-9959-f11523a191a9" 
            style = {{height: 50, width: 50}} /> 
        </> : null }

        {props.data.fi !== "PV" && props.data.fi !== "BV" && props.data.fi !== "RV" ? <>
        <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                <Text style={styles.col2}>Case Summary</Text>
        </View>

        <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '0px 10px'}}>
        {props.data.qCpvStatus === "Positive" ?
            <Text style={styles.col2A}>
            Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')}, Address Confirmation: {props.data.qAddressConfirmed}, Name of Person Met: {props.data.qPersonMet}, Relation With Applicant: {props.data.qPersonRelationship}, 
                Ownership Residence: {props.data.qResidenceOwnership}, TFM: {props.data.qFamilyMembersCount}, Earning: {props.data.qEarningMembersCount}, 
                Rent Per Month: {props.data.qRent}, Area (In Sqft): {props.data.qArea}, Landmark: {props.data.qLandmark}, Type of Residence: {props.data.qTypeOfResidence},  Locality of Residence: {props.data.qLocalityOfResidence}, 
                Residence Construction: {props.data.qResidenceConstruction}, Name of the Person: {props.data.qPersonNameVerification},
                Applicant Details Confirmed: {props.data.qApplicantDetailsConfirmed}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
            </Text> :
            <Text style={styles.col2A}>
                Visit Date and Time:  {(props.data.submittedAt).split(' ').slice(0, 4).join(' ')}, Landmark: {props.data.qLandmark}, Agent Name: {props.data.agentName}, Agent Remarks: {props.data.qAgentRemarks}, Latitude {props.data.markerLatitude}, Longitude: {props.data.markerLongitude}
            </Text> }
        </View>

        {props.data.photos.length > 0 ? 
            <>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                        <Text style={styles.col2}>Applicant Photos</Text>
                </View>

                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(0, 2).map((rowData, index) => (
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(2, 4).map((rowData, index) => (
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>
                <View style={{ display:'flex',flexDirection:'row',justifyContent:'flex-start', padding: '15px 10px 0px'}}>
                {props.data.photos.slice(4, 6).map((rowData, index) => (
                   <Image src = {{uri: rowData.Photo, method: 'GET', headers: {"Cache-Control": "no-cache"}}} style={{width: 200, height : 200, padding: 10}} />
                ))}
                </View>

            </> : null }

        <Image src = "https://firebasestorage.googleapis.com/v0/b/locationtec-f56ff.appspot.com/o/Mail%2F%5Bobject%20File%5DTue%20Jan%2025%202022%2022%3A27%3A24%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=c40dfdb2-c30a-44f7-9959-f11523a191a9" 
            style = {{height: 50, width: 50}} />    
        </> : null }

        
      </Page>
    </Document>
);
}

export default CasePdfMail;
import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import CardTitle from "reactstrap/lib/CardTitle";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";


class BillingPrice extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.bankOnChange = this.bankOnChange.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.handleSubmit,
        '3': this.handleSubmit1,
        '4': this.bankOnChange
    };
    this.state = {
      firestoreData: [],
      bank: '',
      localPrice: '',
      outstation1Price: '',
      outstation2Price: '',
      agentLocalPrice: '',
      agentOutstation1Price: '',
      agentOutstation2Price: '',
      menu: 'Select',
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      pushid: '',
      banks: [],
      billingId: '',
      agentBillingId: '',
      states: [],
      state: ''
    };
  }

  componentDidMount() {
    let comp = this

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
      comp.setState({
        banks: response.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    var config1 = {
      method: 'get',
      url: API_BASE_URL + '/auth/state',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config1)
    .then(function (response1) {
      comp.setState({
        states: response1.data
      })
    })
    .catch(function (error1) {
      console.log(error1);
      // comp.handleNon200Response(error, "1");
    });

    // var config = {
    //   method: 'get',
    //   url: API_BASE_URL +  '/auth/bankbilling',
    //   headers: { 
    //     apikey: API_KEY, 
    //     Authorization: "Bearer " + getCookie("at"),
    //   }
    // };
    
    // axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
    

    // firebase.firestore().collection("AgentBillingPrice").doc("Agent")
    // .onSnapshot(function(querySnapshot) {
    //   if(querySnapshot.exists) {
    //     comp.setState({
    //       agentLocalPrice: querySnapshot.data().LocalPrice,
    //       agentOutstation1Price: querySnapshot.data().OutStation1Price,
    //       agentOutstation2Price: querySnapshot.data().OutStation2Price,
    //     })
    //   } else {
    //     comp.setState({
    //       agentLocalPrice: "",
    //       agentOutstation1Price: "",
    //       agentOutstation2Price: "",
    //     })
    //   }
    // });
    comp.fetchList()
  }

  async fetchList() {
    // let comp = this

    // var config = {
    //   method: 'get',
    //   url: API_BASE_URL + '/auth/agentBilling',
    //   headers: { 
    //     apikey: API_KEY, 
    //     Authorization: "Bearer " + getCookie("at"),
    //   }
    // };
    
    // axios(config)
    // .then(function (response) {
    //   if(response.data.length > 0) {
    //     comp.setState({
    //         agentLocalPrice: response.data[0].localprice,
    //         agentOutstation1Price: response.data[0].outstation1price,
    //         agentOutstation2Price: response.data[0].outstation2price,
    //         agentBillingId: response.data[0]._id
    //     })
    //   } else {
    //     comp.setState({
    //       agentLocalPrice: '',
    //       agentOutstation1Price: '',
    //       agentOutstation2Price: '',
    //       agentBillingId: ''
    //     })
    //   }
    // })
    // .catch(function (error) {
    //   console.log(error);
    //   comp.setState({
    //     agentLocalPrice: '',
    //     agentOutstation1Price: '',
    //     agentOutstation2Price: '',
    //     agentBillingId: ''
    //   })
    //   // comp.handleNon200Response(error, "1");
    // });
  }

  bankOnChange(e) {
      let comp = this
      let value = e.target.value

      var jsonData = JSON.stringify({
        bank: e.target.value,
      });

    var config = {
      method: 'post',
      url: API_BASE_URL +  '/auth/bankbilling/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data : jsonData
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      if(response.data.length > 0) {
        comp.setState({
          localPrice: response.data[0].localprice,
          outstation1Price: response.data[0].outstation1price,
          outstation2Price: response.data[0].outstation2price,
          bank: value,
          billingId: response.data[0]._id
        })
      } else {
        comp.setState({
          localPrice: "",
          outstation1Price: "",
          outstation2Price: "",
          bank: value,
          billingId: ""
        })
      }
    })
    .catch(function (error) {
      console.log(error.response.data);
      comp.setState({
        localPrice: "",
        outstation1Price: "",
        outstation2Price: "",
        bank: value,
        billingId: ""
      })
      comp.handleNon200Response(error, "4");
    });
  }

  stateOnChange(e) {
    let comp = this
    let value = e.target.value

    var jsonData = JSON.stringify({
      state: e.target.value,
    });

  var config = {
    method: 'post',
    url: API_BASE_URL +  '/auth/agentBillingByState',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json'
    },
    data : jsonData
  };
  
  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    if(response.data.length > 0) {
      comp.setState({
        agentLocalPrice: response.data[0].localprice,
        agentOutstation1Price: response.data[0].outstation1price,
        agentOutstation2Price: response.data[0].outstation2price,
        agentBillingId: response.data[0]._id,
        state: value
      })
    } else {
      
      comp.setState({
        agentLocalPrice: '',
        agentOutstation1Price: '',
        agentOutstation2Price: '',
        agentBillingId: '',
        state: value
      })
    }
  })
  .catch(function (error) {
    console.log(error.response.data);
    comp.setState({
      agentLocalPrice: '',
      agentOutstation1Price: '',
      agentOutstation2Price: '',
      agentBillingId: '',
      state: value
    })
    comp.handleNon200Response(error, "4");
  });
}


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    if(data.bank === '') {
        alert("Select Bank");
        document.getElementById("bank").focus();
        return;
    }

    if(data.localPrice === '') {
        alert("Enter Local Price");
        document.getElementById("localPrice").focus();
        return;
    }

    if(data.outstation1Price === '') {
      alert("Enter OutStation 1 Price");
      document.getElementById("outstation1Price").focus();
      return;
    }

    if(data.outstation2Price === '') {
      alert("Enter OutStation 2 Price");
      document.getElementById("outstation2Price").focus();
      return;
    }

      var jsonData = JSON.stringify({
        "bank": data.bank,
        "localprice": data.localPrice,
        "outstation1price": data.outstation1Price,
        "outstation2price": data.outstation2Price
      });

    if(data.billingId !== "") {  
      var config = {
        method: "put",
        url: API_BASE_URL + "/auth/bankbilling/" + data.billingId,
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }
  
      axios(config)
        .then(function (response) {
          if (response.status == 200) {
            alert("Updated Successfully")
          }
        })
        .catch(function (error) {
          console.log(error.response.data)
          comp.handleNon200Response(error, "2")
        })
    } else {
      console.log("Inside post")
      console.log(jsonData)
      var config = {
        method: "post",
        url: API_BASE_URL + "/auth/bankbilling",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }
  
      axios(config)
        .then(function (response) {
          if (response.status == 201) {
            alert("Updated Successfully")
          }
        })
        .catch(function (error) {
          console.log(error.response.data)
          comp.handleNon200Response(error, "2")
        })
    }  
    
    // document.getElementById("create-form").reset()
} 

handleSubmit1(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    console.log(data.agentLocalPrice)

    
    if(data.state === '') {
      alert("Select State");
      document.getElementById("state").focus();
      return;
    }


    if(data.agentLocalPrice === '') {
      alert("Enter Agent Local Price");
      document.getElementById("agentLocalPrice").focus();
      return;
    }

    if(data.agentOutstation1Price === '') {
      alert("Enter Agent OutStation 1 Price");
      document.getElementById("agentOutstation1Price").focus();
      return;
    }

    if(data.agentOutstation2Price === '') {
      alert("Enter Agent OutStation 2 Price");
      document.getElementById("agentOutstation2Price").focus();
      return;
    }

    var jsonData = JSON.stringify({
        "state": data.state,
        "localprice": data.agentLocalPrice,
        "outstation1price": data.agentOutstation1Price,
        "outstation2price": data.agentOutstation2Price
      });

    if(data.agentBillingId !== "") {  
      var config = {
        method: "put",
        url: API_BASE_URL + "/auth/agentBilling/" + data.agentBillingId,
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }

      axios(config)
        .then(function (response) {
          if (response.status == 200) {
            alert("Agent Billing Price Updated Successfully")
          }
        })
        .catch(function (error) {
          console.log(error.response.data)
          comp.handleNon200Response(error, "3")
        })
    } else {
      console.log("Inside post")
      var config = {
        method: "post",
        url: API_BASE_URL + "/auth/agentBilling",
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }

      axios(config)
        .then(function (response) {
          if (response.status == 201) {
            alert("Agent Billing Price Updated Successfully")
          }
        })
        .catch(function (error) {
          console.log(error.response.data)
          comp.handleNon200Response(error, "3")
        })
    }  
} 

handleNon200Response = (error, functionIndex) => {
  if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
    sessionExpired();
  } 
}


  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Billing Price" />
                <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.bank} id = "bank" name = "bank" onChange = {this.bankOnChange.bind(this)}>
                                                        <option value = "">Select Bank</option>
                                                          {this.state.banks.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                            <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Local Price</Label>
                                                        <Input
                                                          name="localPrice"
                                                          placeholder="Local Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="localPrice"
                                                          value = {this.state.localPrice}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                            <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Outstation1 Price</Label>
                                                        <Input
                                                          name="outstation1Price"
                                                          placeholder="Outstation1 Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="outstation1Price"
                                                          value = {this.state.outstation1Price}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                            <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Outstation2 Price</Label>
                                                        <Input
                                                          name="outstation2Price"
                                                          placeholder="Outstation2 Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="outstation2Price"
                                                          value = {this.state.outstation2Price}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>                                                 
                                          </Form>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl="12">
                                <Card>
                                  <CardTitle style = {{padding:"10px"}}>Agents</CardTitle>
                                    <CardBody>
                                      <Form id = "create-form1">
                                            <Row>
                                              <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">State<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.state} id = "state" name = "state" onChange = {this.stateOnChange.bind(this)}>
                                                        <option value = "">Select State</option>
                                                          {this.state.states.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                              </Row>
                                              <Row>  
                                            <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Local Price</Label>
                                                        <Input
                                                          name="agentLocalPrice"
                                                          placeholder="Local Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="agentLocalPrice"
                                                          value = {this.state.agentLocalPrice}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Outstation1 Price</Label>
                                                        <Input
                                                          name="agentOutstation1Price"
                                                          placeholder="Outstation1 Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="agentOutstation1Price"
                                                          value = {this.state.agentOutstation1Price}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Outstation2 Price</Label>
                                                        <Input
                                                          name="agentOutstation2Price"
                                                          placeholder="Outstation2 Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="agentOutstation2Price"
                                                          value = {this.state.agentOutstation2Price}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                                <Button color="primary" onClick = {this.handleSubmit1.bind(this)} id = "submit" type="submit">Submit</Button>                                                 
                                          </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                                        </Row>

                                        <Row>
                                          <Col>
                                          <Card>
                                            <CardBody>
                                              

                                          {/* <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Bank</th>
                                                            <th>Status</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.Status}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default BillingPrice;
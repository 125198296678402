import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyBVeEXZGU1E35G5WfYEY7P7LsHmptFVAXw",
  authDomain: "locationtec-f56ff.firebaseapp.com",
  projectId: "locationtec-f56ff",
  storageBucket: "locationtec-f56ff.appspot.com",
  messagingSenderId: "941443095838",
  appId: "1:941443095838:web:29ded419ef5b1769357d0f",
  measurementId: "G-XQJV77G1TY"
};

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }

//   firebase.initializeApp(config);

  export default firebase;
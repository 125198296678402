import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import Select from "react-select";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";


class UserCreation extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.submit = this.handleSubmit.bind(this);
    this.update = this.handleUpdate.bind(this);
    this.delete = this.delete.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.submit,
        '3': this.update,
        '4': this.delete
    };
    this.state = {
      username: '',
      name: '',
      firebaseData: [],
      success_msg: false,
      password: '',
      mobileNumber: '',
      email: '',
      dash1: false,
      case1: false,
      case2: false,
      case3: false,
      case4: false,
      case5: false,
      case6: false,
      report1: false,
      report2: false,
      report3: false,
      report4: false,
      report5: false,
      report6: false,
      report7: false,
      report8: false,
      report9: false,
      reportA: false,
      masters1: false,
      masters2: false,
      masters3: false,
      masters4: false,
      masters5: false,
      masters6: false,      
      masters7: false,
      user1: false,
      user2: false,
      close: 'Active',
      access: 'Select',
      update_msg: false,
      states: [],
      stateName: [],
      banks: [],
      bankName: [],
      dataId: ''
    };
  }

  componentDidMount() {
    let comp = this

    let states = []

    var config = {
        method: 'get',
        url: API_BASE_URL + '/auth/state',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
        }
      };
      
      axios(config)
      .then(function (response) {
          if(response.status == 200) {
            for(let i=0; i< response.data.length; i++) {
                const newData = {
                    options: [{
                        label: response.data[i].name,
                        value: response.data[i].name,
                    }]
                }
                states.push(newData)
            }

            const superAdminData = {
              options: [{
                  label: "SUPER ADMIN",
                  value: "SUPER ADMIN",
              }]
            }

            states.push(superAdminData)

            comp.setState({
                states: states
            })
        }
      })
      .catch(function (error) {
        console.log(error);
        comp.handleNon200Response(error, "1");
      });


      let banks = []

      var config1 = {
          method: 'get',
          url: API_BASE_URL + '/auth/bank',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
          }
        };
        
        axios(config1)
        .then(function (response1) {
            if(response1.status == 200) {
              for(let i=0; i< response1.data.length; i++) {
                  const newData1 = {
                      options: [{
                          label: response1.data[i].name,
                          value: response1.data[i].name,
                      }]
                  }
                  banks.push(newData1)
              }
  
              const superAdminData1 = {
                options: [{
                    label: "SUPER ADMIN",
                    value: "SUPER ADMIN",
                }]
              }
  
              banks.push(superAdminData1)
  
              comp.setState({
                  banks: banks
              })
          }
        })
        .catch(function (error1) {
          console.log(error1);
          // comp.handleNon200Response(error1, "1");
        });
    // firebase.firestore().collection("WebUser")
    // .onSnapshot(function(querySnapshot) {
    //     var cities = [];
    //     querySnapshot.forEach(function(doc) {
    //       const lock = {
    //         UserName: doc.data().UserName,
    //         Name: doc.data().Name,
    //         Password: doc.data().Password,
    //         Role: doc.data().Role,
    //         Position: doc.data().Position,
    //         Status: doc.data().Status,
    //         State: doc.data().State
    //       }
    //       cities.push(lock)
    //     });
    //     comp.setState({
    //       firebaseData: cities
    //     })
    // });

    comp.fetchList()
  }


  async fetchList() {
    let comp = this

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/webUser',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
              comp.setState({
                firebaseData: response.data,
            })
        }
    })
    .catch(function (error) {
      console.log(error);
      // comp.handleNon200Response(error, "1");
    });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }
  


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  defaultState() {
    this.setState({
        username: '',
        name: '',
        success_msg: false,
        password: '',
        mobileNumber: '',
        stateName: [],
        bankName: [],
        email: '',
        dash1: false,
        case1: false,
        case2: false,
        case3: false,
        case4: false,
        case5: false,
        case6: false,
        report1: false,
        report2: false,
        report3: false,
        report4: false,
        report5: false,
        report6: false,
        report7: false,
        report8: false,
        report9: false,
        reportA: false,
        masters1: false,
        masters2: false,
        masters3: false,
        masters4: false,
        masters5: false,
        masters6: false,
        masters7: false,
        user1: false,
        user2: false,
        close: 'Active',
        access: 'Select',
        update_msg: false
    })
  }

  handleSubmit(e) {
    e.preventDefault();


    let data = this.state
    let comp = this


    if(data.username === '') {
        alert("Enter Username");
        document.getElementById("username").focus();
        return;
    }

    if(data.name === '') {
        alert("Enter Name");
        document.getElementById("name").focus();
        return;
    }

    if(data.password === '') {
        alert("Enter Password");
        document.getElementById("password").focus();
        return;
    }

    if(data.stateName.length === 0) {
        alert("Select State");
        document.getElementById("stateName").focus();
        return;
    }

    var st = []

    for(let i=0; i< data.stateName.length; i++) {
      st.push(data.stateName[i].label)
    }

    console.log(st)

    if(data.bankName.length === 0) {
      alert("Select Bank");
      document.getElementById("bankName").focus();
      return;
    }

    var bank = []

    for(let i=0; i< data.bankName.length; i++) {
      bank.push(data.bankName[i].label)
    }

    console.log(bank)

    let role = ''
    if(data.dash1) {
        role+= "dash1" + ","
    }

    if(data.case1) {
      role+= "case1" + ","
    }

    if(data.case2) {
      role+= "case2" + ","
    }

    if(data.case3) {
        role+= "case3" + ","
    }

    if(data.case4) {
        role+= "case4" + ","
    }

    if(data.case5) {
        role+= "case5" + ","
    }

    if(data.case6) {
        role+= "case6" + ","
    }

    if(data.report1) {
        role+= "report1" + ","
    }

    if(data.report2) {
        role+= "report2" + ","
    }

    if(data.report3) {
        role+= "report3" + ","
    }

    if(data.report4) {
        role+= "report4" + ","
    }

    if(data.report5) {
        role+= "report5" + ","
    }

    if(data.report6) {
        role+= "report6" + ","
    }

    if(data.report7) {
        role+= "report7" + ","
    }

    if(data.report8) {
      role+= "report8" + ","
    }

    if(data.report9) {
        role+= "report9" + ","
    }

    if(data.reportA) {
        role+= "reportA" + ","
    }

    if(data.masters1) {
        role+= "masters1" + ","
    }

    if(data.masters2) {
        role+= "masters2" + ","
    }

    if(data.masters3) {
        role+= "masters3" + ","
    }

    if(data.masters4) {
        role+= "masters4" + ","
    }

    if(data.masters5) {
        role+= "masters5" + ","
    }

    if(data.masters6) {
        role+= "masters6" + ","
    }

    if(data.masters7) {
        role+= "masters7" + ","
    }

    if(data.user1) {
        role+= "user1" + ","
    }

    if(data.user2) {
      role+= "user2" + ","
    }

    console.log(role)

    var jsonData = JSON.stringify({
        username: data.username,
        name: data.name,
        password: data.password,
        role: role,
        position: "Admin",
        status: "Active",
        state: st,
        bank: bank
      });
      
      var config = {
        method: 'post',
        url: API_BASE_URL + "/auth/webUser",
        headers: { 
          'apikey': API_KEY, 
          'Authorization': 'Bearer ' + getCookie('at'),
          'Content-Type': 'application/json'
        },
        data : jsonData
      };
      
      axios(config)
      .then(function (response) {
        if(response.status == 201) {
          comp.defaultState()
          comp.fetchList()
          alert("Added Successfully")
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        comp.handleNon200Response(error, "2")
      }); 

      role = ""
      st = []
      bank = []

      document.getElementById("create-form").reset()
} 

editRow(key) {

    window.scroll("top", 0)
    let data = this.state
    let comp = this

    document.getElementById("submit").style.display = "none"
    document.getElementById("update").style.display = "block"

    data.dash1 =  false

    data.case1 = false
    data.case2 = false
    data.case3 = false
    data.case4 = false
    data.case5 = false
    data.case6 = false

    data.report1 = false
    data.report2 = false
    data.report3 = false
    data.report4 = false
    data.report5 = false
    data.report6 = false
    data.report7 = false
    data.report8 = false
    data.report9 = false
    data.reportA = false


    data.masters1 = false
    data.masters2 = false
    data.masters3 =  false
    data.masters4 = false
    data.masters5 = false
    data.masters6 = false
    data.masters7 = false

    data.user1 =  false
    data.user2 =  false
  
    data.username = key.username
    data.name = key.name
    data.password = key.password
    data.stateName = key.state
    data.bankName = key.bank

    console.log(key.role)

    if(key.role.includes("dash1")) {
      this.setState({
          dash1: true
      })
    }

    if(key.role.includes("case1")) {
        this.setState({
            case1: true
        })
      }

      if(key.role.includes("case2")) {
        this.setState({
            case2: true
        })
      }

      if(key.role.includes("case3")) {
        this.setState({
            case3: true
        })
      }

      if(key.role.includes("case4")) {
        this.setState({
            case4: true
        })
      }

      if(key.role.includes("case5")) {
        this.setState({
            case5: true
        })
      }

      if(key.role.includes("case6")) {
        this.setState({
            case6: true
        })
      }

      if(key.role.includes("report1")) {
        this.setState({
            report1: true
        })
      }

      if(key.role.includes("report2")) {
        this.setState({
            report2: true
        })
      }

      if(key.role.includes("report3")) {
        this.setState({
            report3: true
        })
      }

      if(key.role.includes("report4")) {
        this.setState({
            report4: true
        })
      }


      if(key.role.includes("report5")) {
        this.setState({
            report5: true
        })
      }

      if(key.role.includes("report6")) {
        this.setState({
            report6: true
        })
      }

      if(key.role.includes("report7")) {
        this.setState({
            report7: true
        })
      }

      if(key.role.includes("report8")) {
        this.setState({
            report8: true
        })
      }

      if(key.role.includes("report9")) {
        this.setState({
            report9: true
        })
      }

      if(key.role.includes("reportA")) {
        this.setState({
            reportA: true
        })
      }


      if(key.role.includes("masters1")) {
        this.setState({
            masters1: true
        })
      }

      if(key.role.includes("masters2")) {
        this.setState({
            masters2: true
        })
      }

      if(key.role.includes("masters3")) {
        this.setState({
            masters3: true
        })
      }

      if(key.role.includes("masters4")) {
        this.setState({
            masters4: true
        })
      }

      if(key.role.includes("masters5")) {
        this.setState({
            masters5: true
        })
      }

      if(key.role.includes("masters6")) {
        this.setState({
            masters6: true
        })
      }

      if(key.role.includes("masters7")) {
        this.setState({
            masters7: true
        })
      }

      if(key.role.includes("user1")) {
        this.setState({
            user1: true
        })
      }

      if(key.role.includes("user2")) {
        this.setState({
            user2: true
        })
      }

      let st = []

      for(let i = 0; i< key.state.length; i++) {
        let dict = {
            label: key.state[i],
            value: key.state[i],
        }
        st.push(dict)
      }

      comp.setState({
          stateName: st
      })

      let bank = []

      for(let i = 0; i< key.bank.length; i++) {
        let dict = {
            label: key.bank[i],
            value: key.bank[i],
        }
        bank.push(dict)
      }

      comp.setState({
          bankName: bank
      })
  
    document.getElementById("username").value = key.username
    document.getElementById("name").value = key.name
    document.getElementById("password").value = key.password

    document.getElementById("username").disabled=  true

    this.setState({
        dataId: key._id
    })
  }
  
  delete(key) {
      let comp  = this
    if (window.confirm('Are you sure you want to delete the user?')) {
        // firebase.firestore().collection('WebUser').doc(key.UserName).delete();
        // alert("Successfully Deleted!")
        var config = {
            method: 'delete',
            url:  API_BASE_URL + '/auth/webUser/' + key._id,
            headers: { 
              'apikey': API_KEY,
              'Authorization': 'Bearer ' + getCookie('at')
            },
          };
        
          axios(config)
          .then(function (response) {
            if(response.status == 200) {
                comp.fetchList()
                alert("Deleted Successfully")
            }
          })
          .catch(function (error) {
            console.log(error)
            comp.handleNon200Response(error, "4");
          });
      } else {}
  }

  handleUpdate(e) {
    e.preventDefault();
  
  
    let data = this.state

    let comp = this


    if(data.username === '') {
      alert("Enter Username");
      document.getElementById("username").focus();
      return;
  }

  if(data.name === '') {
      alert("Enter Name");
      document.getElementById("name").focus();
      return;
  }

  if(data.password === '') {
      alert("Enter Password");
      document.getElementById("password").focus();
      return;
  }

  if(data.stateName.length == 0) {
    alert("Select State");
    document.getElementById("stateName").focus();
    return;
  }

  var st = []

  for(let i=0; i< data.stateName.length; i++) {
    st.push(data.stateName[i].label)
  }

  console.log(st)

  if(data.bankName.length == 0) {
    alert("Select Bank");
    document.getElementById("bankName").focus();
    return;
  }

  var bank = []

  for(let i=0; i< data.bankName.length; i++) {
    bank.push(data.bankName[i].label)
  }

  console.log(bank)

  let role = ''
  if(data.dash1) {
      role+= "dash1" + ","
  }

  if(data.case1) {
    role+= "case1" + ","
  }

  if(data.case2) {
    role+= "case2" + ","
  }

  if(data.case3) {
      role+= "case3" + ","
  }

  if(data.case4) {
      role+= "case4" + ","
  }

  if(data.case5) {
      role+= "case5" + ","
  }

  if(data.case6) {
      role+= "case6" + ","
  }

  if(data.report1) {
      role+= "report1" + ","
  }

  if(data.report2) {
      role+= "report2" + ","
  }

  if(data.report3) {
      role+= "report3" + ","
  }

  if(data.report4) {
      role+= "report4" + ","
  }

  if(data.report5) {
      role+= "report5" + ","
  }

  if(data.report6) {
      role+= "report6" + ","
  }

  if(data.report7) {
      role+= "report7" + ","
  }

  if(data.report8) {
    role+= "report8" + ","
}

    if(data.report9) {
        role+= "report9" + ","
    }

    if(data.reportA) {
        role+= "reportA" + ","
    }

  if(data.masters1) {
      role+= "masters1" + ","
  }

  if(data.masters2) {
      role+= "masters2" + ","
  }

  if(data.masters3) {
      role+= "masters3" + ","
  }

  if(data.masters4) {
      role+= "masters4" + ","
  }

  if(data.masters5) {
      role+= "masters5" + ","
  }

  if(data.masters6) {
    role+= "masters6" + ","
}

if(data.masters7) {
    role+= "masters7" + ","
}

  if(data.user1) {
      role+= "user1" + ","
  }

  if(data.user2) {
    role+= "user2" + ","
  }

  console.log(role)

  var jsonData = JSON.stringify({
    username: data.username,
    name: data.name,
    password: data.password,
    role: role,
    status: "Active",
    state: st,
    bank: bank
  });
  
  var config = {
    method: 'put',
    url: API_BASE_URL + "/auth/webUser/" + data.dataId,
    headers: { 
      'apikey': API_KEY, 
      'Authorization': 'Bearer ' + getCookie('at'),
      'Content-Type': 'application/json'
    },
    data : jsonData
  };
  
  axios(config)
  .then(function (response) {
    if(response.status == 200) {
      comp.defaultState()
      comp.fetchList()
      alert("Updated Successfully")
    }
  })
  .catch(function (error) {
    console.log(error.response.data)
    comp.handleNon200Response(error, "2")
  }); 


      document.getElementById("create-form").reset()

      document.getElementById("submit").style.display = "none"
      document.getElementById("update").style.display = "block"

      document.getElementById("username").disabled=  false
}

stateOnChnage = stateName => {
    this.setState({ stateName });
}

bankOnChnage = bankName => {
  this.setState({ bankName });
}


  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="User Creation" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Username<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="username"
                                                          placeholder="UserName"
                                                          type="text"
                                                          className="form-control"
                                                          id="username"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                            <Label htmlFor="validationCustom03">Name<span style = {{color: "red"}}>*</span></Label>
                                                            <Input
                                                            name="name"
                                                            placeholder="Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="name"
                                                            onChange = {this.handleChange.bind(this)}
                                                            />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>

                                                </Row>

                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                            <Label htmlFor="validationCustom03">Password<span style = {{color: "red"}}>*</span></Label>
                                                            <Input
                                                            name="password"
                                                            placeholder="Password"
                                                            type="password"
                                                            className="form-control"
                                                            id="password"
                                                            onChange = {this.handleChange.bind(this)}
                                                            />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">State<span style = {{color: "red"}}>*</span></Label>
                                                        <Select
                                                            value = {this.state.stateName}
                                                            isMulti={true}
                                                            onChange={this.stateOnChnage}
                                                            options={this.state.states}
                                                            classNamePrefix="select1-selection"
                                                            id = "stateName"
                                                            placeholder = "Select States"
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'fixed'} 
                                                        />
                                                        {/* <Input type="select"  value = {this.state.stateName} id = "stateName" name = "stateName" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select State</option>
                                                          {this.state.states.map((rowData, index) => (
                                                            <option value = {rowData.Name}>{rowData.Name}</option>
                                                          ))}
                                                      </Input> */}
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Select
                                                            value = {this.state.bankName}
                                                            isMulti={true}
                                                            onChange={this.bankOnChnage}
                                                            options={this.state.banks}
                                                            classNamePrefix="select1-selection"
                                                            id = "bankName"
                                                            placeholder = "Select Banks"
                                                            menuPortalTarget={document.body}
                                                            menuPosition={'fixed'} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                                <br />


                                                <Row>
                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Dashbaord</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="dash1" onChange={() => false} checked={this.state.dash1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ dash1: !this.state.dash1 }) }} >Dashboard</label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Cases</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="case1" onChange={() => false} checked={this.state.case1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ case1: !this.state.case1 }) }} >Search Cases </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="case2" onChange={() => false} checked={this.state.case2} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ case2: !this.state.case2 }) }} >Upload Case for Verification </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="case3" onChange={() => false} checked={this.state.case3} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ case3: !this.state.case3 }) }} >Download FI template </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="case4" onChange={() => false} checked={this.state.case4} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ case4: !this.state.case4 }) }} >Create Case </label>
                                                        </div>


                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="case5" onChange={() => false} checked={this.state.case5} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ case5: !this.state.case5 }) }} >Search UnAssigned Case </label>
                                                        </div>

{/* 
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="case6" onChange={() => false} checked={this.state.case6} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ case6: !this.state.case6 }) }} >View TAT Details </label>
                                                        </div> */}
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Reports</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report1" onChange={() => false} checked={this.state.report1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report1: !this.state.report1 }) }} >Billing Report </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report2" onChange={() => false} checked={this.state.report2} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report2: !this.state.report2 }) }} >Pending Cases </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report3" onChange={() => false} checked={this.state.report3} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report3: !this.state.report3 }) }} >Attendance Report </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report4" onChange={() => false} checked={this.state.report4} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report4: !this.state.report4 }) }} >Case Report </label>
                                                        </div>


                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report5" onChange={() => false} checked={this.state.report5} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report5: !this.state.report5 }) }} >HP Case Reports </label>
                                                        </div>


                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report6" onChange={() => false} checked={this.state.report6} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report6: !this.state.report6 }) }} >Pending HP Case Reports </label>
                                                        </div>


                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report7" onChange={() => false} checked={this.state.report7} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report7: !this.state.report7 }) }} >Verified HP Case Reports </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report8" onChange={() => false} checked={this.state.report8} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report8: !this.state.report8 }) }} >Agent Reports </label>
                                                        </div>


                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report9" onChange={() => false} checked={this.state.report9} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report9: !this.state.report9 }) }} >Agent Realtime Report </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="reportA" onChange={() => false} checked={this.state.reportA} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ reportA: !this.state.reportA }) }} >Agent Pending Report </label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Masters</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters1" onChange={() => false} checked={this.state.masters1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters1: !this.state.masters1 }) }} >Bank Name </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters2" onChange={() => false} checked={this.state.masters2} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters2: !this.state.masters2 }) }} >Agent Approvals </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters3" onChange={() => false} checked={this.state.masters3} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters3: !this.state.masters3 }) }} >Agent InActive </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters4" onChange={() => false} checked={this.state.masters4} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters4: !this.state.masters4 }) }} >Loan Product </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters5" onChange={() => false} checked={this.state.masters5} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters5: !this.state.masters5 }) }} >Billing Price </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters6" onChange={() => false} checked={this.state.masters6} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters6: !this.state.masters6 }) }} >Push Notification </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters7" onChange={() => false} checked={this.state.masters7} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters7: !this.state.masters7 }) }} >State </label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Admin</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="user1" onChange={() => false} checked={this.state.user1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ user1: !this.state.user1 }) }} >Manage Users </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="user2" onChange={() => false} checked={this.state.user2} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ user2: !this.state.user2 }) }} >Email Setup </label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Add</Button>
                                                <Button color="primary" onClick = {this.handleUpdate.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {/* {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ 
                                                      success_msg: false}) }
                                                  >
                                                    Route Successfully Created!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ update_msg: false })}
                                                >
                                                  Route Updated Successfully!
                                                </SweetAlert>
                                              ) : null} */}
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Name</th>
                                                            <th>Username</th>
                                                            <th>Password</th>
                                                            <th>State</th>
                                                            <th>Bank</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.username}</td>
                                                        <td>{rowData.password}</td>
                                                        <td>{rowData.state + ","}</td>
                                                        <td>{rowData.bank + ","}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default UserCreation;
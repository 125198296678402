import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import { Grid, TablePagination } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";


class AttendanceReport extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.funcMap = {
        '1': this.fetchList,
        '2': this.handleSubmit,
        '98': this.handleChangePage,
        '99': this.handleChangeRowsPerPage
    };
    this.state = {
      firebaseData: [],
      firebaseData1: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      agents: [],
      agentId: [],
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      pages: [15, 30, 50, 100],
      page: 0,
      rowsPerPage:15,
      lastVisible: {},
      fromDate: '',
      toDate: '',
      queryParams: {},
      totalRecords: 0
    };
  }

  componentDidMount() {
    let comp = this
    // firebase.firestore().collection("Attendance")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //       loans.push(doc.data())
    //     });
    //     comp.setState({
    //       firebaseData: loans,
    //     })
    // });

    // firebase.firestore().collection("Attendance")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //           const loan = {
    //             Name: doc.data().Name,
    //             MobileNumber: doc.data().MobileNumber,
    //             In: doc.data().In,
    //             Out: doc.data().Out,
    //             InLat: doc.data().InLat,
    //             InLong: doc.data().InLong,
    //             OutLat: doc.data().OutLat,
    //             OutLong: doc.data().OutLong,
    //           }
    //           loans.push(loan)
    //     });
    //     comp.setState({
    //       firebaseData1: loans
    //     })
    // });
    comp.fetchList()
  }

  async fetchList() {
      let comp = this

      var loans = []
      var totalAmount = 0

      let jsonData = {}

      comp.setState({
        queryParams: jsonData
      })
  
      jsonData.pagination = true
      jsonData.pageSize = comp.state.rowsPerPage
      jsonData.pageCount = Number(comp.state.page) + 1
    
      let jsonData1 = JSON.stringify(jsonData);
  
      var config = {
        method: 'post',
        url: API_BASE_URL + '/auth/report/attendance',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        },
        data: jsonData1
      };
      
      axios(config)
      .then(function (response) {
          console.log(response.data)
          if(response.status == 200) {
            // for(let i=0; i< response.data.length; i++) {
            //   const lock = {
            //     Name: response.data[i].name,
            //     MobileNumber: response.data[i].mobilenumber,
            //     In: response.data[i].in,
            //     Out: response.data[i].out,
            //     InLat: response.data[i].inlat,
            //     InLong: response.data[i].inlong,
            //     OutLat: response.data[i].outlat,
            //     OutLong: response.data[i].outlong,
            //   }
            //   downloadData.push(lock)
            // }
            comp.setState({
              firebaseData: response.data.attendanceList,
              totalRecords: response.data.totalRecords
              // firebaseData1: downloadData
            })
          }
      })
      .catch(function (error) {
        console.log(error);
        comp.handleNon200Response(error, "1");
      });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  agentsOnChange = agentId => {
    this.setState({ agentId });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleChangePage(event, newPage) {
    this.setState({
        page: newPage
    })

    let comp = this
    let jsonData = comp.state.queryParams

    jsonData.pagination = true
    jsonData.pageSize = comp.state.rowsPerPage
    jsonData.pageCount = Number(newPage) + 1

    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/attendance',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        if(response.status == 200) {
          comp.setState({
            firebaseData: response.data.attendanceList,
            totalRecords: response.data.totalRecords
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "98");
    });
}

handleChangeRowsPerPage(event) {
   this.setState({
       rowsPerPage: parseInt(event.target.value, 15),
       page: 0
   })

   let comp = this
   let jsonData = comp.state.queryParams

   jsonData.pagination = true
   jsonData.pageSize = parseInt(event.target.value, 10)
   jsonData.pageCount = Number(0) + 1

   let jsonData1 = JSON.stringify(jsonData);

   var config = {
     method: 'post',
     url: API_BASE_URL + '/auth/report/attendance',
     headers: { 
       apikey: API_KEY, 
       Authorization: "Bearer " + getCookie("at"),
       'Content-Type': 'application/json'
     },
     data: jsonData1
   };
   
   axios(config)
   .then(function (response) {
       if(response.status == 200) {
         comp.setState({
           firebaseData: response.data.attendanceList,
           totalRecords: response.data.totalRecords
         })
       }
   })
   .catch(function (error) {
     console.log(error);
     comp.handleNon200Response(error, "99");
   });
}

  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    if(data.fromDate === '') {
      alert("Select From Date");
      document.getElementById("fromDate").focus();
      return;
    }

    if(data.toDate === '') {
      alert("Select To Date");
      document.getElementById("toDate").focus();
      return;
    }

    var jsonData = {
      startDate: data.fromDate,
      endDate: data.toDate
    };

    comp.setState({
      queryParams: jsonData
    })

    jsonData.pagination = true
    jsonData.pageSize = comp.state.rowsPerPage
    jsonData.pageCount = Number(comp.state.page) + 1

    var jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/attendance',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json',
      },
      data: jsonData1
    };
  
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
          comp.setState({
            firebaseData: response.data.attendanceList,
            totalRecords: response.data.totalRecords
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    // firebase.firestore().collection("Attendance")
    // .orderBy("InTimeDate").startAt(data.fromDate).endAt(data.toDate)
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //       loans.push(doc.data())
    //     });
    //     comp.setState({
    //       firebaseData: loans,
    //     })
    // });
}

// exportToCSV(csvData, fileName) {
//   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//   const fileExtension = '.xlsx';

//   const ws = XLSX.utils.json_to_sheet(csvData);
//   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
//   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//   const data = new Blob([excelBuffer], {type: fileType});
//   FileSaver.saveAs(data, fileName + fileExtension);
// }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Repprt" breadcrumbItem="Attendance Report" />
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                      <Row>
                                            <Col md = "4">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            {/* <Button color="primary" onClick = {this.exportToCSV.bind(this, , "Attendance Report")} type="submit">Excel</Button> */}
                                            </Col>
                                        </Row>
                                        <br />

                                        <Row>
                                              <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">From Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.fromDate} id = "fromDate" name = "fromDate" onChange = {this.handleChange.bind(this)} />
                                                    </FormGroup>
                                                </Col>
           
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">To Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.toDate} id = "toDate" name = "toDate" onChange = {this.handleChange.bind(this)} />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                              <Button color="primary" onClick = {this.handleSubmit.bind(this)} type="submit">Submit</Button>
                                              </Col>
                                              </Row>

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Check In</th>
                                                            <th>Check In Location</th>
                                                            <th>Check Out</th>
                                                            <th>Check Out Location</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this?.state?.firebaseData?.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.mobilenumber}</td>
                                                        <td><p>{rowData.in}</p><img src = {rowData.intimephoto} height = "100" width = "100" /></td>

                                                        <td>
                                                          {rowData.inlat && rowData.inlong ? (
                                                            <iframe
                                                              width="200"
                                                              height="140"
                                                              frameBorder="0"
                                                              scrolling="no"
                                                              marginHeight="0"
                                                              marginWidth="0"
                                                              // src={`https://maps.google.com/maps?q=${encodeURIComponent(rowData.inlat)},${encodeURIComponent(rowData.inlong)}&hl=en&z=14&output=embed`}
                                                              src={`https://maps.google.com/maps?q=${rowData.inlat},${rowData.inlong}&hl=en&z=14&output=embed`}
                                                              // src={`https://maps.google.com/maps?q=37.7749,-122.4194&hl=en&z=14&output=embed`} // dummy fixed location
                                                              title="Check-in Location"
                                                            ></iframe>
                                                          ) : (
                                                            "Check-in location not available"
                                                          )}
                                                        </td>
                                                        {/* {rowData.inlat || rowData.inlong !== "" ?
                                                            <td><iframe width="200" height="140" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src = {`https://maps.google.com/maps?q=${rowData.inlat},${rowData.inlong}&hl=en&z=14&output=embed`}></iframe></td>
                                                        : <td>Attendance Tracking Not available</td> } */}
                                                        <td><p>{rowData.out}</p><img src = {rowData.outtimephoto} height = "100" width = "100" /></td>
                                                        {/* {rowData.outlat || rowData.outlong !== "" ?
                                                            <td><iframe width="200" height="140" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src = {`https://maps.google.com/maps?q=${rowData.outlat},${rowData.outlong}&hl=en&z=14&output=embed`}></iframe></td>
                                                        : <td>Attendance Tracking Not available</td> } */}

                                                        <td>
                                                          {rowData.outlat && rowData.outlong ? (
                                                            <iframe
                                                              width="200"
                                                              height="140"
                                                              frameBorder="0"
                                                              scrolling="no"
                                                              marginHeight="0"
                                                              marginWidth="0"
                                                              // src={`https://maps.google.com/maps?q=37.7749,-122.4194&hl=en&z=14&output=embed`} // dummy fixed location
                                                              src = {`https://maps.google.com/maps?q=${rowData.outlat},${rowData.outlong}&hl=en&z=14&output=embed`}
                                                            ></iframe>
                                                          ) : (
                                                            "Check-out location not available"
                                                          )}
                                                        </td>
                                                     </tr>
                                                ))}                                                
                                                </tbody>
                                            </Table>
                                        </div>
                                            <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.totalRecords}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                            />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default AttendanceReport;
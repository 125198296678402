import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table, Row, Col, Card, CardBody } from "reactstrap";
import Button from "reactstrap/lib/Button";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'

class AgentsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firebaseData: [],
            profile: false
        };

    }

    componentDidMount() {
        let comp = this
        firebase.firestore().collection("Agents")
        .where("ApprovalStatus", "==", "Approved")
        .onSnapshot(function(querySnapshot) {
            var ags = [];
            querySnapshot.forEach(function(doc) {
                ags.push(doc.data())

                console.log(doc.data())
            });
            comp.setState({
              firebaseData: ags,
            })
        });
    }

    approveShortVideo(key) {
    if (window.confirm('Are you sure you want to approve the agent?')) {
        firebase.firestore().collection("Agents").doc(key.UserId).update({
            ApprovalStatus: "Approved"
        })
        .then(function(ref) {
            alert("Approved Successfully!!")
        })
    } else {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Agents" breadcrumbItem="Agents" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>User Id</th>
                                                            <th>Name</th>
                                                            <th>Number</th>
                                                            <th>Email</th>
                                                            <th>ID Proof</th>
                                                            <th>Address Proof</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.UserId}</td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.Number}</td>
                                                        <td>{rowData.Email}</td>
                                                        <td>{rowData.IdProof}</td>
                                                        <td>{rowData.AddressProof}</td>
                                                        </tr>
                                                    ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AgentsList;

import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import Modal from "reactstrap/lib/Modal";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TablePagination } from '@material-ui/core';
import Loader from "react-loader-spinner";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

function getLastWeek() {
    var today = new Date();
    var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
  }


class AgentPendingReport extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFilterAgentWise = this.handleFilterAgentWise.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.funcMap = {
        '1': this.fetchList,
        '2': this.handleFilter,
        '3': this.handleFilterAgentWise,
        '98': this.handleChangePage,
        '99': this.handleChangeRowsPerPage
    };
    this.state = {
      firebaseData: [],
      firebaseData1: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      agents: [],
      agentId: [],
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      banks: [],
      products: [],
      bank: '',
      product: '',
      moveToView: false,
      startDate: '',
      endDate: '',
      openModal: false,
      applicationId: '',
      customerName: '',
      mobileNumber: '',
      productName: '' ,
      rvAddress: '',
      bvAddress: '',

      pages: [30, 50, 100, 300],
      page: 0,
      rowsPerPage: 30,
      lastVisible: {},
      count: 0,

      load: false,
      agents: [],
      agent: 'Select',
      queryParams: {},
      totalRecords: 0
    };
  }

  componentDidMount() {
    let comp = this

    comp.setState({
        load: true
    })

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/agents',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
    //   data: jsonData
    };

    let agents = []
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
          for(let i=0; i< response.data.length; i++) {
            if(response.data[i].agentid !== undefined && response.data[i].agentid !== "") {
              agents.push(response.data[i])
            }
          }
          comp.setState({
            agents: agents,
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    // firebase.firestore().collection("Agents").orderBy("Name")
    // .onSnapshot(function(querySnapshot) {
    //     var agents = [];
    //     querySnapshot.forEach(function(doc) {
    //       if(doc.data().AgentId !== undefined) {
    //         agents.push(doc.data())
    //       }
    //     });
    //     comp.setState({
    //       agents: agents
    //     })
    // });

    comp.fetchList()
  }

  async fetchList() {
    let comp = this

    var today = new Date()
    var currentDate = formatDate(today)  
    var lastWeek = getLastWeek();
    var endDate = formatDate(lastWeek)


    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    var agentName = []
    var agentId = []
    var agentNumber = []
    var agUserId = []

    var casePriority = []
    var caseAgent = []
    var caseStatus = []
    var caseAgId = []

    let newPendingcase = []
    let newVerifiedCase = []
    let hpCase = []

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/approvedAgents',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        for(let i=0; i< response.data.length; i++) {
          agentName.push(response.data[i].name)
          agentId.push(response.data[i].agentid)
          agUserId.push(response.data[i]._id)
          agentNumber.push(response.data[i].number)
        }

        newPendingcase = Array(agUserId.length).fill(0)
        newVerifiedCase = Array(agUserId.length).fill(0)
        hpCase = Array(agUserId.length).fill(0)

        let jsonData1 = JSON.stringify({
          startDate: endDate,
          endDate: currentDate
        })

        var config1 = {
          method: 'post',
          url: API_BASE_URL + '/auth/filterCase',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data : jsonData1
        };
        
        axios(config1)
        .then(function (response1) {
          console.log(response1.data)
          for(let i=0; i< response1.data.length; i++) {
            caseStatus.push(response1.data[i].status)
            caseAgent.push(response1.data[i].agentid)
            casePriority.push(response1.data[i].hp)
            caseAgId.push(response1.data[i].agent)
          }
        })
        .catch(function (error1) {
          console.log(error1);
          // comp.handleNon200Response(error1, "1");
        });

        setTimeout(function() {
          console.log("Agent User Id", agUserId)
          console.log("Case Status", caseStatus)
          console.log("Case Agent id", caseAgId)
          console.log("Agent Name", agentName)
  
  
      for (let i=0; i < agUserId.length; i++) {
          for (let j=0; j < caseAgId.length; j++) {
  
              if(agUserId[i] === caseAgId[j]) {
                  if(caseStatus[j] === "Pending") {
                      newPendingcase[i] = newPendingcase[i] + 1
                  }
                  if(caseStatus[j] === "Completed") {
                      newVerifiedCase[i] = newVerifiedCase[i] + 1
                  }
  
                  if(casePriority[j] === "YES") {
                      hpCase[i] = hpCase[i] + 1
                  }
              }
          }
      }
  
      console.log("Pending Case", newPendingcase)
      console.log("Verified Case", newVerifiedCase)
      console.log("HP Case", hpCase)
  
      let newData = []
  
      for(let i = 0; i< agUserId.length; i++) {
          const lock = {
              // UserId: agUserId[i],
              AgentId: agentId[i],
              Name: agentName[i],
              Number: agentNumber[i],
              PendingCases: newPendingcase[i],
              VerifiedCase: newVerifiedCase[i],
              HPCase: hpCase[i]
          }
          newData.push(lock)
      }
  
      comp.setState({
          firebaseData: newData,
          load: false,
          startDate: endDate,
          endDate: currentDate
      })
  }, 5000);


    // firebase.firestore().collection("Agents")
    // .where("ApprovalStatus", "==", "Approved")
    // .get().then((querySnapshot) => {
    //     querySnapshot.forEach(function(snap) {

    //     })




    
    // firebase.firestore().collection("Case")
    // .get().then((querySnapshot) => {
    //     querySnapshot.forEach(function(snap) {
    //         if(typeof snap.data().CreatedAt == "string") {
    //             if((formatDate(snap.data().CreatedAt) >= endDate) && (formatDate(snap.data().CreatedAt) <= currentDate)) {
    //                 caseStatus.push(snap.data().Status)
    //                 caseAgent.push(snap.data().AgentId)
    //                 casePriority.push(snap.data().HP)
    //                 caseAgId.push(snap.data().Agent)
    //             }
    //         }
    //     });
    // })

  
  })
  .catch(function (error) {
    console.log(error);
    // comp.handleNon200Response(error, "1");
  });

}

handleNon200Response = (error, functionIndex) => {
  if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
    sessionExpired();
  } 
}
  


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }


handleFilter(e) {
  e.preventDefault();

  let data = this.state
  let comp = this

  var state = localStorage.getItem("state")
  if(state === null) {
      state = sessionStorage.getItem("state")
  }

  if(data.startDate === '') {
    alert("Select Start date");
    document.getElementById("startDate").focus();
    return;
  }

  if(data.endDate === '') {
    alert("Select End Date");
    document.getElementById("endDate").focus();
    return;
  }

  comp.setState({
      load: true
  })

  var agentName = []
  var agentId = []
  var agentNumber = []
  var agUserId = []

  var casePriority = []
  var caseAgent = []
  var caseStatus = []
  var caseAgId = []

  let newPendingcase = []
  let newVerifiedCase = []
  let hpCase = []

  var config = {
    method: 'get',
    url: API_BASE_URL + '/auth/approvedAgents',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
    }
  };
  
  axios(config)
  .then(function (response) {
      console.log(response.data)
      for(let i=0; i< response.data.length; i++) {
        agentName.push(response.data[i].name)
        agentId.push(response.data[i].agentid)
        agUserId.push(response.data[i]._id)
        agentNumber.push(response.data[i].number)
      }

      newPendingcase = Array(agUserId.length).fill(0)
      newVerifiedCase = Array(agUserId.length).fill(0)
      hpCase = Array(agUserId.length).fill(0)

      let jsonData1 = JSON.stringify({
        startDate: data.startDate,
        endDate: data.endDate
      })

      var config1 = {
        method: 'post',
        url: API_BASE_URL + '/auth/filterCase',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        },
        data : jsonData1
      };
      
      axios(config1)
      .then(function (response1) {
        console.log(response1.data)
        for(let i=0; i< response1.data.length; i++) {
          caseStatus.push(response1.data[i].status)
          caseAgent.push(response1.data[i].agentid)
          casePriority.push(response1.data[i].hp)
          caseAgId.push(response1.data[i].agent)
        }
      })
      .catch(function (error1) {
        console.log(error1);
        comp.handleNon200Response(error1, "1");
      });

      setTimeout(function() {
        console.log("Agent User Id", agUserId)
        console.log("Case Status", caseStatus)
        console.log("Case Agent id", caseAgId)
        console.log("Agent Name", agentName)


    for (let i=0; i < agUserId.length; i++) {
        for (let j=0; j < caseAgId.length; j++) {

            if(agUserId[i] === caseAgId[j]) {
                if(caseStatus[j] === "Pending") {
                    newPendingcase[i] = newPendingcase[i] + 1
                }
                if(caseStatus[j] === "Completed") {
                    newVerifiedCase[i] = newVerifiedCase[i] + 1
                }

                if(casePriority[j] === "YES") {
                    hpCase[i] = hpCase[i] + 1
                }
            }
        }
    }

    console.log("Pending Case", newPendingcase)
    console.log("Verified Case", newVerifiedCase)
    console.log("HP Case", hpCase)

    let newData = []

    for(let i = 0; i< agUserId.length; i++) {
        const lock = {
            UserId: agUserId[i],
            AgentId: agentId[i],
            Name: agentName[i],
            Number: agentNumber[i],
            PendingCases: newPendingcase[i],
            VerifiedCase: newVerifiedCase[i],
            HPCase: hpCase[i]
        }
        newData.push(lock)
    }

    comp.setState({
        firebaseData: newData,
        load: false,
    })
  }, 5000);
  })
  .catch(function (error) {
    console.log(error);
    // comp.handleNon200Response(error, "2");
  });
}

handleFilterAgentWise(e) {
  e.preventDefault();

  let data = this.state
  let comp = this

  var state = localStorage.getItem("state")
  if(state === null) {
      state = sessionStorage.getItem("state")
  }

  if(data.agent=== 'Select') {
    alert("Select Agent");
    document.getElementById("agent").focus();
    return;
  }


  comp.setState({
      load: true
  })

  var agentName = []
  var agentId = []
  var agentNumber = []
  var agUserId = []

  var casePriority = []
  var caseAgent = []
  var caseStatus = []
  var caseAgId = []

  let newPendingcase = []
  let newVerifiedCase = []
  let hpCase = []

  console.log(data.agent)


  var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/agents/' + data.agent,
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
  
  axios(config)
  .then(function (response) {
    if(response.status ==  200) {
      agentName.push(response.data.name)
      agentId.push(response.data.agentid)
      agUserId.push(response.data._id)
      agentNumber.push(response.data.number)

      newPendingcase = Array(agUserId.length).fill(0)
      newVerifiedCase = Array(agUserId.length).fill(0)
      hpCase = Array(agUserId.length).fill(0)

      var today = new Date()
      var currentDate = formatDate(today)  
      var lastWeek = getLastWeek();
      var endDate = formatDate(lastWeek)
    
      if(data.startDate !== '') {
        endDate = data.startDate
      }
    
      if(data.endDate !== '') {
        currentDate = data.endDate
      }

      let jsonData1 = JSON.stringify({
        startDate: endDate,
        endDate: currentDate
      })

      var config1 = {
        method: 'post',
        url: API_BASE_URL + '/auth/filterCase',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        },
        data : jsonData1
      };
      
      axios(config1)
      .then(function (response1) {
        console.log(response1.data)
        for(let i=0; i< response1.data.length; i++) {
          caseStatus.push(response1.data[i].status)
          caseAgent.push(response1.data[i].agentid)
          casePriority.push(response1.data[i].hp)
          caseAgId.push(response1.data[i].agent)
        }
      })
      .catch(function (error1) {
        console.log(error1);
        comp.handleNon200Response(error1, "1");
      });

      setTimeout(function() {
        console.log("Agent User Id", agUserId)
        console.log("Case Status", caseStatus)
        console.log("Case Agent id", caseAgId)
        console.log("Agent Name", agentName)


    for (let i=0; i < agUserId.length; i++) {
        for (let j=0; j < caseAgId.length; j++) {

            if(agUserId[i] === caseAgId[j]) {
                if(caseStatus[j] === "Pending") {
                    newPendingcase[i] = newPendingcase[i] + 1
                }
                if(caseStatus[j] === "Completed") {
                    newVerifiedCase[i] = newVerifiedCase[i] + 1
                }

                if(casePriority[j] === "YES") {
                    hpCase[i] = hpCase[i] + 1
                }
            }
        }
    }

    console.log("Pending Case", newPendingcase)
    console.log("Verified Case", newVerifiedCase)
    console.log("HP Case", hpCase)

    let newData = []

    for(let i = 0; i< agUserId.length; i++) {
        const lock = {
            UserId: agUserId[i],
            AgentId: agentId[i],
            Name: agentName[i],
            Number: agentNumber[i],
            PendingCases: newPendingcase[i],
            VerifiedCase: newVerifiedCase[i],
            HPCase: hpCase[i]
        }
        newData.push(lock)
    }

    comp.setState({
        firebaseData: newData,
        load: false,
    })
  }, 5000);
  }
})
  .catch(function (error) {
    console.log(error);
    // comp.handleNon200Response(error, "3");
  });
}


  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];

    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  exportToCSV(csvData, fileName) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
  
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Report" breadcrumbItem="Agent Pending Report" />
            {this.state.moveToView ? <Redirect to = "view-case" /> : null}
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                      <Row>
                                            <Col md = "3">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData, "Agent Pending Report")} type="submit">Excel</Button>
                                            </Col>
                                        </Row>
                                        <br />

                                          <Row>
                                            {console.log(this.state.agents)}
                                                <Col md = {{size: 3}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom05">Agent<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.agent} id = "agent" name = "agent" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Agent</option>
                                                          {this.state.agents.map((rowData, index) => (
                                                            <option value = {rowData._id}>{rowData.agentid + "-" + rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                                  <Button color="primary" onClick = {this.handleFilterAgentWise.bind(this)} type="submit">Filter</Button>
                                                </Col>
                                              </Row>
                                              <br />

                                        <Row>
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.startDate} id = "startDate" name = "startDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.endDate} id = "endDate" name = "endDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                                  <Button color="primary" onClick = {this.handleFilter.bind(this)} type="submit">Filter</Button>
                                                </Col>
                                              </Row>
                                              <br />

                                        {this.state.load ? 
                                          <Loader 
                                            type="Circles" 
                                            color="#4058b0" 
                                            height={130} 
                                            width={130}
                                            timeout={10000}
                                            style = {{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 2 }}
                                          /> : null }

                                        <h4>Date Range: {this.state.startDate} - {this.state.endDate}</h4>
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            {/* <th>User Id</th> */}
                                                            <th>Agent Id</th>
                                                            <th>Agent Name</th>
                                                            <th>Number</th>
                                                            <th>Pending Cases</th>
                                                            <th>Verified Cases</th>
                                                            <th>High Priority Cases</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        {/* <td>{rowData.UserId}</td> */}
                                                        <td>{rowData.AgentId}</td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.Number}</td>
                                                        <td>{rowData.PendingCases}</td>
                                                        <td>{rowData.VerifiedCase}</td>
                                                        <td>{rowData.HPCase}</td>
                                                     </tr>
                                                ))}                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.firebaseData.length}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default AgentPendingReport;
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table, Row, Col, Card, CardBody, FormGroup, Label, Input } from "reactstrap";
import Button from "reactstrap/lib/Button";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TablePagination } from '@material-ui/core';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('/');
}

class AgentReports extends Component {
    constructor(props) {
        super(props);
        this.fetchList = this.fetchList.bind(this);
        this.clearIMEI = this.clearIMEI.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.funcMap = {
            '1': this.fetchList,
            '2': this.clearIMEI,
            '98': this.handleChangePage,
            '99': this.handleChangeRowsPerPage
        };
        this.state = {
            firebaseData: [],
            firebaseData1: [],
            profile: false,
            startDate: '',
            endDate: '',

            pages: [30, 50, 100, 300],
            page: 0,
            rowsPerPage: 30,
            lastVisible: {},
            count: 0,
            queryParams: {},
            totalRecords: 0
        };

    }

    componentDidMount() {
        let comp = this


        var today = new Date()
        var currentDate = formatDate(today)

        comp.fetchList()


        // firebase.firestore().collection("Agents")
        // .orderBy("UserId")
        // .limit(this.state.rowsPerPage)
        // // .where("JoiningDate", "==", currentDate)
        // .get().then((querySnapshot) => {
        //     var ags = [];

        //     var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
        //     console.log("last", lastVisible);

        //     querySnapshot.forEach(function(doc) {
        //         ags.push(doc.data())
        //     });
        //     comp.setState({
        //       firebaseData: ags,
        //       lastVisible: lastVisible
        //     })
        // });

        // firebase.firestore().collection("Agents")
        // .orderBy("UserId")
        // .get().then((querySnapshot) => {
        //     var loans = [];
        //     querySnapshot.forEach(function(doc) {
        //           const loan = {
        //             UserId: doc.data().UserId,
        //             AgentId: doc.data().AgentId,
        //             Name: doc.data().Name,
        //             Number: doc.data().Number,
        //             Email: doc.data().Email,
        //             JoiningDate: doc.data().JoiningDate,
        //             IdProof: doc.data().IdProof,
        //             AddressProof: doc.data().AddressProof,
        //             Password: doc.data().Password,
        //             ApprovalStatus:doc.data().ApprovalStatus,
        //             IMEI: doc.data().IMEI
        //           }
        //           loans.push(loan)
        //     });
        //     comp.setState({
        //       firebaseData1: loans
        //     })
        // });
    }

    async fetchList() {
        let comp = this

        let jsonData = {}

        comp.setState({
          queryParams: jsonData
        })
    
        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(comp.state.page) + 1

        let jsonData1 = JSON.stringify(jsonData);
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/agents/pagination',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData1
        };
        
        axios(config)
        .then(function (response) {
            console.log(response.data)
            let downloadData = []
            if(response.status == 200) {
              // for(let i=0; i< response.data.length; i++) {
              //   // console.log("_id", response.data[i]._id)
              //   const loan = {
              //       UserId: response.data[i].userid,
              //       AgentId: response.data[i].agentid,
              //       Name: response.data[i].name,
              //       Number: response.data[i].number,
              //       Email: response.data[i].email,
              //       JoiningDate: response.data[i].joiningdate,
              //       IdProof: response.data[i].idproof,
              //       AddressProof: response.data[i].addressproof,
              //       ApprovalStatus:response.data[i].approvalSstatus,
              //       IMEI: response.data[i].imei
              //   }
              //   downloadData.push(loan)

              // var config = {
              //   method: 'delete',
              //   url:  API_BASE_URL + '/auth/agents/' + response.data[i]._id,
              //   headers: { 
              //     'apikey': API_KEY,
              //     'Authorization': 'Bearer ' + getCookie('at')
              //   },
              // };
            
              // axios(config)
              // .then(function (responseA) {
              //   if(responseA.status == 200) {
              //       // comp.fetchList()
              //       // alert("Deleted Successfully")
              //   }
              // })
            // }

              comp.setState({
                firebaseData: response.data.agentsList,
                totalRecords: response.data.totalRecords
              })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "1");
        });
      }

      handleChangePage(event, newPage) {
        this.setState({
          page: newPage
        })

        let comp = this
        let jsonData = comp.state.queryParams
    
        jsonData.pagination = true
        jsonData.pageSize = comp.state.rowsPerPage
        jsonData.pageCount = Number(newPage) + 1
    
        let jsonData1 = JSON.stringify(jsonData);
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/agents/pagination',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData1
        };
        
        axios(config)
        .then(function (response) {
          console.log("Response", response.data)
            if(response.status == 200) {
              comp.setState({
                firebaseData: response.data.agentsList,
                totalRecords: response.data.totalRecords
              })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "98");
        });
      }
      
      handleChangeRowsPerPage(event) {
        this.setState({
          rowsPerPage: parseInt(event.target.value, 10),
          page: 0
        })

        let comp = this
        let jsonData = comp.state.queryParams
    
        jsonData.pagination = true
        jsonData.pageSize = parseInt(event.target.value, 10)
        jsonData.pageCount = Number(0) + 1
    
        let jsonData1 = JSON.stringify(jsonData);
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/agents/pagination',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
          data: jsonData1
        };
        
        axios(config)
        .then(function (response) {
            if(response.status == 200) {
              comp.setState({
                firebaseData: response.data.agentsList,
                totalRecords: response.data.totalRecords
              })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "99");
        });
      }

    clearIMEI(key) {
        let comp = this
        if (window.confirm('Are you sure you want to clear IMEI for the agent?')) {

            var jsonData = JSON.stringify({
                "imei": "",
              })
          
              var config = {
                method: "put",
                url: API_BASE_URL + "/auth/agents/" + key._id,
                headers: {
                  apikey: API_KEY,
                  Authorization: "Bearer " + getCookie("at"),
                  "Content-Type": "application/json",
                },
                data: jsonData,
              }
          
              axios(config)
                .then(function (response) {
                  if (response.status == 200) {
                    comp.fetchList()
                    alert("IMEI cleared Successfully!!")
                  }
                })
                .catch(function (error) {
                  console.log(error)
                  comp.handleNon200Response(error, "2")
                })
        } else {}
    }

    // handleSubmit(e) {
    //     e.preventDefault();
    
    //     let data = this.state
    
    //     let comp = this
    
    //     if(data.startDate === '') {
    //       alert("Select Start Date");
    //       document.getElementById("startDate").focus();
    //       return;
    //     }
    
    //     if(data.endDate === '') {
    //         alert("Select End Date");
    //         document.getElementById("endDate").focus();
    //         return;
    //     }

    //     var jsonData = JSON.stringify({
    //         state: state.split(",").slice(0, -1),
    //         startDate: data.startDate,
    //         endDate: data.endDate
    //       });
        
    //       var config = {
    //         method: 'post',
    //         url: API_BASE_URL + '/auth/report/agents',
    //         headers: { 
    //           apikey: API_KEY, 
    //           Authorization: "Bearer " + getCookie("at"),
    //           'Content-Type': 'application/json',
    //         },
    //         data: jsonData
    //       };
          
    //       axios(config)
    //       .then(function (response) {
    //           console.log(response.data)
    //           if(response.status == 200) {
    //                 comp.setState({
    //                   firebaseData: response.data,
    //               })
    //           }
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         comp.handleNon200Response(error, "3");
    //       });
    // }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
      }

      myFunction() {
        var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
        var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
        input = document.getElementById("search");
        filter = input.value.toUpperCase();
        table = document.getElementById("dataTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
        td1 = tr[i].getElementsByTagName("td")[1];
        td2 = tr[i].getElementsByTagName("td")[2];
        td3 = tr[i].getElementsByTagName("td")[3];
        td4 = tr[i].getElementsByTagName("td")[4];
        td5 = tr[i].getElementsByTagName("td")[5];
        td6 = tr[i].getElementsByTagName("td")[6];
        td7 = tr[i].getElementsByTagName("td")[7];
        td8 = tr[i].getElementsByTagName("td")[8];
        if (td1) {
          txtValue1 = td1.textContent || td1.innerText;
          txtValue2 = td2.textContent || td2.innerText;
          txtValue3 = td3.textContent || td3.innerText;
          txtValue4 = td4.textContent || td4.innerText;
          txtValue5 = td5.textContent || td5.innerText;
          txtValue6 = td6.textContent || td6.innerText;
          txtValue7 = td7.textContent || td7.innerText;
          txtValue8 = td8.textContent || td8.innerText;
        
        var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7+ txtValue8;
          if (main.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
      }


  exportToCSV(fileName) {
    let comp = this
    var state = localStorage.getItem("state")
      if(state === null) {
          state = sessionStorage.getItem("state")
      }

    var jsonData = {
      state: state.split(",").slice(0, -1)
    };

    let bank = localStorage.getItem("bank")
    if(bank === null) {
        bank = sessionStorage.getItem("bank")
    }

    if(!bank.includes("SUPER ADMIN")) {
      jsonData.bank = bank.split(",").slice(0, -1)
    }

    let jsonData1 = JSON.stringify(jsonData);
    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/agents/pagination',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        let downloadData = []
        if(response.status == 200) {
          for(let i=0; i< response.data.length; i++) {
                const loan = {
                    UserId: response.data[i].userid,
                    AgentId: response.data[i].agentid,
                    Name: response.data[i].name,
                    Number: response.data[i].number,
                    Email: response.data[i].email,
                    Password: response.data[i].password,
                    JoiningDate: response.data[i].joiningdate,
                    IdProof: response.data[i].idproof,
                    AddressProof: response.data[i].addressproof,
                    ApprovalStatus:response.data[i].approvalSstatus,
                    IMEI: response.data[i].imei
                }
                downloadData.push(loan)
          }
        }
  
          const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          const fileExtension = '.xlsx';
          const ws = XLSX.utils.json_to_sheet(downloadData);
          const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const data = new Blob([excelBuffer], {type: fileType});
          FileSaver.saveAs(data, fileName + fileExtension);
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });
  }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Reports" breadcrumbItem="Agent Reports" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                    <Row>
                                            <Col md = "3">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            <Button color="primary" onClick = {this.exportToCSV.bind(this, "Agent Report")} type="submit">Excel</Button>
                                            </Col>
                                        </Row>
                                        <br />
                                            {/* <Row>
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.startDate} id = "startDate" name = "startDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.endDate} id = "endDate" name = "endDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                              </Row>
                                              <Button color="primary" onClick = {this.handleSubmit.bind(this)} type="submit">Submit</Button>


                                        <br /> */}
                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>User Id</th>
                                                            <th>Agent Id</th>
                                                            <th>Name</th>
                                                            <th>Number</th>
                                                            <th>Email</th>
                                                            <th>Password</th>
                                                            <th>Joining Date</th>
                                                            <th>ID Proof</th>
                                                            <th>Address Proof</th>
                                                            <th>Status</th>
                                                            <th>Clear IMEI</th>
                                                    </tr>
                                                </thead>
                                                <tbody id = "dataTable">
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.userid}</td>
                                                        <td>{rowData.agentid}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.number}</td>
                                                        <td>{rowData.email}</td>
                                                        <td>{rowData.password}</td>
                                                        <td>{rowData.joiningdate !== undefined ? rowData.joiningdate.slice(0, 10) : ""}</td>
                                                        <td>{rowData.idproof}</td>
                                                        <td><a href = {rowData.addressproofimage} target = "_blank">{rowData.addressproof}</a></td>
                                                        <td>{rowData.approvalstatus}</td>
                                                        <td><Button type = "submit" color = "danger" onClick = {this.clearIMEI.bind(this, rowData)}>Clear IMEI</Button></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.totalRecords}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AgentReports;

import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import CardTitle from "reactstrap/lib/CardTitle";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";


class PushNotifications extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.agentsOnChage = this.agentsOnChage.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.agentsOnChage,
    };
    this.state = {
      firestoreData: [],
      bank: '',
      localPrice: '',
      outstation1Price: '',
      outstation2Price: '',
      agentLocalPrice: '',
      agentOutstation1Price: '',
      agentOutstation2Price: '',
      menu: 'Select',
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      pushid: '',
      banks: [],
      agents: [],

      agentType: 'Select',
      title: '',
      message: '',
      agent: '',
      agentToken: ''
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  async fetchList() {
    let comp = this

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/approvedAgents',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
      comp.setState({
        agents: response.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  agentsOnChage(e) {
    let comp = this
    const value = e.target.value
    var config = {
      method: "get",
      url: API_BASE_URL + "/auth/agents/" + value,
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
      },
    }

    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data)
          comp.setState({
            agent: value,
            agentToken: response.data.messagingtoken
          })
        }
      })
      .catch(function (error) {
        console.log(error)
        comp.handleNon200Response(error, "2")
      })
  //   firebase.firestore().collection("Agents").doc(value)
  //   .onSnapshot(function(doc) {
  //     comp.setState({
  //       agent: value,
  //       agentToken: doc.data().MessagingToken
  //     })
  // });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    if(data.agentType === 'Select') {
        alert("Select Type");
        document.getElementById("agentType").focus();
        return;
    }

    if(data.agentType === "Single") {
      if(data.agent === '') {
        alert("Select Agent");
        document.getElementById("agent").focus();
        return;
      }
    }

    if(data.title === '') {
        alert("Enter Title");
        document.getElementById("title").focus();
        return;
    }

    if(data.message === '') {
      alert("Enter Message");
      document.getElementById("message").focus();
      return;
    }

    if(data.agentType === "Single"){

      var bodyData = {
        notification:{
          title: data.title,
          body:data.message,
          icon:""
        },
        to: data.agentToken
      };
      
      console.log(bodyData)
      const json = JSON.stringify(bodyData)
      console.log(json)
  
      var config = {
        method: 'post',
        url: 'https://fcm.googleapis.com/fcm/send',
        headers: { 
          'Authorization': 'key=AAAA2zJhnR4:APA91bG19TbmwGVluSqEodcXtm9nG8s4-t_Me-j0ybzKADWvjiarc9SScttFSigFr4fqNYD_xdrOCmfKhSbOxDgxZ8undDihYwYtLG0gPBizpdgpsHpXIbOP4YTEAV2KEPXomz2afOJw', 
          'Content-Type': 'application/json'
        },
        data : json
      }
      axios(config)
      .then((response) => {
          console.log(response)

          if(response.status === 200) {
            alert("Notification sent successfully")
          }
      })
      .catch((error) => {
        console.log(error.response)
      })

      comp.setState({
        agentType: "Select",
        agentToken: '',
        title:'',
        message: ''
      })
    }

    if(data.agentType === "Group"){

      var bodyData = {
        notification:{
          title: data.title,
          body:data.message,
          icon:""
        },
        to: "/topics/Agents"
      };
  
      console.log(bodyData)
      const json = JSON.stringify(bodyData)
      console.log(json)
  
      var config = {
        method: 'post',
        url: 'https://fcm.googleapis.com/fcm/send',
        headers: { 
          'Authorization': 'key=AAAA2zJhnR4:APA91bG19TbmwGVluSqEodcXtm9nG8s4-t_Me-j0ybzKADWvjiarc9SScttFSigFr4fqNYD_xdrOCmfKhSbOxDgxZ8undDihYwYtLG0gPBizpdgpsHpXIbOP4YTEAV2KEPXomz2afOJw', 
          'Content-Type': 'application/json'
        },
        data : json
      }
      axios(config)
      .then((response) => {
          console.log(response)

          if(response.status === 200) {
            alert("Notification sent successfully")
          }
      })
      .catch((error) => {
          console.log(error.response)
      })

      comp.setState({
        agentType: "Select",
        agentToken: '',
        title:'',
        message: ''
      })
    }

    document.getElementById("create-form").reset()
} 


  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Push Notifications" />
                <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                                <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Type<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.agentType} id = "agentType" name = "agentType" onChange = {this.handleChange.bind(this)}>
                                                            <option value = "Select">Select Type</option>
                                                            <option value = "Group">Group</option>
                                                            <option value = "Single">Single</option>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            {this.state.agentType === "Single" ?
                                            <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Agents<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.agent} id = "agent" name = "agent" onChange = {this.agentsOnChage.bind(this)}>
                                                        <option value = "">Select Agent</option>
                                                        {this.state.agents.map((rowData, index) => (
                                                            <option value = {rowData._id}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null }

                                            <Row>
                                            <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Title</Label>
                                                        <Input
                                                          name="title"
                                                          placeholder="Title"
                                                          type="text"
                                                          className="form-control"
                                                          id="title"
                                                          value = {this.state.title}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                            <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Message</Label>
                                                        <Input
                                                          name="message"
                                                          placeholder="Message..."
                                                          type="text"
                                                          className="form-control"
                                                          id="message"
                                                          value = {this.state.message}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>                                                 
                                          </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                    </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default PushNotifications;
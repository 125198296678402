import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";


class Bank extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.submit = this.handleSubmit.bind(this);
    this.update = this.updateRow.bind(this);
    this.delete = this.delete.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.submit,
        '3': this.update,
        '4': this.delete
    };
    this.state = {
      bank: '',
      menu: '',
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      pushid: '',
      dataId: ''
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  async fetchList() {
    let comp = this

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
      comp.setState({
        firebaseData: response.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });
  }

  handleNon200Response = (error, functionIndex) => {
    let comp = this
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
    // if (error.response.status == 403) {
    //   const result = renewAccessToken();
    //   result.then(function (res) {
    //     if (res.status == 200) {
    //       setCookie('at', res.data.accessToken).then(function (value) {
    //         if (value) {
    //           const method = comp.funcMap[functionIndex];
    //           if (typeof method === 'function') {
    //               method();
    //           }
    //         }
    //       });
    //     }
    //   });
    //   result.catch(function (error) {
    //     console.log(error);
    //     handleRenewAccessTokenError(error)
    //   });
    // }
  }

  defaultState() {
    this.setState({
      bank: '',
      menu: '',
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    if(data.bank === '') {
        alert("Enter Bank Name");
        document.getElementById("bank").focus();
        return;
    }

    if(data.menu === '') {
        alert("Select Status");
        document.getElementById("menu").focus();
        return;
    }

    var jsonData = JSON.stringify({
      "name": data.bank,
      "status": data.menu,
      "pushid": Date.now()
    });

    var config = {
      method: 'post',
      url: API_BASE_URL + "/auth/bank",
      headers: { 
        'apikey': API_KEY, 
        'Authorization': 'Bearer ' + getCookie('at'),
        'Content-Type': 'application/json'
      },
      data : jsonData
    };

    axios(config)
    .then(function (response) {
      if(response.status == 201) {
        comp.defaultState()
        comp.fetchList()
        alert("Added Successfully")
      }
    })
    .catch(function (error) {
      console.log(error.response.data)
      comp.handleNon200Response(error, "2")
    });
} 

updateRow(e) {
  e.preventDefault();

    let data = this.state
    let comp = this

    if(data.bank === '') {
        alert("Enter Bank Name");
        document.getElementById("bank").focus();
        return;
    }

    if(data.menu === '') {
        alert("Select Status");
        document.getElementById("menu").focus();
        return;
    }

    var jsonData = JSON.stringify({
      "name": data.bank,
      "status": data.menu,
    })

    var config = {
      method: "put",
      url: API_BASE_URL + "/auth/bank/" + data.dataId,
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        "Content-Type": "application/json",
      },
      data: jsonData,
    }

    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          comp.defaultState()
          comp.fetchList()
          alert("Updated Successfully")
        }
      })
      .catch(function (error) {
        console.log(error)
        comp.handleNon200Response(error, "3")
      })

    // firebase.firestore().collection('Bank').doc(data.pushid).update({
    //     Name: data.bank,
    //     Status: data.menu,
    //     PushId: data.pushid
    // })
    // .then(function(docRef) {
    //     comp.setState({
    //       update_msg: true
    //     })
    //     data.bank = ""
    //     data.menu = "Select"
    //     document.getElementById("create-form").reset()
    // })

    // data.bank = ""
    // data.menu = "Select"

  document.getElementById("submit").style.display = "block"
  document.getElementById("update").style.display = "none"
}

editRow(key) {
  let data = this.state
  document.getElementById("submit").style.display = "none"
  document.getElementById("update").style.display = "block"

  this.setState({
    pushid: key.pushid,
    bank: key.name,
    menu: key.status,
    dataId: key._id
  })

  window.scrollTo('top', 0)
}

delete(key) {
  // if (window.confirm('Are you sure you want to  delete the bank?')) {
  //     firebase.firestore().collection('Bank').doc(key.PushId).delete()
  //     alert("Successfully Deleted!")
  //   } else {}

  let comp = this;

  if(window.confirm('Are you sure want to delete?')) {
    var config = {
      method: 'delete',
      url:  API_BASE_URL + '/auth/bank/' + key._id,
      headers: { 
        'apikey': API_KEY,
        'Authorization': 'Bearer ' + getCookie('at')
      },
    };
  
    axios(config)
    .then(function (response) {
      if(response.status == 200) {
          comp.fetchList()
          alert("Deleted Successfully")
      }
    })
    .catch(function (error) {
      console.log(error)
      comp.handleNon200Response(error, "4");
    });
  }

}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Bank" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Bank Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="bank"
                                                          placeholder="Bank"
                                                          type="text"
                                                          className="form-control"
                                                          id="bank"
                                                          value = {this.state.bank}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Status<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" value = {this.state.menu} id = "menu" name = "menu" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Status</option>
                                                        <option value = "Active">Active</option>
                                                        <option value = "InActive">InActive</option>
                                                    
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                                <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                    Bank Successfully Created!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                  <SweetAlert
                                                    title="Great"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ update_msg: false })}
                                                  >
                                                    Bank Updated Successfully!
                                                  </SweetAlert>
                                                ) : null}     
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                                          <Col>
                                          <Card>
                                            <CardBody>
                                              

                                          <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Bank</th>
                                                            <th>Status</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.status}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Bank;
import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import Modal from "reactstrap/lib/Modal";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TablePagination } from '@material-ui/core';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

const customStyles1 = {
  control: base => ({
    ...base,
    height: 53,
    minHeight: 35,
    backgroundColor: "white",
    overflow: "hidden",
    width: 250
  })
}


class PendingHighPriorityCase extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleAssignAgent = this.handleAssignAgent.bind(this);
    this.getCaseDetails = this.getCaseDetails.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.funcMap = {
        '1': this.fetchList,
        '2': this.handleSubmit,
        '3': this.handleFilter,
        '4': this.handleAssignAgent,
        '5': this.getCaseDetails,
        '98': this.handleChangePage,
        '99': this.handleChangeRowsPerPage
    };
    this.state = {
      firebaseData: [],
      firebaseData1: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      agents: [],
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      banks: [],
      products: [],
      bank: '',
      product: '',
      agentName: '',
      agent: '',
      topIndex: '-1',
      startDate: '',
      endDate: '',
      openModal: false,
      applicationId: '',
      customerName: '',
      mobileNumber: '',
      productName: '' ,
      rvAddress: '',
      bvAddress: '',
      pvAddress: '',

      pages: [30, 50, 100, 300],
      page: 0,
      rowsPerPage: 30,
      lastVisible: {},
      count: 0,
      queryParams: {},
      totalRecords: 0
    };
  }

  componentDidMount() {
    let comp = this

    var today = new Date()
    var currentDate = formatDate(today) 
    
    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }


    // firebase.firestore().collection("Case")
    // .where("Status", "==", "Pending")
    // .where("HP", "==", "YES")
    // .where("State", "array-contains-any", state.split(",").slice(0, -1))
    // .orderBy("TimeStamp", "desc")
    // .limit(this.state.rowsPerPage)
    // .get().then((querySnapshot) => {
    //     var loans = [];

    //     var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
    //     console.log("last", lastVisible);

    //     querySnapshot.forEach(function(doc) {
    //       if(typeof doc.data().CreatedAt == "string") {
    //         if(formatDate(doc.data().CreatedAt) === currentDate) {
    //         loans.push(doc.data())
    //         }
    //       }
    //     });
    //     comp.setState({
    //       firebaseData: loans,
    //       lastVisible: lastVisible
    //     })
    // });

    // firebase.firestore().collection("Case")
    // .where("Status", "==", "Pending")
    // .where("HP", "==", "YES")
    // .where("State", "array-contains-any", state.split(",").slice(0, -1))
    // .orderBy("TimeStamp", "desc")
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //           const loan = {
    //             ApplicationId: doc.data().ApplicationId,
    //             CustomerName: doc.data().CustomerName,
    //             Bank: doc.data().Bank,
    //             Product: doc.data().ProductName,
    //             CaseType: doc.data().FI,
    //             TATTime: doc.data().TATTime,
    //             Agent: doc.data().AgentName,
    //             Status: doc.data().Status,
    //             Allocation_Date_Time: doc.data().CreatedAt,
    //             CPV_Status: (doc.data().QCpvStatus == undefined) ? "": doc.data().QCpvStatus,
    //             Reject_Reason:(doc.data().QRejectReason == undefined) ? "": doc.data().QRejectReason,
    //           }
    //           loans.push(loan)
    //     });
    //     comp.setState({
    //       firebaseData1: loans
    //     })
    // });

    var config1 = {
      method: 'get',
      url: API_BASE_URL + '/auth/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config1)
    .then(function (response1) {
      comp.setState({
        banks: response1.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    var config2 = {
      method: 'get',
      url: API_BASE_URL + '/auth/loanproduct',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config2)
    .then(function (response2) {
      comp.setState({
        products: response2.data
      })
    })
    .catch(function (error) {
      console.log(error);
      // comp.handleNon200Response(error, "1");
    });

    if(state.includes("SUPER ADMIN")) {
      var config = {
        method: 'get',
        url: API_BASE_URL + '/auth/approvedAgents',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
        }
      };

      var ags = [];
    
      axios(config)
      .then(function (response) {
          console.log(response.data)
          for(let i=0; i< response.data.length; i++) {
            if(response.data[i].agentid !== undefined) {
              const dict = {
                label: (response.data[i].agentid) + "-" + response.data[i].name,
                value: response.data[i]._id
              }
              ags.push(dict)
            }
          }
        comp.setState({
          agents: ags,
        })
      })
      .catch(function (error) {
        console.log(error);
        // comp.handleNon200Response(error, "1");
      });
    } else {

      var jsonData = JSON.stringify({
        state: state.split(",").slice(0, -1)
      });

      var config = {
        method: 'post',
        url: API_BASE_URL + '/auth/report/approvedAgents',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        },
        data: jsonData
      };

      var ags = [];
    
      axios(config)
      .then(function (response) {
          console.log(response.data)
          for(let i=0; i< response.data.length; i++) {
            if(response.data[i].agentid !== undefined) {
              const dict = {
                label: (response.data[i].agentid) + "-" + response.data[i].name,
                value: response.data[i]._id
              }
              ags.push(dict)
            }
          }
        comp.setState({
          agents: ags,
        })
      })
      .catch(function (error) {
        console.log(error);
        // comp.handleNon200Response(error, "1");
      });
    }

    comp.fetchList()
  }

  async fetchList() {
    let comp = this

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    var jsonData = {
      state: state.split(",").slice(0, -1)
    };

    let bank = localStorage.getItem("bank")
    if(bank === null) {
        bank = sessionStorage.getItem("bank")
    }
  
    if(!bank.includes("SUPER ADMIN")) {
      jsonData.bank = bank.split(",").slice(0, -1)
    }

    comp.setState({
      queryParams: jsonData
    })

    jsonData.pagination = true
    jsonData.pageSize = comp.state.rowsPerPage
    jsonData.pageCount = Number(comp.state.page) + 1
  
    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/hpPendingCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        let downloadData = []
        if(response.status == 200) {
          comp.setState({
            firebaseData: response.data.caseList,
            totalRecords: response.data.totalRecords
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      // comp.handleNon200Response(error, "1");
    });
  }


  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    })

    let comp = this
    let jsonData = comp.state.queryParams

    jsonData.pagination = true
    jsonData.pageSize = comp.state.rowsPerPage
    jsonData.pageCount = Number(newPage) + 1

    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/hpPendingCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        if(response.status == 200) {
          comp.setState({
            firebaseData: response.data.caseList,
            totalRecords: response.data.totalRecords
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "98");
    });
  }
  
  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })

    let comp = this
    let jsonData = comp.state.queryParams

    jsonData.pagination = true
    jsonData.pageSize = parseInt(event.target.value, 10)
    jsonData.pageCount = Number(0) + 1

    let jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/hpPendingCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        if(response.status == 200) {
          comp.setState({
            firebaseData: response.data.caseList,
            totalRecords: response.data.totalRecords
          })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "99");
    });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  agentsOnChange = agentId => {
    this.setState({ agentId });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state
    let comp = this

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    let jsonData = {
      state: state.split(",").slice(0, -1),
    }

    let bank = localStorage.getItem("bank")
    if(bank === null) {
        bank = sessionStorage.getItem("bank")
    }
  
    if(!bank.includes("SUPER ADMIN")) {
      jsonData.bank = bank.split(",").slice(0, -1)
    }

    if(data.product !== "") {
      jsonData.product = data.product
    }

    comp.setState({
      queryParams: jsonData
    })

    jsonData.pagination = true
    jsonData.pageSize = comp.state.rowsPerPage
    jsonData.pageCount = Number(comp.state.page) + 1

    var jsonData1 = JSON.stringify(jsonData);

    var config = {
      method: 'post',
      url: API_BASE_URL + '/auth/report/hpPendingCase',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
        'Content-Type': 'application/json'
      },
      data: jsonData1
    };
    
    axios(config)
    .then(function (response) {
        console.log(response.data)
        if(response.status == 200) {
              comp.setState({
                firebaseData: response.data.caseList,
                totalRecords: response.data.totalRecords
            })
        }
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "2");
    });
}

handleFilter(e) {
  e.preventDefault();

  let data = this.state
  let comp = this

  var state = localStorage.getItem("state")
  if(state === null) {
      state = sessionStorage.getItem("state")
  }

  if(data.startDate === '') {
    alert("Select Start date");
    document.getElementById("startDate").focus();
    return;
  }

  if(data.endDate === '') {
    alert("Select End Date");
    document.getElementById("endDate").focus();
    return;
  }

  var jsonData = {
    state: state.split(",").slice(0, -1),
    startDate: data.startDate,
    endDate: data.endDate
  };

  let bank = localStorage.getItem("bank")
  if(bank === null) {
      bank = sessionStorage.getItem("bank")
  }

  if(!bank.includes("SUPER ADMIN")) {
    jsonData.bank = bank.split(",").slice(0, -1)
  }

  comp.setState({
    queryParams: jsonData
  })

  jsonData.pagination = true
  jsonData.pageSize = comp.state.rowsPerPage
  jsonData.pageCount = Number(comp.state.page) + 1

  let jsonData1 = JSON.stringify(jsonData);

  var config = {
    method: 'post',
    url: API_BASE_URL + '/auth/report/hpPendingCase',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json'
    },
    data: jsonData1
  };
  
  axios(config)
  .then(function (response) {
      console.log(response.data)
      if(response.status == 200) {
            comp.setState({
              firebaseData: response.data.caseList,
              totalRecords: response.data.totalRecords
          })
      }
  })
  .catch(function (error) {
    console.log(error);
    comp.handleNon200Response(error, "1");
  });
}

  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    td8 = tr[i].getElementsByTagName("td")[8];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
      txtValue7 = td7.textContent || td7.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  exportToCSV(fileName) {
    let comp = this
    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    var jsonData = {
      state: state.split(",").slice(0, -1)
    };

    let bank = localStorage.getItem("bank")
    if(bank === null) {
        bank = sessionStorage.getItem("bank")
    }
  
    if(!bank.includes("SUPER ADMIN")) {
      jsonData.bank = bank.split(",").slice(0, -1)
    }
  
      let jsonData1 = JSON.stringify(jsonData);
      var config = {
        method: 'post',
        url: API_BASE_URL + '/auth/report/hpPendingCase',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        },
        data: jsonData1
      };
      
      axios(config)
      .then(function (response) {
          let loans = []
          if(response.status == 200) {
            for(let i=0; i< response.data.length; i++) {
              const loan = {
                ApplicationId: response.data[i].applicationid,
                CustomerName: response.data[i].customername,
                Bank: response.data[i].bank,
                Product: response.data[i].productname,
                CaseType: response.data[i].fi,
                TATTime: response.data[i].tattime,
                AgentName: response.data[i].agentname,
                Status: response.data[i].status,
                Allocation_Date_Time: (response.data[i].createdat == undefined || response.data[i].createdat == "") ? "": response.data[i].createdat.slice(0, 10),
                Completion_Date_Time: (response.data[i].submittedat == undefined || response.data[i].submittedat == "") ? "": response.data[i].submittedat.slice(0, 10),
                CPV_Status: (response.data[i].qcpvstatus == undefined) ? "": response.data[i].qcpvstatus,
                Reject_Reason:(response.data[i].qrejectreason == undefined) ? "": response.data[i].qrejectreason,
              }
              loans.push(loan)
            }
          }
    
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(loans);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);
      })
      .catch(function (error) {
        console.log(error);
        comp.handleNon200Response(error, "1");
      });
  }

  handleAssignAgent(key, index) {

    console.log(key.PushId)
    let data = this.state
    let comp = this
  
    if(data.topIndex === index) {
      if(data.agent === "Select") {
          alert("Select Agent")
          return
    }

      var jsonData = JSON.stringify({
        "agentname": data.selectedMulti.label.split("-")[1],
        "agent": data.selectedMulti.value,
        "agentid": data.selectedMulti.label.split("-")[0],
      })
  
      var config = {
        method: "put",
        url: API_BASE_URL + "/auth/case/" + key._id,
        headers: {
          apikey: API_KEY,
          Authorization: "Bearer " + getCookie("at"),
          "Content-Type": "application/json",
        },
        data: jsonData,
      }
  
      axios(config)
        .then(function (response) {
          if (response.status == 200) {
            comp.fetchList()
            comp.setState({
              agent: '',
              agentName: '',
              topIndex: '-1'
            })
            alert("Agent Assigned Successfully!!")
          }
        })
        .catch(function (error) {
          console.log(error)
          comp.handleNon200Response(error, "4")
        })
      } else {
        alert("Select Agent")
        return
      }
  
      document.getElementById(`agent${key._id}`).value = ""
    
  }

  toggle() {
    let comp = this

    comp.setState({
      openModal: false
    })
  }

  getCaseDetails(key) {
    let comp = this


    let rvAddress = []
    let bvAddress = []
    let pvAddress = []

    comp.setState({
      rvAddress: '',
      bvAddress: '',
      pvAddress: ''
    })

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    var jsonData = JSON.stringify({
      state: state.split(",").slice(0, -1),
      applicationId: key.applicationid
    })


    var config = {
      method: "post",
      url: API_BASE_URL + "/auth/case/searchByApplicationId",
      headers: {
        apikey: API_KEY,
        Authorization: "Bearer " + getCookie("at"),
        "Content-Type": "application/json",
      },
      data: jsonData,
    }

    axios(config)
      .then(function (response) {
        if (response.status == 200) {
            for(let i=0; i< response.data.length; i++) {
              rvAddress.push(response.data[i].residenceaddress)
              bvAddress.push(response.data[i].officeaddress)
              pvAddress.push(response.data[i].permanentaddress)
            }

            for(let i = 0; i< rvAddress.length; i++) {
              if(rvAddress[i] !== undefined) {
                comp.setState({
                  rvAddress: rvAddress[i]
                })
              }
            }
      
            for(let i = 0; i< bvAddress.length; i++) {
              if(bvAddress[i] !== undefined) {
                comp.setState({
                  bvAddress: bvAddress[i]
                })
              }
            }
            for(let i = 0; i< pvAddress.length; i++) {
              if(pvAddress[i] !== undefined) {
                comp.setState({
                  pvAddress: pvAddress[i]
                })
              }
            }
          
            comp.setState({
              applicationId: key.applicationid,
              customerName: key.customername,
              mobileNumber: key.mobilenumber,
              productName: key.productname,
              openModal: true
          })
        }
      })
      .catch(function (error) {
        console.log(error.response.data)
        comp.handleNon200Response(error, "5")
      })
}

handleMulti = (selectedMulti, index) => {
  this.setState({ 
    selectedMulti,
    topIndex: index
  });
};

searchByApplicationId(e) {
  let data = this.state
  let comp = this

  console.log("Search")

  var state = localStorage.getItem("state")
  if(state === null) {
      state = sessionStorage.getItem("state")
  }

  comp.setState({
    firebaseData: []
  })

  if(data.applicationId === '') {
    alert("Enter Application ID")
    document.getElementById("applicationId").focus()
    return
  }

  let jsonData = {
    state: state.split(",").slice(0, -1),
    applicationId: data.applicationId
  }

  let bank = localStorage.getItem("bank")
  if(bank === null) {
      bank = sessionStorage.getItem("bank")
  }

  if(!bank.includes("SUPER ADMIN")) {
    jsonData.bank = bank.split(",").slice(0, -1)
  }

  let jsonData1 = JSON.stringify(jsonData);

  var config = {
    method: 'post',
    url: API_BASE_URL + '/auth/case/searchByApplicationId',
    headers: { 
      apikey: API_KEY, 
      Authorization: "Bearer " + getCookie("at"),
      'Content-Type': 'application/json'
    },
    data: jsonData1
  };
  
  axios(config)
  .then(function (response) {
      console.log(response.data)
      if(response.status == 200) {
            comp.setState({
              firebaseData: response.data,
              totalRecords: 1,
          })
      }
  })
  .catch(function (error) {
    console.log(error);
    comp.handleNon200Response(error, "1");
  });
}

  render() {
    let bank = localStorage.getItem("bank")
    if(bank === null) {
        bank = sessionStorage.getItem("bank")
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Report" breadcrumbItem="Pending HP Case Report" />
            <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                    <Row>
                                        <Col md = {3} className = "input-group">
                                                <input className="form-control" placeholder = "Application ID"  type="applicationId" name="applicationId" id="applicationId" value = {this.state.applicationId}  onChange = {this.handleChange.bind(this)} />
                                                <span className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="search">
                                                    <FontAwesomeIcon style = {{fontSize: "10px", cursor: "pointer"}} icon={faSearch} onClick = {this.searchByApplicationId.bind(this)}/>
                                                    </button>
                                                </span>
                                            </Col>
                                        </Row>
                                        <br />
                                      <Row>
                                            <Col md = "3">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            <Button color="primary" onClick = {this.exportToCSV.bind(this, "Pending HP Case Report")} type="submit">Excel</Button>
                                            </Col>
                                        </Row>
                                        <br />

                                          <Row>
                                              {/* <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.bank} id = "bank" name = "bank" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Bank</option>
                                                          {this.state.banks.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col> */}
           
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Products<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.product} id = "product" name = "product" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Product</option>
                                                          {this.state.products.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                              <Button color="primary" onClick = {this.handleSubmit.bind(this)} type="submit">Submit</Button>
                                              </Col>
                                              </Row>

                                        <br />

                                        <Row>
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.startDate} id = "startDate" name = "startDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.endDate} id = "endDate" name = "endDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 3}} style = {{margin: "auto"}}>
                                                  <Button color="primary" onClick = {this.handleFilter.bind(this)} type="submit">Filter</Button>
                                                </Col>
                                              </Row>
                                              <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>Bank</th>
                                                            <th>Product</th>
                                                            <th>Case Type</th>
                                                            <th>TAT Time</th>
                                                            <th>Agent</th>
                                                            <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                   <>
                                                   {bank.includes("SUPER ADMIN") || rowData.showToClient == "YES" ?
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td onClick = {this.getCaseDetails.bind(this, rowData)} style = {{color: "#556EE6", cursor: "pointer"}}>{rowData.applicationid}</td>
                                                        <td>{rowData.customername}</td>
                                                        <td>{rowData.bank}</td>
                                                        <td>{rowData.productname}</td>
                                                        <td>{rowData.fi}</td>
                                                        <td>{rowData.tattime}</td>
                                                        <td>{rowData.agentname} <br /> <br />
                                                        <Select
                                                                onChange={(event) => this.handleMulti(event,index)}
                                                                options={this.state.agents}
                                                                id={`agent${rowData._id}`}
                                                                placeholder = "Select Agent"
                                                                styles = {customStyles1}
                                                            /> <br />
                                                          <Button color="primary" onClick = {this.handleAssignAgent.bind(this, rowData, index)}  type="submit">Submit</Button>
                                                        </td>
                                                        <td>{rowData.status}</td>
                                                     </tr>
                                                     : null }
                                                     </>
                                                ))}                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.totalRecords}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          />

                                        <Modal isOpen={this.state.openModal} toggle={this.toggle.bind(this)}>
                                          <ModalHeader toggle={this.toggle.bind(this)}>{this.state.applicationId}</ModalHeader>
                                          <ModalBody>
                                            <Row>
                                              <Col md = {4}>
                                                <h6>Customer Name</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.customerName}</h6>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col md = {4}>
                                                <h6>Mobile Number</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.mobileNumber}</h6>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col md = {4}>
                                                <h6>Product</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.productName}</h6>
                                              </Col>
                                            </Row>
                                            {this.state.pvAddress !== '' ?
                                            <Row>
                                              <Col md = {4}>
                                                <h6>PV Address</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.pvAddress}</h6>
                                              </Col>
                                            </Row> : null }
                                            {this.state.rvAddress !== '' ?
                                            <Row>
                                              <Col md = {4}>
                                                <h6>RV Address</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.rvAddress}</h6>
                                              </Col>
                                            </Row> : null }
                                            {this.state.bvAddress !== '' ?
                                            <Row>
                                              <Col md = {4}>
                                                <h6>BV Address</h6>
                                              </Col>
                                              <Col md = {8}>
                                                <h6>: {this.state.bvAddress}</h6>
                                              </Col>
                                            </Row> : null }
                                          </ModalBody>
                                        </Modal>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default PendingHighPriorityCase;
import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import { pdf,PDFViewer } from '@react-pdf/renderer';
import axios from "axios";
import RichTextEditor from 'react-rte';
import CasePdfMail from "../Reports/casePdfMail";
import Loader from "react-loader-spinner";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
  };

  const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
       }))
  
  
  
    function dataURLtoFile(dataurl, filename) {
       var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
       bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
       while(n--){
       u8arr[n] = bstr.charCodeAt(n);
       }
     return new File([u8arr], filename, {type:mime});
    }

    const bodyMail = "<p>Dear Colleagues,<br></p><p>The FI image upload activity&nbsp; has been completed</p><p>Regards,<br></p><p><strong>LocationTec Powered by Adsum</strong></p><p><strong>Note-This is system generated mail, please do not reply</strong></p>"


class SendMail extends Component {
  constructor(props) {
    super(props);
    this.searchByApplicationId = this.searchByApplicationId.bind(this)
    this.funcMap = {
      '1': this.searchByApplicationId,
    };
    this.state = {
        to: '',
        cc: '',
        subject: 'The FI image upload activity has been completed',
        body: RichTextEditor.createValueFromString(bodyMail, 'html'),
        applicationId: '',
        casePdf: false,
        caseImage: false,


        firebaseData: [],
        photos: [],
        menu: 'Select',
        success_msg: false,
        agents: [],
        home: false,
        q: '',
        pdf: false,
  
      qAddressConfirmed: '',
      qStayConfirmed: '',
      qPersonMet: '',
      qPersonRelationship: '',
      qPersonStability: '',
      qTotalAgriLand: '',
      qLandDetails: '',
      qIrrigationSource: '',
      qCropType: '',
      qesidenceOwnership: '',
      qFamilyMembersCount: '',
      qEarningMembersCount: '',
      qOfficeAddress: '',
      officeAddress: '',
      qResidenceProofId: '',
      qAgricultureLending: '',
      qInterior: '',
      qExterior: '',
      qNeighbour1: '',
      qNeighbour2: '',
      qCpvStatus: '',
      qRejectReason: '',
      qAgentRemarks: '',
      agentSignature: '',
      qPersonMet: '',
      qPersonMetDesignation: '',
      qCoOffice: '',
      qAddressConfirmed: '',
      qPersonDesignation: '',
      qResidentAddress: '',
      qNature: '',
      qOfficeOwnership: '',
      qWorkingSince: '',
      qApproxIncome: '',
      qNumberEmployees: '',
      qStocks: '',
      qBusinessProof: '',
      qBusinessActivity: '',
      qSignBoardText: '',
      qOfficeSeenText: '',
      qVehicleDetails: '',
      qStability: '',
      qustomerSignature: '',
      qTpc1: '',
      qTpc1Check: '',
      qTpc2: '',
      qTpc2Check: '',
      qCpvStatus: '',
      qRejectReason: '',
      qAgentRemarks: '',
      agentSignature: '',
      qAddressConfirmed: '',
      qPersonMet: '',
      qPersonRelationship: '',
      qPersonStability: '',
      qResidenceOwnership: '',
      qFamilyMembersCount: '',
      qEarningMembersCount: '',
      qSpouseWorking: '',
      qEarningMembers: '',
      qNbfcName: '',
      qLoanAmount: '',
      qPurpose: '',
      qResidenceStatus: '',
      qPermanentAddress: '',
      qContactPerson: '',
      qRent: '',
      qVerifiedFrom: '',
      qArea: '',
      qAssetDetails: '',
      qAssetAppliedFor: '',
      qAssetToBeUsedBy: '',
      qEaseOfLocate: '',
      qLandmark: '',
      qTypeOfResidence: '',
      qLocalityOfResidence: '',
      qAreaofResidence: '',
      qResidenceConstruction: '',
      qCommentOnExterior: '',
      qCommentOnInterior: '',
      qAssetSeenAtResidence: '',
      qTwoWheeler: '',
      qTwoWheelerModelName: '',
      qTwoWheelerYear: '',
      qCar: '',
      qCarModelName: '',
      qCarYear: '',
      qVehicleUsedBy: '',
      qPersonNameVerification: '',
      qApplicantDetailsConfirmed: '',
      qNegativeFeedback: '',
      qResidenceLocked: '',
      qCpvStatus: '',
      qRejectReason: '',
      productName: '',
      customerName: '',
      amount: '',
      bank: '',
      agentName: '',
      fi: '',
  
      markers: [],
      markerLatitude: '',
      markerLongitude: '',
      myLatLong: {},
  
      submittedAt: "",
      fiDate: "",
      fiTime: "",
      fiFlag: "",
      dob: "",
      residentAddresss: "",
      permanentAddress: "",
      mobileNumber: "",
      qIncome: '',
      qAge: '',
      qEducation: '',
      qOccupation: '',
      qLandDetails: '',
      qBranchDistance: '',
      stamp: [],
      showLoader: false,
      fiType: 'Select'
    };
  }

  componentDidMount() {
    let comp = this
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  searchByApplicationId(e) {
    let data = this.state
    let comp = this

    var state = localStorage.getItem("state")
    if(state === null) {
        state = sessionStorage.getItem("state")
    }

    console.log(data.applicationId)


    comp.setState({
      firebaseData: []
    })

    if(data.applicationId === '') {
      alert("Enter Application ID")
      document.getElementById("applicationId").focus()
      return
    }

    if(data.fiType === 'Select') {
      alert("Select FI Type")
      document.getElementById("fiType").focus()
      return
    }

    var caseId = ""
    var stamp = []

    toDataURL("https://firebasestorage.googleapis.com/v0/b/locationtec-f56ff.appspot.com/o/Mail%2F%5Bobject%20File%5DTue%20Jan%2025%202022%2022%3A27%3A24%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=c40dfdb2-c30a-44f7-9959-f11523a191a9")
    .then(dataUrl => {
    var fileData1 = dataURLtoFile(dataUrl, "signature.jpg")
    console.log(fileData1)
    stamp.push(fileData1)

    comp.setState({
      stamp: stamp
    })
  })

  var jsonData = JSON.stringify({
    state: state.split(",").slice(0, -1),
    applicationId: data.applicationId,
    fi: data.fiType
  })

  var config = {
    method: "post",
    url: API_BASE_URL + "/auth/case/searchByApplicationId",
    headers: {
      apikey: API_KEY,
      Authorization: "Bearer " + getCookie("at"),
      "Content-Type": "application/json",
    },
    data: jsonData,
  }

  axios(config)
    .then(function (response) {
      if (response.status == 200) {
        comp.setState({
          firebaseData: response.data
        })

        if(response.data.length > 0) {
          caseId = response.data[0]._id
        } else {
          caseId = ""
        }



    // firebase.firestore().collection("Case")
    // .where("ApplicationId", "==", data.applicationId)
    // .where("FI", "==", data.fiType)
    // .get().then((querySnapshot) => {
    //     var loans = [];
    //     querySnapshot.forEach(function(doc) {
    //       if(doc.data().ApplicationId !== undefined) {
    //         caseId = doc.id
    //         loans.push(doc.data())
    //       }
    //     });
    //     comp.setState({
    //       firebaseData: loans
    //     })

        console.log("Fire", comp.state.firebaseData)

        if(caseId !== "") {
          var config1 = {
            method: "get",
            url: API_BASE_URL + "/auth/case/" + caseId,
            headers: {
              apikey: API_KEY,
              Authorization: "Bearer " + getCookie("at"),
            },
          }
      
          axios(config1)
            .then(function (response1) {
              if (response1.status == 200) {
                comp.setState({
                  qAddressConfirmed:(response1.data.qaddressconfirmed === undefined) ? "" : response1.data.qaddressconfirmed,
                  qStayConfirmed: (response1.data.qstayconfirmed === undefined) ? "": response1.data.qstayconfirmed,
                  qPersonRelationship:(response1.data.qpersonrelationship === undefined) ? "": response1.data.qpersonrelationship,
                  qPersonStability:(response1.data.qstability === undefined) ? "": response1.data.qstability,
                  qTotalAgriLand:(response1.data.qtotalagriland === undefined) ? "": response1.data.qtotalagriland,
                  qLandDetails:(response1.data.qlanddetails === undefined) ? "": response1.data.qlanddetails,
                  qIrrigationSource:(response1.data.qirrigationsource === undefined) ? "": response1.data.qirrigationsource,
                  qCropType:(response1.data.qcroptype === undefined) ? "": response1.data.qcroptype,
                  qesidenceOwnership:(response1.data.residenceownership === undefined) ? "": response1.data.residenceownership,
                  qAge: (response1.data.qage === undefined) ? "": response1.data.qage,
                  qEducation: (response1.data.qeducation === undefined) ? "": response1.data.qeducation,
                  qFamilyMembersCount:(response1.data.qfamilymemberscount === undefined) ? "": response1.data.qfamilymemberscount,
                  qEarningMembersCount:(response1.data.qearningmemberscount === undefined) ? "": response1.data.qearningmemberscount,
                  qOfficeAddress:(response1.data.qofficeaddress === undefined) ? "": response1.data.qofficeaddress,
                  officeAddress:response1.data.officeaddress,
                  qResidenceProofId:(response1.data.qresidenceproofid === undefined) ? "": response1.data.qresidenceproofid,
                  qAgricultureLending:(response1.data.qirrigationsource === undefined) ? "": response1.data.qirrigationsource,
                  qInterior:(response1.data.qinterior === undefined) ? "": response1.data.qinterior,
                  qExterior:(response1.data.qexterior === undefined) ? "": response1.data.qexterior,
                  qNeighbour1:(response1.data.qneighbour1 === undefined) ? "": response1.data.qneighbour1,
                  qNeighbour2:(response1.data.qneighbour2 === undefined) ? "": response1.data.qneighbour2,
                  qCpvStatus:(response1.data.qcpvstatus === undefined) ? "": response1.data.qcpvstatus,
                  qRejectReason:(response1.data.qrejectreason === undefined) ? "": response1.data.qrejectreason,
                  qAgentRemarks:(response1.data.qagentremarks === undefined) ? "": response1.data.qagentremarks,
                  agentSignature:(response1.data.agentsignature === undefined) ? "": response1.data.agentsignature,

                  qPersonMet: (response1.data.qpersonmet === undefined) ? "": response1.data.qpersonmet,
                  qPersonMetDesignation:(response1.data.qpersonmetdesignation === undefined) ? "": response1.data.qpersonmetdesignation,
                  qCoOffice: (response1.data.qcooffice === undefined) ? "": response1.data.qcooffice,
                  qAddressConfirmed: (response1.data.qaddressconfirmed === undefined) ? "": response1.data.qaddressconfirmed,
                  qPersonDesignation:(response1.data.qpersondesignation === undefined) ? "": response1.data.qpersondesignation,
                  qResidentAddress: (response1.data.qresidentaddress === undefined) ? "": response1.data.qresidentaddress,
                  qNature: (response1.data.qnature === undefined) ? "": response1.data.qnature,
                  qOfficeOwnership: (response1.data.qofficeownership === undefined) ? "": response1.data.qofficeownership,
                  qWorkingSince:(response1.data.qworkingsince === undefined) ? "": response1.data.qworkingsince,
                  qApproxIncome: (response1.data.qapproxincome === undefined) ? "": response1.data.qapproxincome,
                  qNumberEmployees: (response1.data.qnumberemployees === undefined) ? "": response1.data.qnumberemployees,
                  qStocks: (response1.data.qstocks === undefined) ? "": response1.data.qstocks,
                  qBusinessProof: (response1.data.qbusinessproof === undefined) ? "": response1.data.qbusinessproof,
                  qBusinessActivity: (response1.data.qbusinessactivity === undefined) ? "": response1.data.qbusinessactivity,
                  qSignBoardText: (response1.data.qsignboardtext === undefined) ? "": response1.data.qsignboardtext,
                  qOfficeSeenText:(response1.data.qofficeseentext === undefined) ? "": response1.data.qofficeseentext,
                  qVehicleDetails: (response1.data.qvehicledetails === undefined) ? "": response1.data.qvehicledetails,
                  qStability: (response1.data.qstability === undefined) ? "": response1.data.qstability,
                  qustomerSignature: (response1.data.customersignature === undefined) ? "": response1.data.customersignature,
                  qTpc1: (response1.data.qtpc1 === undefined) ? "": response1.data.qtpc1,
                  qTpc1Check: (response1.data.qtpc1check === undefined) ? "": response1.data.qtpc1check,
                  qTpc2: (response1.data.qtpc2 === undefined) ? "": response1.data.qtpc2,
                  qTpc2Check: (response1.data.qtpc2check === undefined) ? "": response1.data.qtpc2check,
                  qCpvStatus: (response1.data.qcpvstatus === undefined) ? "": response1.data.qcpvstatus,
                  qRejectReason:(response1.data.qrejectreason === undefined) ? "": response1.data.qrejectreason,
                  qAgentRemarks: (response1.data.qagentremarks === undefined) ? "": response1.data.qagentremarks,

                  qPersonMet: response1.data.qpersonmet,
                  qPersonRelationship: response1.data.qpersonrelationship,
                  qPersonStability: response1.data.qpersonstability,
                  qResidenceOwnership: response1.data.qresidenceownership,
                  qFamilyMembersCount: response1.data.qfamilymemberscount,
                  qEarningMembersCount: response1.data.qearningmemberscount,
                  qSpouseWorking: response1.data.qspouseworking,
                  qEarningMembers: response1.data.qearningmembers,
                  qNbfcName: response1.data.qnbfcname,
                  qLoanAmount: response1.data.qloanamount,
                  qPurpose: response1.data.qpurpose,
                  qResidenceStatus: response1.data.qresidencestatus,
                  qPermanentAddress: response1.data.qpermanentaddress,
                  qContactPerson: response1.data.qcontactperson,
                  qRent: response1.data.qrent,
                  qVerifiedFrom: response1.data.qverifiedfrom,
                  qArea: response1.data.qarea,
                  qAssetDetails: response1.data.qassetdetails,
                  qAssetAppliedFor: response1.data.qassetappliedfor,
                  qAssetToBeUsedBy: response1.data.qassettobeusedby,
                  qEaseOfLocate: response1.data.qeaseoflocate,
                  qLandmark: response1.data.qlandmark,
                  qTypeOfResidence: response1.data.qtypeofresidence,
                  qLocalityOfResidence: response1.data.qlocalityofresidence,
                  qAreaofResidence: response1.data.qareaofresidence,
                  qResidenceConstruction: response1.data.qresidenceconstruction,
                  qCommentOnExterior: response1.data.qcommentonexterior,
                  qCommentOnInterior: response1.data.qcommentoninterior,
                  qAssetSeenAtResidence: response1.data.qassetseenatresidence,
                  qTwoWheeler: response1.data.qtwowheeler,
                  qTwoWheelerModelName: response1.data.qtwowheelermodelname,
                  qTwoWheelerYear: response1.data.qtwowheeleryear,
                  qCar: response1.data.qcar,
                  qCarModelName: response1.data.qcarmodelname,
                  qCarYear: response1.data.qcaryear,
                  qVehicleUsedBy: response1.data.qvehicleusedby,
                  qPersonNameVerification: response1.data.qpersonnameverification,
                  qApplicantDetailsConfirmed: response1.data.qapplicantdetailsconfirmed,
                  qNegativeFeedback: response1.data.qnegativefeedback,
                  qOccupation: (response1.data.qoccupation === undefined) ? "": response1.data.qoccupation,
                  qLandDetails: (response1.data.qlanddetails === undefined) ? "": response1.data.qlanddetails,
                  qBranchDistance: (response1.data.qbranchdistance === undefined) ? "": response1.data.qbranchdistance,
                  qIncome:(response1.data.qincome === undefined) ? "": response1.data.qincome,
                  qResidenceLocked: response1.data.qresidencelocked,
                  qCpvStatus: response1.data.qcpvstatus,
                  qRejectReason: response1.data.qrejectreason,
                  productName: response1.data.productname,
                  fi: response1.data.fi,

                  customerName: response1.data.customername,
                  amount: response1.data.loanamount,
                  bank: response1.data.bank,
                  agentName: response1.data.agentname,

                  submittedAt: response1.data.submittedat,
                  applicationId: response1.data.applicationid,
                  fiDate: response1.data.fidate,
                  fiTime: response1.data.fitime,
                  fiFlag: response1.data.fiflag,
                  dob: response1.data.dateofbirth,
                  residentAddresss: response1.data.residenceaddress,
                  permanentAddress: response1.data.permanentaddress,
                  mobileNumber: response1.data.mobilenumber
                })

                // if(doc.data().LocationCoordinates !== undefined) {
                //   comp.setState({
                //       markerLatitude: doc.data().LocationCoordinates.latitude,
                //       markerLongitude: doc.data().LocationCoordinates.longitude,
                //   })
                // }
            }
          })
          .catch(function (error1) {
            console.log(error1)
            comp.handleNon200Response(error1, "1")
          })

          // firebase.firestore().collection("Case").doc(caseId).collection("Photo")
          // .onSnapshot(function(querySnapshot) {
          //   var photos = [];
          //   var images = []
          //     querySnapshot.forEach(function(doc) {
          //         images.push(doc.data().Photo)
          //     })
              
          //     let count = 0
          //     for(let i = 0; i< images.length; i++) {
          //       toDataURL(images[i])
          //       .then(dataUrl => {
          //         count++
          //         var fileData = dataURLtoFile(dataUrl, "image" + count + ".jpg");
          //         //  console.log("Here is JavaScript File Object",fileData)
          //         photos.push(fileData)
          //       })
          //     }
          //     comp.setState({
          //         photos: photos
          //     })
          // })
      } else {
        alert("Case not found")
        return
      }
    }
  })
  .catch(function (error1) {
    console.log(error1)
    comp.handleNon200Response(error1, "1")
  })
}

async handleSubmit(e) {    
    const url = "https://us-central1-locationtec-f56ff.cloudfunctions.net/app";

    let comp = this
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    console.log(this.state.body.toString('html'))

    if(this.state.to==''){
      alert("Please Enter a TO email")
      document.getElementById("to").focus();
      return
  }

    // if(this.state.to!=='' && !emailPattern.test(this.state.to)){
    //     alert("Please Enter a valid email")
    //     document.getElementById("to").focus();
    //     return
    // }
  

    // if(this.state.cc!=='' && !emailPattern.test(this.state.cc)){
    //     alert("Please Enter a valid CC email")
    //     document.getElementById("cc").focus();
    //     return
    // }

    if(this.state.subject == ''){
      alert("Select Subject")
        document.getElementById("subject").focus();
        return
    }

  // 126525812

    if(this.state.applicationId === '') {
      alert("Select Case")
      document.getElementById("applicationId").focus();
      return
    }

    if(this.state.fiType === 'Select') {
      alert("Select FI Type")
      document.getElementById("fiType").focus();
      return
    }

    if(this.state.firebaseData.length === 0) {
      alert("Click on the search icon")
      return
    }

    comp.setState({
      showLoader: true
    })

        const data = new FormData();
        const doc = <CasePdfMail data={comp.state} />
        const asPdf = pdf(doc); 
        const blob = await asPdf.toBlob();
        const pdffile = new File([blob], `case_${this.state.applicationId}.pdf`, {lastModified: new Date()});
        if(this.state.casePdf) {
          data.append('file', pdffile);
        }

        if(this.state.caseImage) {
            for(var x = 0; x<this.state.photos.length; x++) {
              console.log("Files data", this.state.photos[x])
              data.append('file', this.state.photos[x])
              }
        }

        for(var x = 0; x<this.state.stamp.length; x++) {
          console.log("Stamp", this.state.stamp[x])
          data.append('file', this.state.stamp[x])
        }

        data.append('email',this.state.to);
        data.append('cc',this.state.cc);
        data.append('subject',this.state.subject);
        data.append('message',this.state.body.toString('html'));

        for (var pair of data.entries()) {
          console.log(pair[0]+ ', ' + pair[1]); 
        }

        axios.post(url+'/send', data ,{ headers: { 'Content-Type': 'multipart/form-data',"Access-Control-Allow-Origin": "*" }})
        .then(response => {
          console.log(response.data)
          if(response.data.success) {
            alert("Mail Sent Successfully")
          }
            comp.setState({
              to: '',
              cc: '',
              subject: 'The FI image upload activity has been completed',
              body: RichTextEditor.createValueFromString(bodyMail, 'html'),
              applicationId: '',
              fiType: 'Select',
              firebaseData: [],
              caseImage: false,
              casePdf: false,
              showLoader: false
            });
        })
        .catch(function (error) {
          alert("Not able to send mail. Please try agin later")
          console.log(error);
          comp.setState({
            showLoader: false
          })
        });
    }

 onDescriptionChange = (content) => {
  this.setState({
    body:content
  });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Send Mail" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                    {this.state.showLoader ? 
                                      <Loader 
                                        type="Circles" 
                                        color="#4058b0" 
                                        height={130} 
                                        width={130}
                                        timeout={10000}
                                        style = {{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 2 }}
                                      /> : null }
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">To<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="to"
                                                          placeholder="To"
                                                          type="text"
                                                          className="form-control"
                                                          id="to"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.to}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">CC</Label>
                                                        <Input
                                                          name="cc"
                                                          placeholder="CC"
                                                          type="text"
                                                          className="form-control"
                                                          id="cc"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.cc}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 10}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Subject<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="subject"
                                                          placeholder="Subject"
                                                          type="text"
                                                          className="form-control"
                                                          id="subject"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.subject}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 10}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Body<span style = {{color: "red"}}>*</span></Label>
                                                        <RichTextEditor
                                                          toolbarConfig={toolbarConfig}
                                                          value={this.state.body}
                                                          onChange={this.onDescriptionChange}
                                                          style = {{marginLeft: "4px", zIndex: 0}}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Application Id<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="applicationId"
                                                          placeholder="Application Id"
                                                          type="text"
                                                          className="form-control"
                                                          id="applicationId"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.applicationId}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3, offset: 2}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom05">FI Type<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.fiType} id = "fiType" name = "fiType" onChange = {this.handleChange.bind(this)}>
                                                            <option value = "Select">Select</option>
                                                            <option value = "BV">BV</option>
                                                            <option value = "RV">RV</option>
                                                            <option value = "PV">PV</option>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 1}}>
                                                  <br />
                                                    <button onClick = {this.searchByApplicationId.bind(this)} style = {{marginTop: "5px"}} className="btn btn-outline-secondary" type="button" id="search">
                                                    <FontAwesomeIcon style = {{fontSize: "10px", cursor: "pointer"}} icon={faSearch}/>
                                                    </button>
                                                </Col>

                                                {/* <Col md = {4} className = "input-group">
                                                <input className="form-control" placeholder = "Application ID"  type="applicationId" name="applicationId" id="applicationId" value = {this.state.applicationId}  onChange = {this.handleChange.bind(this)} />
                                                <span className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="search">
                                                    <FontAwesomeIcon style = {{fontSize: "10px", cursor: "pointer"}} icon={faSearch} onClick = {this.searchByApplicationId.bind(this)}/>
                                                    </button>
                                                </span>
                                                </Col> */}
                                                </Row>
                                                <br /> 

                                                <Row>

                                                <Col md = {12}>
                                                <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>Bank</th>
                                                            <th>Product</th>
                                                            <th>Case Type</th>
                                                            <th>TAT Time</th>
                                                            <th>Agent</th>
                                                            <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{rowData.applicationid}</td>
                                                        <td>{rowData.customername}</td>
                                                        <td>{rowData.bank}</td>
                                                        <td>{rowData.productname}</td>
                                                        <td>{rowData.fi}</td>
                                                        <td>{rowData.tattime}</td>
                                                        <td>{rowData.agentname}</td>
                                                        <td>{rowData.status}</td>
                                                     </tr>
                                                ))}                                                
                                                </tbody>
                                            </Table>
                                        </div>
                                                </Col>
                                                </Row>

                                                <br />
                                                <Row>

                                                <Col md={4}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="casePdf" onChange={() => false} checked={this.state.casePdf} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ casePdf: !this.state.casePdf }) }} >Case PDF</label>
                                                        </div>
                                                    </div>

                                                    <div className="mt-4 mt-lg-0">
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="caseImage" onChange={() => false} checked={this.state.caseImage} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ caseImage: !this.state.caseImage }) }} >Case Images</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Send Mail</Button>   
                                                 
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default SendMail;
import React from 'react';
import { Row, Col, Card, CardBody, Table, CardTitle} from "reactstrap";
import GoogleMap from 'google-map-react';
import Stamp from '../../assets/images/stamp.png'


class CasePdf extends React.Component {

    componentDidMount() {
        setTimeout(function() {
            window.print()
        }, 1000)
    }


renderMarkers = (map, maps) => {

    let marker = new maps.Marker({
       position: { lat: parseFloat(this.props.data.markerLatitude), lng: parseFloat(this.props.data.markerLongitude) },
       map,
       title: this.props.data.customerName
    });
    return marker;
 };

    render() {
    return (
      <div>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list" style = {{border: "1px solid black", padding: "10px"}}>
                                        <h3 style = {{textAlign: "center"}}><b>{this.props.data.productName}</b></h3>
                                        <hr style = {{borderBottom: "1px solid black"}} />
                                        <Row>
                                            <Col md = "6">
                                                <h6>Branch: {this.props.data.bank}</h6>
                                            </Col>

                                            <Col md = "6">
                                                <h6>Agency Name: Adsum Advisory Services Private Limited</h6>
                                            </Col>
                                        </Row>
                                        <hr style = {{borderBottom: "1px solid black"}} />
                                        <h3 style = {{textAlign: "center"}}><b>{this.props.data.fi === "BV" ? "BUSINESS " : "RESIDENT "} VERIFICATION REPORT <br/>(Strictly Private & Confidential) </b></h3>
                                        <hr style = {{borderBottom: "1px solid black"}} />
                                        <Table style = {{tableLayout: "fixed", width: "100%"}}>
                                            <tbody style = {{border: "1px solid black", tableLayout: "fixed"}}>
                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Product</td>
                                                    <td colSpan = "3" style = {{border: "1px solid black"}}>{this.props.data.productName}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Date</td>
                                                    <td style = {{border: "1px solid black"}}>{new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')}</td>

                                                    <td style = {{border: "1px solid black"}}>Reference No</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.applicationId}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>FI Date</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.fiDate}</td>

                                                    <td style = {{border: "1px solid black"}}>FI Time</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.fiTime}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>FI Flag</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.fiFlag}</td>

                                                    <td style = {{border: "1px solid black"}}>Date of Birth</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.dob}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Applicant Name</td>
                                                    <td colSpan = "3" style = {{border: "1px solid black"}}>{this.props.data.customerName}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Resident Address</td>
                                                    <td colSpan = "3" style = {{border: "1px solid black"}}>{this.props.data.residentAddresss}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Permanent Address</td>
                                                    <td colSpan = "3" style = {{border: "1px solid black"}}>{this.props.data.permanentAddress}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Mobile</td>
                                                    <td colSpan = "3" style = {{border: "1px solid black"}}>{this.props.data.mobileNumber}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td colSpan = "4" style = {{border: "1px solid black"}}><b>The following information is to be obtained from the applicant or anybody residing at the address </b></td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Loan Amount Rs</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qLoanAmount}</td>

                                                    <td style = {{border: "1px solid black"}}>Addres Confirmed</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qAddressConfirmed}</td>
                                                </tr>

                                                <tr>
                                                    <td style = {{border: "1px solid black"}}>Stay Confirmed</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qStayConfirmed}</td>
                                                </tr>
                                            </tbody>

                                        {this.props.data.fi === "RV" && this.props.data.productName.toLowerCase() === "agriloan" ? <>

                                            <tbody style = {{border: "1px solid black", tableLayout: "fixed"}}>
                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Name of Person Met</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMet}</td>

                                                    <td style = {{border: "1px solid black"}}>Relation With Applicant</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qPersonRelationship}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Stability</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qStability}</td>

                                                    <td style = {{border: "1px solid black"}}>Total Agri Land</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qTotalAgriLand}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Land Details (PATTA No)</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qLandDetails}</td>

                                                    <td style = {{border: "1px solid black"}}>Source of Irrigation</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qIrrigationSource}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Type of Crops</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qCropType}</td>

                                                    <td style = {{border: "1px solid black"}}>Ownership Residence</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceOwnership}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>TFM</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qFamilyMembersCount}</td>

                                                    <td style = {{border: "1px solid black"}}>Earning</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qEarningMembers}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Office Address</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qOfficeAddress}</td>

                                                    <td style = {{border: "1px solid black"}}>Residence Proof Sighted</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceProofId}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Agriculture Lending (If Any)</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qAgricultureLending}</td>

                                                    <td style = {{border: "1px solid black"}}>Interior</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qInterior}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Application Occupation</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qOccupation === "Agriculture" ? this.props.data.qLandDetails : this.props.data.qIncome}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Exterior</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qExterior}</td>

                                                    <td style = {{border: "1px solid black"}}>Neighbour Check1</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qNeighbour1}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Neighbour Check 2</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qNeighbour2}</td>

                                                    <td style = {{border: "1px solid black"}}>CPV Status</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qCpvStatus}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Reject Reason</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qRejectReason}</td>

                                                    <td style = {{border: "1px solid black"}}>Agent Remarks</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qAgentRemarks}</td>
                                                </tr>
                                            </tbody>
                                        </>
                                            
                                            : null }

                                            {this.props.data.fi === "BV" ? <>

                                            <tbody style = {{border: "1px solid black", tableLayout: "fixed"}}>
                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Person Met</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMet}</td>

                                                    <td style = {{border: "1px solid black"}}>Person Met Designation</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMetDesignation}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Name of Co. /Officer</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qCoOffice}</td>

                                                    <td style = {{border: "1px solid black"}}>Applicant Designation</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qPersonDesignation}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Business Address</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.officeAddress}</td>

                                                    <td style = {{border: "1px solid black"}}>Nature of Business</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qNature}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Office Ownership</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qOfficeOwnership}</td>

                                                    <td style = {{border: "1px solid black"}}>Working Since</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qWorkingSince}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Approx Income</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qApproxIncome}</td>

                                                    <td style = {{border: "1px solid black"}}>Number of Employees</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qNumberEmployees}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Stocks</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qStocks}</td>

                                                    <td style = {{border: "1px solid black"}}>Nature of Business Proofs</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qBusinessProof}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Business Activity</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qBusinessActivity}</td>

                                                    <td style = {{border: "1px solid black"}}>Name Mentioned on SignBoard</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qSignBoardText === '' ? 'No' : this.props.data.qSignBoardText}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Proof of Office Seen</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qOfficeSeenText === '' ? 'No' : this.props.data.qOfficeSeenText}</td>

                                                    <td style = {{border: "1px solid black"}}>Vehicle Details</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qVehicleDetails}</td>
                                                </tr>


                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Distance from Branch (in KM)</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qBranchDistance}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Stability</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qStability}</td>

                                                    <td style = {{border: "1px solid black"}}>Customer Sign</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qustomerSignature}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>TPC 1 Name</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qTpc1}</td>

                                                    <td style = {{border: "1px solid black"}}>TPC 1 Check with</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qTpc1Check}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>TPC 2 Name</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qTpc2}</td>

                                                    <td style = {{border: "1px solid black"}}>TPC 2 Check with</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qTpc2Check}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Verifier Feedback</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qCpvStatus}</td>

                                                    <td style = {{border: "1px solid black"}}>Reject Reason</td>
                                                    <td style = {{border: "1px solid black"}}>{this.props.data.qRejectReason}</td>
                                                </tr>

                                                <tr style = {{border: "1px solid black"}}>
                                                    <td style = {{border: "1px solid black"}}>Agent Remrks</td>
                                                    <td colSpan = {3} style = {{border: "1px solid black"}}>{this.props.data.qAgentRemarks}</td>
                                                </tr>
                                            </tbody>

                                            </> : null }

                                            {this.props.data.fi === "RV" && this.props.data.productName.toLowerCase() !== "agriloan" ? <>

                                                <tbody style = {{border: "1px solid black", tableLayout: "fixed"}}>
                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Person Met</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMet}</td>

                                                        <td style = {{border: "1px solid black"}}>Person Met Designation</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMetDesignation}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Relation With Applicant</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonRelationship}</td>

                                                        <td style = {{border: "1px solid black"}}>Stability</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qStability}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Age</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAge}</td>

                                                        <td style = {{border: "1px solid black"}}>Education</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEducation}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Ownership Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceOwnership}</td>

                                                        <td style = {{border: "1px solid black"}}>TFM</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qFamilyMembersCount}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Earning</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEarningMembersCount}</td>

                                                        <td style = {{border: "1px solid black"}}>Spouse Working</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qSpouseWorking}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Earning Members</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEarningMembers}</td>

                                                        <td style = {{border: "1px solid black"}}>NBFC/Bank Name</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qNbfcName}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Loan Amount</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLoanAmount}</td>

                                                        <td style = {{border: "1px solid black"}}>Purpose</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPurpose}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Resident Status</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceStatus}</td>

                                                        <td style = {{border: "1px solid black"}}>Permanent Address</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPermanentAddress}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Contact Person</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qContactPerson}</td>

                                                        <td style = {{border: "1px solid black"}}>Rent Per Month</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qRent}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Verified From</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qVerifiedFrom}</td>

                                                        <td style = {{border: "1px solid black"}}>Area (In Sqft)</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qArea}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Asset Details</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetDetails}</td>

                                                        <td style = {{border: "1px solid black"}}>Asset Applied For</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetAppliedFor}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Asset To be Used By</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetToBeUsedBy}</td>

                                                        <td style = {{border: "1px solid black"}}>Ease of Locate Address</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEaseOfLocate}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Landmark</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLandmark}</td>

                                                        <td style = {{border: "1px solid black"}}>Type of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTypeOfResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Locality of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLocalityOfResidence}</td>

                                                        <td style = {{border: "1px solid black"}}>Area of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAreaofResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Residence Construction</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceConstruction}</td>

                                                        <td style = {{border: "1px solid black"}}>Comment on Exterior</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCommentOnExterior}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Comment on Interior</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCommentOnInterior}</td>

                                                        <td style = {{border: "1px solid black"}}>Asset seen at Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetSeenAtResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Two Wheeler</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheeler}</td>

                                                        <td style = {{border: "1px solid black"}}>Model Name</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheelerModelName}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Year of Manufacture</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheelerYear}</td>

                                                        <td style = {{border: "1px solid black"}}>Car</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCar}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Car Model</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCarModelName}</td>

                                                        <td style = {{border: "1px solid black"}}>Car Manufacturing Year</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCarYear}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Vehicle to be Used By</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qVehicleUsedBy}</td>

                                                        <td style = {{border: "1px solid black"}}>Name of the Person</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonNameVerification}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Applicant Details Confirmed</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qApplicantDetailsConfirmed}</td>

                                                        <td style = {{border: "1px solid black"}}>Negative Feedback (If Any)</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qNegativeFeedback}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Application Occupation</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qOccupation === "Agriculture" ? this.props.data.qLandDetails : this.props.data.qIncome}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Resident Locked During Visit</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceLocked}</td>

                                                        <td style = {{border: "1px solid black"}}>CPV Status</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCpvStatus}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Reject Reason</td>
                                                        <td colSpan = "3" style = {{border: "1px solid black"}}>{this.props.data.qRejectReason}</td>
                                                    </tr>
                                                </tbody>

                                                </> : null }

                                                {this.props.data.fi === "PV" && this.props.data.productName.toLowerCase() !== "agriloan" ? <>

                                                <tbody style = {{border: "1px solid black", tableLayout: "fixed"}}>
                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Person Met</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMet}</td>

                                                        <td style = {{border: "1px solid black"}}>Person Met Designation</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMetDesignation}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Relation With Applicant</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonRelationship}</td>

                                                        <td style = {{border: "1px solid black"}}>Stability</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qStability}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Ownership Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceOwnership}</td>

                                                        <td style = {{border: "1px solid black"}}>TFM</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qFamilyMembersCount}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Earning</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEarningMembersCount}</td>

                                                        <td style = {{border: "1px solid black"}}>Spouse Working</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qSpouseWorking}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Earning Members</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEarningMembers}</td>

                                                        <td style = {{border: "1px solid black"}}>NBFC/Bank Name</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qNbfcName}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Loan Amount</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLoanAmount}</td>

                                                        <td style = {{border: "1px solid black"}}>Purpose</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPurpose}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Resident Status</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceStatus}</td>

                                                        <td style = {{border: "1px solid black"}}>Permanent Address</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPermanentAddress}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Contact Person</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qContactPerson}</td>

                                                        <td style = {{border: "1px solid black"}}>Rent Per Month</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qRent}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Verified From</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qVerifiedFrom}</td>

                                                        <td style = {{border: "1px solid black"}}>Area (In Sqft)</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qArea}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Asset Details</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetDetails}</td>

                                                        <td style = {{border: "1px solid black"}}>Asset Applied For</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetAppliedFor}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Asset To be Used By</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetToBeUsedBy}</td>

                                                        <td style = {{border: "1px solid black"}}>Ease of Locate Address</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEaseOfLocate}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Landmark</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLandmark}</td>

                                                        <td style = {{border: "1px solid black"}}>Type of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTypeOfResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Locality of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLocalityOfResidence}</td>

                                                        <td style = {{border: "1px solid black"}}>Area of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAreaofResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Residence Construction</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceConstruction}</td>

                                                        <td style = {{border: "1px solid black"}}>Comment on Exterior</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCommentOnExterior}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Comment on Interior</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCommentOnInterior}</td>

                                                        <td style = {{border: "1px solid black"}}>Asset seen at Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetSeenAtResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Two Wheeler</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheeler}</td>

                                                        <td style = {{border: "1px solid black"}}>Model Name</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheelerModelName}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Year of Manufacture</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheelerYear}</td>

                                                        <td style = {{border: "1px solid black"}}>Car</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCar}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Car Model</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCarModelName}</td>

                                                        <td style = {{border: "1px solid black"}}>Car Manufacturing Year</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCarYear}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Vehicle to be Used By</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qVehicleUsedBy}</td>

                                                        <td style = {{border: "1px solid black"}}>Name of the Person</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonNameVerification}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Applicant Details Confirmed</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qApplicantDetailsConfirmed}</td>

                                                        <td style = {{border: "1px solid black"}}>Negative Feedback (If Any)</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qNegativeFeedback}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Resident Locked During Visit</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceLocked}</td>

                                                        <td style = {{border: "1px solid black"}}>CPV Status</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCpvStatus}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Reject Reason</td>
                                                        <td colSpan = "3" style = {{border: "1px solid black"}}>{this.props.data.qRejectReason}</td>
                                                    </tr>
                                                </tbody>

                                                </> : null }

                                            {this.props.data.fi !== "PV" && this.props.data.fi !== "BV" && this.props.data.fi !== "RV" ? <>

                                                <tbody style = {{border: "1px solid black", tableLayout: "fixed"}}>
                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Person Met</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMet}</td>

                                                        <td style = {{border: "1px solid black"}}>Person Met Designation</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonMetDesignation}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Relation With Applicant</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonRelationship}</td>

                                                        <td style = {{border: "1px solid black"}}>Stability</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qStability}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Ownership Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceOwnership}</td>

                                                        <td style = {{border: "1px solid black"}}>TFM</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qFamilyMembersCount}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Earning</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEarningMembersCount}</td>

                                                        <td style = {{border: "1px solid black"}}>Spouse Working</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qSpouseWorking}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Earning Members</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEarningMembers}</td>

                                                        <td style = {{border: "1px solid black"}}>NBFC/Bank Name</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qNbfcName}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Loan Amount</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLoanAmount}</td>

                                                        <td style = {{border: "1px solid black"}}>Purpose</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPurpose}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Resident Status</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceStatus}</td>

                                                        <td style = {{border: "1px solid black"}}>Permanent Address</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPermanentAddress}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Contact Person</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qContactPerson}</td>

                                                        <td style = {{border: "1px solid black"}}>Rent Per Month</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qRent}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Verified From</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qVerifiedFrom}</td>

                                                        <td style = {{border: "1px solid black"}}>Area (In Sqft)</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qArea}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Asset Details</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetDetails}</td>

                                                        <td style = {{border: "1px solid black"}}>Asset Applied For</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetAppliedFor}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Asset To be Used By</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetToBeUsedBy}</td>

                                                        <td style = {{border: "1px solid black"}}>Ease of Locate Address</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qEaseOfLocate}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Landmark</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLandmark}</td>

                                                        <td style = {{border: "1px solid black"}}>Type of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTypeOfResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Locality of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qLocalityOfResidence}</td>

                                                        <td style = {{border: "1px solid black"}}>Area of Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAreaofResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Residence Construction</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceConstruction}</td>

                                                        <td style = {{border: "1px solid black"}}>Comment on Exterior</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCommentOnExterior}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Comment on Interior</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCommentOnInterior}</td>

                                                        <td style = {{border: "1px solid black"}}>Asset seen at Residence</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qAssetSeenAtResidence}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Two Wheeler</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheeler}</td>

                                                        <td style = {{border: "1px solid black"}}>Model Name</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheelerModelName}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Year of Manufacture</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qTwoWheelerYear}</td>

                                                        <td style = {{border: "1px solid black"}}>Car</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCar}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Car Model</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCarModelName}</td>

                                                        <td style = {{border: "1px solid black"}}>Car Manufacturing Year</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCarYear}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Vehicle to be Used By</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qVehicleUsedBy}</td>

                                                        <td style = {{border: "1px solid black"}}>Name of the Person</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qPersonNameVerification}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Applicant Details Confirmed</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qApplicantDetailsConfirmed}</td>

                                                        <td style = {{border: "1px solid black"}}>Negative Feedback (If Any)</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qNegativeFeedback}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Resident Locked During Visit</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qResidenceLocked}</td>

                                                        <td style = {{border: "1px solid black"}}>CPV Status</td>
                                                        <td style = {{border: "1px solid black"}}>{this.props.data.qCpvStatus}</td>
                                                    </tr>

                                                    <tr style = {{border: "1px solid black"}}>
                                                        <td style = {{border: "1px solid black"}}>Reject Reason</td>
                                                        <td colSpan = "3" style = {{border: "1px solid black"}}>{this.props.data.qRejectReason}</td>
                                                    </tr>
                                                </tbody>

                                                </> : null }
                                        </Table>


                                            {this.props.data.fi === "RV" && this.props.data.productName.toLowerCase() === "agriloan" ? <>
                                            <br />
                                                <Card>
                                                    <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                                    {this.props.data.qCpvStatus === "Positive" ?
                                                    <CardBody>
                                                        <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Address Confirmation: {this.props.data.qAddressConfirmed} , Name of Person Met: {this.props.data.qPersonMet} , Relation With Applicant: {this.props.data.qPersonRelationship} ,
                                                        Total Agri Land: {this.props.data.qTotalAgriLand} , Land Details (PATTA No): {this.props.data.qLandDetails} , Source of Irrigation: {this.props.data.qIrrigationSource} ,
                                                        Type of Crops: {this.props.data.qCropType} , Ownership Residence: {this.props.data.qResidenceOwnership} , TFM: {this.props.data.qFamilyMembersCount} , Earning: {this.props.data.qEarningMembersCount} ,
                                                        Neighbour Check1: {this.props.data.qNeighbour1} , Agent Remarks: {this.props.data.qAgentRemarks} , Latitude {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                    </CardBody> :
                                                    <CardBody>
                                                        <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Agent Remarks: {this.props.data.qAgentRemarks} , Latitude {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                    </CardBody> }
                                                </Card>

                                                <br />

                                                <Card>
                                                    <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            {/* {this.props.data.photos.map((rowData, index) => (
                                                            <Col md = "2">
                                                                <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "150" height = "150" /></a> <br />
                                                            </Col>
                                                            ))} */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                </> : null }

                                                {this.props.data.fi === "BV" ? <> 
                                                <br />
                                                <Card>
                                                    <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                                    {this.props.data.qCpvStatus === "Positive" ?
                                                    <CardBody>
                                                        <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')}, Person Met: {this.props.data.qPersonMet} , Person Met Designation: {this.props.data.qPersonMetDesignation} , Name of Co. /Officer: {this.props.data.qCoOffice}  
                                                        Address Confirmation: {this.props.data.qAddressConfirmed} , Applicant Designation: {this.props.data.qPersonDesignation}, Nature of Business: {this.props.data.qNature}
                                                        Office Ownership: {this.props.data.qOfficeOwnership} , Working Since: {this.props.data.qWorkingSince} , Number of Employees: {this.props.data.qNumberEmployees} , Stocks: {this.props.data.qStocks}
                                                        , Business Activity: {this.props.data.qBusinessActivity} , Name Mentioned on SignBoard: {this.props.data.qSignBoardText} ,
                                                        TPC 1 Name: {this.props.data.qTpc1} ,
                                                        TPC 2 Name: {this.props.data.qTpc2} , Agent Remarks: {this.props.data.qAgentRemarks} , Latitude: {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                    </CardBody> :
                                                    <CardBody>
                                                        <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')}, Agent Remarks: {this.props.data.qAgentRemarks} , Latitude: {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                    </CardBody> }
                                                </Card>
                                                <br />

                                                <Card>
                                                        <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                                        <CardBody>
                                                            <Row>
                                                            {/* {this.props.data.photos.map((rowData, index) => (
                                                            <Col md = "2">
                                                                <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "150" height = "150" /></a> <br />
                                                            </Col>
                                                            ))} */}
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                            </> : null }

                                            {this.props.data.fi === "RV" && this.props.data.productName.toLowerCase() !== "agriloan" ? <>
                                            <br />

                                                <Card>
                                                    <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                                    {this.props.data.qCpvStatus === "Positive" ?
                                                    <CardBody>
                                                    <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Address Confirmation: {this.props.data.qAddressConfirmed} , Name of Person Met: {this.props.data.qPersonMet} , Relation With Applicant: {this.props.data.qPersonRelationship} , 
                                                        Ownership Residence: {this.props.data.qResidenceOwnership} , Age: {this.props.data.qAge} ,  Education: {this.props.data.qEducation} , TFM: {this.props.data.qFamilyMembersCount} , Earning: {this.props.data.qEarningMembersCount} , 
                                                        Rent Per Month: {this.props.data.qRent} , Area (In Sqft): {this.props.data.qArea} , Landmark: {this.props.data.qLandmark} , Type of Residence: {this.props.data.qTypeOfResidence} ,  Locality of Residence: {this.props.data.qLocalityOfResidence} , 
                                                        Residence Construction: {this.props.data.qResidenceConstruction} , Name of the Person: {this.props.data.qPersonNameVerification} ,
                                                        Applicant Details Confirmed: {this.props.data.qApplicantDetailsConfirmed} , Agent Remarks: {this.props.data.qAgentRemarks} , Latitude {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                    </CardBody> :
                                                    <CardBody>
                                                        <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Landmark: {this.props.data.qLandmark}, Agent Remarks: {this.props.data.qAgentRemarks} , Latitude {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                    </CardBody> }
                                                </Card>

                                                <br />


                                                <Card>
                                                    <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                            {/* {this.props.data.photos.map((rowData, index) => (
                                                            <Col md = "2">
                                                            <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "150" height = "150" /></a> <br />
                                                            </Col>
                                                            ))} */}
                                                        </Row>
                                                    </CardBody>
                                                </Card> 
                                            </> : null }

                                            {this.props.data.fi === "PV" && this.props.data.productName.toLowerCase() !== "agriloan" ? <>
                                            <br />

                                            <Card>
                                                <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                                {this.props.data.qCpvStatus === "Positive" ?
                                                    <CardBody>
                                                        <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Address Confirmation: {this.props.data.qAddressConfirmed} , Name of Person Met: {this.props.data.qPersonMet} , Relation With Applicant: {this.props.data.qPersonRelationship} , 
                                                        Ownership Residence: {this.props.data.qResidenceOwnership} , TFM: {this.props.data.qFamilyMembersCount} , Earning: {this.props.data.qEarningMembersCount} , 
                                                        Rent Per Month: {this.props.data.qRent} , Area (In Sqft): {this.props.data.qArea} , Landmark: {this.props.data.qLandmark} , Type of Residence: {this.props.data.qTypeOfResidence} ,  Locality of Residence: {this.props.data.qLocalityOfResidence} , 
                                                        Residence Construction: {this.props.data.qResidenceConstruction} , Name of the Person: {this.props.data.qPersonNameVerification} ,
                                                        Applicant Details Confirmed: {this.props.data.qApplicantDetailsConfirmed} , Agent Remarks: {this.props.data.qAgentRemarks} , Latitude {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                    </CardBody> :
                                                    <CardBody>
                                                       <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Landmark: {this.props.data.qLandmark}, Agent Remarks: {this.props.data.qAgentRemarks} , Latitude {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                   </CardBody> }
                                            </Card>
                                            <br />

                                            <Card>
                                                    <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                        {/* {this.props.data.photos.map((rowData, index) => (
                                                        <Col md = "2">
                                                            <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "150" height = "150" /></a> <br />
                                                        </Col>
                                                        ))} */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            
                                            </> : null }

                                            {this.props.data.fi !== "PV" && this.props.data.fi !== "BV" && this.props.data.fi !== "RV" ? <>
                                            <br />

                                            <Card>
                                                <CardTitle style = {{padding: "10px"}}>Case Summary</CardTitle>
                                                {this.props.data.qCpvStatus === "Positive" ?
                                                <CardBody>
                                                <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Address Confirmation: {this.props.data.qAddressConfirmed} , Name of Person Met: {this.props.data.qPersonMet} , Relation With Applicant: {this.props.data.qPersonRelationship} , 
                                                    Ownership Residence: {this.props.data.qResidenceOwnership} , TFM: {this.props.data.qFamilyMembersCount} , Earning: {this.props.data.qEarningMembersCount} , 
                                                    Rent Per Month: {this.props.data.qRent} , Area (In Sqft): {this.props.data.qArea} , Landmark: {this.props.data.qLandmark} , Type of Residence: {this.props.data.qTypeOfResidence} ,  Locality of Residence: {this.props.data.qLocalityOfResidence} , 
                                                    Residence Construction: {this.props.data.qResidenceConstruction} , Name of the Person: {this.props.data.qPersonNameVerification} ,
                                                    Applicant Details Confirmed: {this.props.data.qApplicantDetailsConfirmed} , Agent Remarks: {this.props.data.qAgentRemarks} , Latitude {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                </CardBody> :
                                                <CardBody>
                                                    <p>Visit Date and Time: {new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')} , Landmark: {this.props.data.qLandmark}, Agent Remarks: {this.props.data.qAgentRemarks} , Latitude {this.props.data.markerLatitude} , Longitude: {this.props.data.markerLongitude}</p>
                                                </CardBody> }
                                            </Card>
                                            <br />

                                            <Card>
                                                    <CardTitle style = {{padding: "10px"}}>Applicant Photos</CardTitle>
                                                    <CardBody>
                                                        <Row>
                                                        {/* {this.props.data.photos.map((rowData, index) => (
                                                        <Col md = "2">
                                                            <a href = {rowData.Photo} target = "_blank"><img src  = {rowData.Photo} alt = "Applicant" width = "150" height = "150" /></a> <br />
                                                        </Col>
                                                        ))} */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            
                                            </> : null }

                                            <Card className="w-full h-512 rounded-8 shadow-1">
                                                <br />
                                                <Table style = {{tableLayout: "fixed", width: "100%"}}>
                                                    <tbody style = {{border: "1px solid black", tableLayout: "fixed"}}>
                                                        <tr style = {{border: "1px solid black"}}>
                                                            <td style = {{border: "1px solid black"}}>Completed Date Time</td>
                                                            <td  colSpan = {3} style = {{border: "1px solid black"}}>{new Date(this.props.data.submittedAt).toUTCString().split(' ').slice(0, 4).join(' ')}</td>

                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <img src = {Stamp} height = "100" width = "100" /> <br />
                                            </Card>



                                            {this.props.data.markerLatitude !== '' && this.props.data.markerLongitude !== '' ?
                                                <Card className="w-full h-512 rounded-8 shadow-1">
                                                <br />
                                                <Table style = {{tableLayout: "fixed", width: "100%"}}>
                                                    <tbody style = {{border: "1px solid black", tableLayout: "fixed"}}>
                                                        <tr style = {{border: "1px solid black"}}>
                                                            <td style = {{border: "1px solid black"}}>Latitude</td>
                                                            <td style = {{border: "1px solid black"}}>{this.props.data.markerLatitude}</td>

                                                            <td style = {{border: "1px solid black"}}>Longitude</td>
                                                            <td style = {{border: "1px solid black"}}>{this.props.data.markerLongitude}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>

                                                {this.props.data.markerLatitude.toString().length > 3 && this.props.data.markerLatitude.toString().length > 3   ?

                                                <div style={{height: `400px`, width: '100%'}}>
                                                <GoogleMap
                                                bootstrapURLKeys={{
                                                    key: "AIzaSyBJzptPqnoVkUgJfL9x6kBnruQj_3VPQcA"
                                                }}
                                                defaultZoom={8}
                                                defaultCenter={[this.props.data.markerLatitude, this.props.data.markerLongitude]}
                                                yesIWantToUseGoogleMapApiInternals
                                                onGoogleApiLoaded={({ map, maps }) => {this.renderMarkers(map, maps)}}
                                                >
                                                </GoogleMap>
                                                </div> : null }
                                            </Card> : null }


                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
      </div>
  );
}
}
export default CasePdf;
import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import {ExcelRenderer} from 'react-excel-renderer';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


class Import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firestoreData: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      firebaseData1: [],
      cols: [],
      rows: [],
      loadIds: []
    };
  }

  componentDidMount() {
    let newData1 = []
    const del1 = {
      ApplicationId: "",
      Customer_Name: "",
      FI_To_Be_Conducted: "",
      Product_Name: "",
      Residence_Address: "", 
      Office_Address: "",
      Permanent_Address: "",
      FI_Date: "",
      FI_Time: "",
      FI_Flag: "",
      Date_Of_Birth: "",
      Loan_Amount: "",
      Agent_Name: ""
  }
  newData1.push(del1)

  this.setState({
    firebaseData1: newData1
  })

  let comp = this
  firebase.firestore().collection("Case")
  .onSnapshot(function(querySnapshot) {
      var loanIds = [];
      querySnapshot.forEach(function(doc) {
          loanIds.push(doc.id)
        })
      comp.setState({
        loanIds: loanIds
      })
  });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let data = this.state
    let comp = this

    // console.log(data.rows)
  
    var myTab = document.getElementById('excelTable');
  
    if(myTab.rows.length === 0){
      alert("Upload Excel Sheet");
      return;
  }
  
    for (let i = 0; i < myTab.rows.length; i++) {
      var objCells = myTab.rows.item(i).cells;

      if(objCells.item(0).innerHTML !== '') {

      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  
  
     firebase.firestore().collection("Case").doc((objCells.item(0).innerHTML).replace('&nbsp;', '').trim()).set({
        ApplicationId: (objCells.item(0).innerHTML).replace('&nbsp;', ''),
        CustomerName: (objCells.item(1).innerHTML).replace('&nbsp;', ''),
        FI: (objCells.item(2).innerHTML).replace('&nbsp;', ''),
        ProductName: (objCells.item(3).innerHTML).replace('&nbsp;', ''),
        ResidenceAddress: (objCells.item(4).innerHTML).replace('&nbsp;', ''),
        OfficeAddress: (objCells.item(5).innerHTML).replace('&nbsp;', ''),
        PermanentAddress: (objCells.item(6).innerHTML).replace('&nbsp;', ''),
        FIDate: (objCells.item(7).innerHTML).replace('&nbsp;', ''),
        FITime: (objCells.item(8).innerHTML).replace('&nbsp;', ''),
        FIFlag: (objCells.item(9).innerHTML).replace('&nbsp;', ''),
        DateOfBirth: (objCells.item(10).innerHTML).replace('&nbsp;', ''),
        LoanAmount: (objCells.item(11).innerHTML).replace('&nbsp;', ''),
        AgentName: (objCells.item(12).innerHTML).replace('&nbsp;', ''),
        TimeStamp: timestamp,
        Status: "Pending"
      })
      .then(function(docRef) {
        comp.setState({
          rows: [],
          cols: [] 
      })
  
      document.getElementById("import").value = ""
    })
  }
}

alert('Data Added Sucessfully!');

    
}

excelUpload = event =>{

  let fileObj = event.target.files[0];

//just pass the fileObj as parameter
ExcelRenderer(fileObj, (err, resp) => {
  if(err){
    console.log(err);            
  }
  else{
    this.setState({
      cols: resp.cols,
      rows: resp.rows
    });
  }
})       
}

exportToCSV(csvData, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}

  render() {
    // {console.log(this.state.rows)}
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Import" breadcrumbItem="Import" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md = "4">
                                                    <FormGroup>
                                                    <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData1, 'Loan Application Report')} type="submit">Excel</Button>
                                                    {/* <ReactHTMLTableToExcel  
                                                        className="btn btn-primary"  
                                                        table="dataTable"  
                                                        filename= {this.state.category + " Report"}  
                                                        sheet="Sheet"  
                                                        buttonText="Download" />   */}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                              <Row>
                                                <Col md = "4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Import<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="import"
                                                          placeholder="Model Name"
                                                          type="file"
                                                          className="form-control"
                                                          id="import"
                                                          onChange = {this.excelUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <div className="table-responsive">
                                            <Table className="table mb-0 table-striped table-bordered" style = {{display: "none"}}>
                                                <thead>
                                                    <tr>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>FI To Be Conducted</th>
                                                            <th>Product Name</th>
                                                            <th>Residence Address</th>
                                                            <th>Office Address</th>
                                                            <th>Permanent Address</th>
                                                            <th>FI Date</th>
                                                            <th>FI Time</th>
                                                            <th>FI Flag</th>
                                                            <th>Date Of Birth</th>
                                                            <th>Loan amount</th>
                                                            <th>Agent name</th>
                                                    </tr>
                                                </thead>
                                                <tbody id = "excelTable">
                                                {this.state.rows.slice(1, this.state.rows.length).map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{rowData[0]}</td>
                                                        <td>{rowData[1]}</td>
                                                        <td>{rowData[2]}</td>
                                                        <td>{rowData[3]}</td>
                                                        <td>{rowData[4]}</td>
                                                        <td>{rowData[5]}</td>
                                                        <td>{rowData[6]}</td>
                                                        <td>{rowData[7]}</td>
                                                        <td>{rowData[8]}</td>
                                                        <td>{rowData[9]}</td>
                                                        <td>{rowData[10]}</td>
                                                        <td>{rowData[11]}</td>
                                                        <td>{rowData[12]}</td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>
                                              </Form>
                                            <Button color="primary" onClick = {this.handleSubmit.bind(this)}  type="submit">Submit</Button>
                                            {this.state.success_msg ? (
											<SweetAlert
												title="Good job!"
												success
												confirmBtnBsStyle="success"
												onConfirm={() => this.setState({ success_msg: false })}
											>
												Successfully Created!
											</SweetAlert>
										) : null}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Import;
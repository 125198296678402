import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import {ExcelRenderer} from 'react-excel-renderer';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Loader from "react-loader-spinner";
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";


class UploadCase extends Component {
  constructor(props) {
    super(props);
    this.fetchList = this.fetchList.bind(this);
    this.submit = this.handleSubmit.bind(this);
    this.funcMap = {
        '1': this.fetchList,
        '2': this.submit,
    };
    this.state = {
      firestoreData: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      firebaseData1: [],
      cols: [],
      rows: [],
      loadIds: [],
      applicationIds: [],

      bank: '',
      product: '',

      banks: [],
      products: [],
      loadAgentNames: [],
      loadAgentIds: [],
      loadAgentUserIds: []
    };
  }

  componentDidMount() {
    let comp = this

    var config = {
      method: 'get',
      url: API_BASE_URL + '/auth/bank',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config)
    .then(function (response) {
      comp.setState({
        banks: response.data
      })
    })
    .catch(function (error) {
      console.log(error);
      comp.handleNon200Response(error, "1");
    });

    var config1 = {
      method: 'get',
      url: API_BASE_URL + '/auth/loanproduct',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };
    
    axios(config1)
    .then(function (response1) {
      comp.setState({
        products: response1.data
      })
    })
    .catch(function (error1) {
      console.log(error1);
      // comp.handleNon200Response(error1, "1");
    });

    var config2 = {
      method: 'get',
      url: API_BASE_URL + '/auth/approvedAgents',
      headers: { 
        apikey: API_KEY, 
        Authorization: "Bearer " + getCookie("at"),
      }
    };

    let agSelect = []
    let agentName = []
    let agentId = []
    let agentUserId = []
    
    axios(config2)
    .then(function (response2) {
      if(response2.status == 200) {
        for(let i=0; i< response2.data.length; i++) {
          if(response2.data[i].agentid !== "") {
            agentName.push(response2.data[i].name)
            agentId.push(response2.data[i].agentid)
            agentUserId.push(response2.data[i]._id)
          }
        }
        comp.setState({
          loadAgentUserIds: agentUserId,
          loadAgentNames: agentName,
          loadAgentIds: agentId
        })
      }
    })
    .catch(function (error2) {
      console.log(error2);
      // comp.handleNon200Response(error2, "1");
    });

    // firebase.firestore().collection("Agents")
    // .where("ApprovalStatus", "==", "Approved")
    // .onSnapshot(function(querySnapshot) {
    //   let agentName = []
    //   let agentId = []
    //   let agentUserId = []
    //     querySnapshot.forEach(function(doc) {
    //       if(doc.data().AgentId !== undefined) {
    //         agentName.push(doc.data().Name)
    //         agentId.push(doc.data().AgentId)
    //         agentUserId.push(doc.data().UserId)
    //       }
    //     });
    //     comp.setState({
    //       loadAgentUserIds: agentUserId,
    //       loadAgentNames: agentName,
    //       loadAgentIds: agentId
    //     })
    // })

    let newData1 = []
    const del1 = {
      ApplicationId: "",
      Customer_Name: "",
      FI_To_Be_Conducted: "",
      Product_Name: "",
      Residence_Address: "", 
      Office_Address: "",
      Permanent_Address: "",
      FI_Date: "",
      FI_Time: "",
      FI_Flag: "",
      Date_Of_Birth: "",
      Loan_Amount: "",
      Agent_Id: ""
  }
  newData1.push(del1)

  this.setState({
    firebaseData1: newData1
  })
  setTimeout(function() {
    comp.fetchList();
  }, 2000)
  }

  async fetchList() {
    let comp = this

    // var config = {
    //   method: 'get',
    //   url: API_BASE_URL + '/auth/case',
    //   headers: { 
    //     apikey: API_KEY, 
    //     Authorization: "Bearer " + getCookie("at"),
    //   }
    // };

    // let applicationIds = []
    
    // axios(config)
    // .then(function (response) {
    //   for(let i=0; i < response.data.length; i++) {
    //     if(response.data[i].applicationid !== "") {
    //         applicationIds.push(response.data[i].applicationid)
    //     } 
    //   }
    //   comp.setState({
    //     applicationIds: applicationIds,
    //   })
    // })
    // .catch(function (error) {
    //   console.log(error);
    //   // comp.handleNon200Response(error, "1");
    // });
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleNon200Response = (error, functionIndex) => {
    if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
      sessionExpired();
    } 
  }

  async handleSubmit(e) {
    e.preventDefault();
    let data = this.state
    let comp = this

    let productName = []
    let agentName = []
    let agentId = []
    let agentUserId = []

    for(let i=0; i< this.state.products.length; i++) {
        productName.push(this.state.products[i].name)
    }

    // firebase.firestore().collection("LoanProduct").orderBy("Name")
    // .onSnapshot(function(querySnapshot) {
    //     querySnapshot.forEach(function(doc) {
    //         productName.push(doc.data().Name)
    //     });

    // console.log(data.rows)

    if(data.bank === '') {
      alert("Select Bank");
      document.getElementById("bank").focus();
      return;
    }

    // if(data.product === '') {
    //   alert("Select Product");
    //   document.getElementById("product").focus();
    //   return;
    // }
  
    var myTab = document.getElementById('excelTable');
  
    if(myTab.rows.length === 0){
      alert("Upload Excel Sheet");
      return;
  }

  var today = new Date()

  let trigger = 0

  var state = localStorage.getItem("state")
  if(state === null) {
      state = sessionStorage.getItem("state")
  }

  let st = []

  st.push("SUPER ADMIN")

  let stat = state.split(",").slice(0, -1)
  for(let i=0; i< stat.length; i++) {
    st.push(stat[i])
  }
  
    for (let i = 0; i < myTab.rows.length; i++) {
      var objCells = myTab.rows.item(i).cells;

      if(objCells.item(0).innerHTML !== '') {

      const timestamp = firebase.firestore.FieldValue.serverTimestamp();

      var state = localStorage.getItem("state")
      if(state === null) {
          state = sessionStorage.getItem("state")
      }

      let applicationIdExists = false
      
  
      let jsonDataApplicationId = {
        applicationId: objCells.item(0).innerHTML.trim(),
        state: state.split(",").slice(0, -1),
      }
    
      let jsonDataApplicationId1 = JSON.stringify(jsonDataApplicationId);
    
      var config = {
        method: 'post',
        url: API_BASE_URL + '/auth/case/searchByApplicationId',
        headers: { 
          apikey: API_KEY, 
          Authorization: "Bearer " + getCookie("at"),
          'Content-Type': 'application/json'
        },
        data: jsonDataApplicationId1
      };
      
      await axios(config)
      .then(function (response) {
          console.log(response.data)
          if(response.status == 200) {
            console.log("response.data", response.data)
            if(response.data.length > 0) {
              alert("Application Id already exists: " + objCells.item(0).innerHTML)
              applicationIdExists = true
            }
          }
      })

      // if(data.applicationIds.indexOf(objCells.item(0).innerHTML.trim()) !== -1) {
      //   alert("Application Id already exists: " + objCells.item(0).innerHTML)
      //   trigger = 1
      //   break;
      // }

      if(applicationIdExists) {
        trigger = 1;
        break;
      }


      let pushid = ""

      if(productName.indexOf(objCells.item(3).innerHTML) === -1) {
        alert("Product Name is Invalid. Please Enter Correct Product Name With Case Matching: " + objCells.item(3).innerHTML + " Application Id " + objCells.item(0).innerHTML)
        trigger = 1
        break;
      }

      if(data.loadAgentIds.indexOf(objCells.item(15).innerHTML) === -1) {
          alert("Agent Id is Invalid. Please Enter Correct Agent Id With Case Matching: "  + objCells.item(15).innerHTML + " Application Id: " + objCells.item(0).innerHTML)
          trigger = 1
          break;
      }

      var jsonData3 = JSON.stringify({
        "bank": data.bank,
        "productname": (objCells.item(3).innerHTML),
        "applicationid": (objCells.item(0).innerHTML).replace('&nbsp;', '').trim(),
        "customername": (objCells.item(1).innerHTML).replace('&nbsp;', ''),
        "loanamount": (objCells.item(12).innerHTML).replace('&nbsp;', ''),
        "mobilenumber": (objCells.item(2).innerHTML).replace('&nbsp;', ''),
        "hp": "NO",
        "vehicle": '',
        "coapplicantname": '',
        "guranteename": '',
        "agentname": (data.loadAgentNames[data.loadAgentIds.indexOf(objCells.item(15).innerHTML)]),
        "agent": (data.loadAgentUserIds[data.loadAgentIds.indexOf(objCells.item(15).innerHTML)]),
        "agentid": (objCells.item(15).innerHTML),
        "geolimit": (objCells.item(13).innerHTML).replace('&nbsp;', ''),
        "tattime": (objCells.item(14).innerHTML).replace('&nbsp;', ''),
        "remarks": '',
        "fi": (objCells.item(4).innerHTML).replace('&nbsp;', ''),
        "residenceaddress": (objCells.item(5).innerHTML).replace('&nbsp;', ''),
        "officeaddress":  (objCells.item(6).innerHTML).replace('&nbsp;', ''),
        "permanentaddress": (objCells.item(7).innerHTML).replace('&nbsp;', ''),
        "location": "",
        "fidate": (objCells.item(8).innerHTML).replace('&nbsp;', ''),
        "fitime": (objCells.item(9).innerHTML).replace('&nbsp;', ''),
        "fiflag":  (objCells.item(10).innerHTML).replace('&nbsp;', ''),
        "dateofbirth": (objCells.item(11).innerHTML).replace('&nbsp;', ''),
        "timestamp": String(new Date()),
        "status": "Pending",
        "paid": "No",
        "state": st,
        "pushid": Date.now()
      });
  
      var config3 = {
        method: 'post',
        url: API_BASE_URL + "/auth/case",
        headers: { 
          'apikey': API_KEY, 
          'Authorization': 'Bearer ' + getCookie('at'),
          'Content-Type': 'application/json'
        },
        data : jsonData3
      };
      
      axios(config3)
      .then(function (response3) {
        if(response3.status == 201) {
          // comp.defaultState()
          // comp.fetchList()
          // alert("Added Successfully")
        }
      })
      .catch(function (error3) {
        trigger = 1;
        console.log(error3.response.data)
        comp.handleNon200Response(error3, "2")
      }); 


  
  
    //  firebase.firestore().collection("Case").add({
    //     Bank: data.bank,
    //     ProductName: (objCells.item(3).innerHTML),
    //     HP: "NO",
    //     CreatedAt: String(today),
    //     ApplicationId: (objCells.item(0).innerHTML).replace('&nbsp;', '').trim(),
    //     CustomerName: (objCells.item(1).innerHTML).replace('&nbsp;', ''),
    //     MobileNumber: (objCells.item(2).innerHTML).replace('&nbsp;', ''),
    //     LoanAmount: (objCells.item(12).innerHTML).replace('&nbsp;', ''),
    //     Vehicle: '',
    //     CoApplicantName: '',
    //     GuranteeName: '',
    //     AgentName: (data.loadAgentNames[data.loadAgentIds.indexOf(objCells.item(15).innerHTML)]),
    //     Agent: (data.loadAgentUserIds[data.loadAgentIds.indexOf(objCells.item(15).innerHTML)]),
    //     AgentId: (objCells.item(15).innerHTML),
    //     GeoLimit: (objCells.item(13).innerHTML).replace('&nbsp;', ''),
    //     TATTime: (objCells.item(14).innerHTML).replace('&nbsp;', ''),
    //     Remarks: "",
    //     FI: (objCells.item(4).innerHTML).replace('&nbsp;', ''), 
    //     ResidenceAddress: (objCells.item(5).innerHTML).replace('&nbsp;', ''),
    //     OfficeAddress: (objCells.item(6).innerHTML).replace('&nbsp;', ''),
    //     PermanentAddress: (objCells.item(7).innerHTML).replace('&nbsp;', ''),
    //     FIDate: (objCells.item(8).innerHTML).replace('&nbsp;', ''),
    //     FITime: (objCells.item(9).innerHTML).replace('&nbsp;', ''),
    //     FIFlag:  (objCells.item(10).innerHTML).replace('&nbsp;', ''),
    //     DateOfBirth: (objCells.item(11).innerHTML).replace('&nbsp;', ''),
    //     TimeStamp: timestamp,
    //     Status: "Pending",
    //     Paid: "No",
    //     State: st
    //   })
    //   .then(function(docRef) {
    //     pushid = docRef.id

    //     console.log(pushid)
  
    //     firebase.firestore().collection('Case').doc(pushid).update({
    //         PushId: pushid
    //     })

    //     firebase.firestore().collection('CaseIdStore').doc(pushid).set({
    //       PushId: pushid,
    //       ApplicationId: (objCells.item(0).innerHTML).replace('&nbsp;', '').trim(),
    //     })
      //   comp.setState({
      //     rows: [],
      //     cols: [],
      //     bank: '',
      //     product: ''
      // })
  
      // document.getElementById("import").value = ""
  }
}

if(trigger === 0) {
  alert('Data Added Sucessfully!');
}


}

excelUpload = event =>{

  let fileObj = event.target.files[0];

//just pass the fileObj as parameter
ExcelRenderer(fileObj, (err, resp) => {
  if(err){
    console.log(err);            
  }
  else{
    this.setState({
      cols: resp.cols,
      rows: resp.rows
    });
  }
})       
}

exportToCSV(csvData, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}

  render() {
    // {console.log(this.state.rows)}
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Case" breadcrumbItem="Upload Case" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                    {/* {this.state.applicationIds.length == 0 ? 
                                      <Loader 
                                        type="Circles" 
                                        color="#4058b0" 
                                        height={130} 
                                        width={130}
                                        timeout={10000}
                                        style = {{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 2 }}
                                      /> : null } */}
                                      <Form id = "create-form">
                                          <Row>
                                              <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Bank<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.bank} id = "bank" name = "bank" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Bank</option>
                                                          {this.state.banks.map((rowData, index) => (
                                                            <option value = {rowData.name}>{rowData.name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                {/* <Row>
                                                <Col md = {{size: 6}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Products<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select"  value = {this.state.product} id = "product" name = "product" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "">Select Product</option>
                                                          {this.state.products.map((rowData, index) => (
                                                            <option value = {rowData.Name}>{rowData.Name}</option>
                                                          ))}
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                                </Row> */}
                                              <Row>
                                                <Col md = "6">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Import<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="import"
                                                          placeholder="Model Name"
                                                          type="file"
                                                          className="form-control"
                                                          id="import"
                                                          onChange = {this.excelUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                              </Row>

                                                <div className="table-responsive">
                                            <Table className="table mb-0 table-striped table-bordered" style = {{display: "none"}}>
                                                <thead>
                                                    <tr>
                                                            <th>Application Id</th>
                                                            <th>Customer Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Product Name</th>
                                                            <th>FI To Be Conducted</th>
                                                            <th>Residence Address</th>
                                                            <th>Office Address</th>
                                                            <th>Permanent Address</th>
                                                            <th>FI Date</th>
                                                            <th>FI Time</th>
                                                            <th>FI Flag</th>
                                                            <th>Date Of Birth</th>
                                                            <th>Loan amount</th>
                                                            <th>Geo Limit</th>
                                                            <th>TAT</th>
                                                            <th>Agent ID</th>
                                                    </tr>
                                                </thead>
                                                <tbody id = "excelTable">
                                                {this.state.rows.slice(1, this.state.rows.length).map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{rowData[0]}</td>
                                                        <td>{rowData[1]}</td>
                                                        <td>{rowData[2]}</td>
                                                        <td>{rowData[3]}</td>
                                                        <td>{rowData[4]}</td>
                                                        <td>{rowData[5]}</td>
                                                        <td>{rowData[6]}</td>
                                                        <td>{rowData[7]}</td>
                                                        <td>{rowData[8]}</td>
                                                        <td>{rowData[9]}</td>
                                                        <td>{rowData[10]}</td>
                                                        <td>{rowData[11]}</td>
                                                        <td>{rowData[12]}</td>
                                                        <td>{rowData[13]}</td>
                                                        <td>{rowData[14]}</td>
                                                        <td>{rowData[15]}</td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>
                                              </Form>
                                            <Button color="primary" onClick = {this.handleSubmit.bind(this)}  type="submit">Submit</Button>
                                            {this.state.success_msg ? (
											<SweetAlert
												title="Good job!"
												success
												confirmBtnBsStyle="success"
												onConfirm={() => this.setState({ success_msg: false })}
											>
												Successfully Created!
											</SweetAlert>
										) : null}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default UploadCase;

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table, Row, Col, Card, CardBody } from "reactstrap";
import Button from "reactstrap/lib/Button";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'

class WorkInProgressReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firebaseData: [],
            profile: false,
            refs: []
        };

    }

    componentDidMount() {
        // let comp = this
        // firebase.firestore().collection("Users")
        // .orderBy('UserId')
        // .onSnapshot(function(querySnapshot) {
        //     var cities = [];
        //     querySnapshot.forEach(function(doc) {

        //         var superstar = ""
        //         if(doc.data().SuperStar !== undefined) {
        //             superstar = doc.data().SuperStar
        //         } else {
        //             superstar = ""
        //         }
        //         const newData = {
        //           Name: doc.data().Name,
        //           MobileNumber:doc.data().MobileNumber,
        //           UserId: doc.id,
        //           DateofBirth: doc.data().Dob,
        //           Followers: doc.data().Followers,
        //           Following: doc.data().Following,
        //           Subscription: doc.data().Subscription,
        //           Referal: doc.data().Referal,
        //           Status: doc.data().Status,
        //           ReferralCount: doc.data().ReferralCount,
        //           SuperStar: superstar
        //         }
        //         cities.push(newData)
        //     });
        //     comp.setState({
        //       firebaseData: cities,
        //     })
        // });
    }

    moveToProfile(key) {
        sessionStorage.setItem("userId",key.UserId)
        this.setState({
            profile: true
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.profile ? <Redirect to  = "/user-profile" /> : null }
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Reports" breadcrumbItem="Work In Progress Report" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>User Id</th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Date of Birth</th>
                                                            <th>Email</th>
                                                    </tr>
                                                </thead>
                                                {/* <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.UserId}</td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.MobileNumber}</td>
                                                        <td>{rowData.DateofBirth}</td>
                                                        <td>{rowData.Followers}</td>
                                                        <td>{rowData.Following}</td>
                                                        <td>{rowData.Subscription}</td>
                                                        {rowData.SuperStar === "Yes" ?
                                                        <td>{rowData.SuperStar} <br /><br /><Button onClick = {this.superstarNo.bind(this, rowData)} color = "danger">No</Button></td> :
                                                        <td>{rowData.SuperStar} <br /><br /><Button onClick = {this.superstarYes.bind(this, rowData)} color = "success">Yes</Button></td> }
                                                        <td>{rowData.Referal} <br /> <br /><span style = {{color: "#ff0d00", cursor: "pointer"}} onClick = {this.referralOpen.bind(this, rowData)}><b>{rowData.ReferralCount}</b></span></td>
                                                        {rowData.Status === undefined || rowData.Status === "InActive" ?
                                                         <td><Button onClick = {this.unBlockUser.bind(this, rowData)} color = "success">UnBlock</Button></td> :
                                                         <td><Button onClick = {this.blockUser.bind(this, rowData)} color = "danger">Block</Button></td> }
                                                        <td><Button onClick = {this.moveToProfile.bind(this, rowData)} color = "primary">View Profile</Button></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody> */}
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default WorkInProgressReport;

import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import {ExcelRenderer} from 'react-excel-renderer';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


class DownloadTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firestoreData: [],
      videoName: '',
      menu: 'Select',
      success_msg: false,
      firebaseData1: [],
      cols: [],
      rows: [],
      loadIds: []
    };
  }

  componentDidMount() {
    let newData1 = []
    const del1 = {
      ApplicationId: "",
      Customer_Name: "",
      Mobile_Number: "",
      Product_Name: "",
      FI_To_Be_Conducted: "",
      Residence_Address: "", 
      Office_Address: "",
      Permanent_Address: "",
      FI_Date: "",
      FI_Time: "",
      FI_Flag: "",
      Date_Of_Birth: "",
      Loan_Amount: "",
      Geo_Limit: "",
      TAT: "",
      Agent_ID: ""
  }
  newData1.push(del1)

  this.setState({
    firebaseData1: newData1
  })
}

exportToCSV(csvData, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Case" breadcrumbItem="Download FI Template" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md = "12">
                                                    <FormGroup>
                                                    <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData1, 'FI template')} type="submit">Download FI Template</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                              </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default DownloadTemplate;
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Table, Row, Col, Card, CardBody, FormGroup, Label, Input } from "reactstrap";
import Button from "reactstrap/lib/Button";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TablePagination } from '@material-ui/core';
import axios from "axios";
import { API_BASE_URL, API_KEY, getCookie, renewAccessToken, setCookie, handleRenewAccessTokenError , sessionExpired } from "../../constants/utilis";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

class SalesReport extends Component {
    constructor(props) {
        super(props);
        this.fetchList = this.fetchList.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.funcMap = {
            '1': this.fetchList,
            '2': this.handleSubmit,
        };
        this.state = {
            firebaseData: [],
            firebaseData1: [],
            profile: false,
            startDate: '',
            endDate: '',

            pages: [30, 50, 100, 300],
            page: 0,
            rowsPerPage: 30,
            lastVisible: {},
            count: 0
        };

    }

    componentDidMount() {
        let comp = this
        let count = 0


        var today = new Date()

        // firebase.firestore().collection("Sales")
        // .orderBy("UserId")
        // .get().then((querySnapshot) => {
        //     var loans = [];
        //     querySnapshot.forEach(function(doc) {
        //           count = count + 1
        //     });
        //     comp.setState({
        //       count: count
        //     })
        // });


        // firebase.firestore().collection("Sales")
        // .orderBy("UserId")
        // .limit(this.state.rowsPerPage)
        // // .where("JoiningDate", "==", currentDate)
        // .get().then((querySnapshot) => {
        //     var ags = [];

        //     var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
        //     console.log("last", lastVisible);

        //     querySnapshot.forEach(function(doc) {
        //         ags.push(doc.data())
        //     });
        //     comp.setState({
        //       firebaseData: ags,
        //       lastVisible: lastVisible
        //     })
        // });

        // firebase.firestore().collection("Sales")
        // .orderBy("UserId")
        // .get().then((querySnapshot) => {
        //     var loans = [];
        //     querySnapshot.forEach(function(doc) {
        //           const loan = {
        //             Name: doc.data().Name,
        //             MobileNumber: doc.data().MobileNumber,
        //             EmailId: doc.data().EmailId,
        //             Password: doc.data().Password,
        //             Bank: doc.data().Bank,
        //             Branch: doc.data().Branch,
        //             Location: doc.data().Location,
        //             Status: doc.data().Status,
        //             CreatedAt:doc.data().CreatedAt,
        //           }
        //           loans.push(loan)
        //     });
        //     comp.setState({
        //       firebaseData1: loans
        //     })
        // });

        comp.fetchList()
    }

    async fetchList() {
        let comp = this
    
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/sales',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json'
          },
        };
        
        axios(config)
        .then(function (response) {
            console.log(response.data)
            let downloadData = []
            if(response.status == 200) {
              for(let i=0; i< response.data.length; i++) {
                const loan = {
                    Name: response.data[i].name,
                    MobileNumber: response.data[i].mobilenumber,
                    EmailId: response.data[i].emailid,
                    Bank: response.data[i].bank,
                    Branch: response.data[i].branch,
                    Location: response.data[i].location,
                    Status: response.data[i].status,
                }
                downloadData.push(loan)
              }
              comp.setState({
                firebaseData: response.data,
                firebaseData1: downloadData
              })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "1");
        });
      }

      handleChangePage(event, newPage) {
        this.setState({
          page: newPage
        })
      }
      
      handleChangeRowsPerPage(event) {
        this.setState({
          rowsPerPage: parseInt(event.target.value, 10),
          page: 0
        })
      }

      handleNon200Response = (error, functionIndex) => {
        if(error.response.status == 401 && error.response.data.message == "User Not authenticated") {
          sessionExpired();
        }
      }

    handleSubmit(e) {
        e.preventDefault();
    
        let data = this.state
    
        let comp = this
    
        if(data.startDate === '') {
          alert("Select Start Date");
          document.getElementById("startDate").focus();
          return;
        }
    
        if(data.endDate === '') {
            alert("Select End Date");
            document.getElementById("endDate").focus();
            return;
        }

        var jsonData = JSON.stringify({
          startDate: data.startDate,
          endDate: data.endDate
        });
      
        var config = {
          method: 'post',
          url: API_BASE_URL + '/auth/report/sales',
          headers: { 
            apikey: API_KEY, 
            Authorization: "Bearer " + getCookie("at"),
            'Content-Type': 'application/json',
          },
          data: jsonData
        };
        
        axios(config)
        .then(function (response) {
            console.log(response.data)
            if(response.status == 200) {
                  comp.setState({
                    firebaseData: response.data,
                })
            }
        })
        .catch(function (error) {
          console.log(error);
          comp.handleNon200Response(error, "3");
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
      }

      myFunction() {
        var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
        var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
        input = document.getElementById("search");
        filter = input.value.toUpperCase();
        table = document.getElementById("dataTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
        td1 = tr[i].getElementsByTagName("td")[1];
        td2 = tr[i].getElementsByTagName("td")[2];
        td3 = tr[i].getElementsByTagName("td")[3];
        td4 = tr[i].getElementsByTagName("td")[4];
        td5 = tr[i].getElementsByTagName("td")[5];
        td6 = tr[i].getElementsByTagName("td")[6];
        td7 = tr[i].getElementsByTagName("td")[7];
        td8 = tr[i].getElementsByTagName("td")[8];
        if (td1) {
          txtValue1 = td1.textContent || td1.innerText;
          txtValue2 = td2.textContent || td2.innerText;
          txtValue3 = td3.textContent || td3.innerText;
          txtValue4 = td4.textContent || td4.innerText;
          txtValue5 = td5.textContent || td5.innerText;
          txtValue6 = td6.textContent || td6.innerText;
          txtValue7 = td7.textContent || td7.innerText;
          txtValue8 = td8.textContent || td8.innerText;
        
        var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7+ txtValue8;
          if (main.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
      }


  exportToCSV(csvData, fileName) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
  
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Reports" breadcrumbItem="Sales Report" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                    <Row>
                                            <Col md = "3">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                            <Col md = "2">
                                            <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData1, "Sales Report")} type="submit">Excel</Button>
                                            </Col>
                                        </Row>
                                        <br />
                                    <Row>
                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Start Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.startDate} id = "startDate" name = "startDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 3}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">End Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="date"  value = {this.state.endDate} id = "endDate" name = "endDate" onChange = {this.handleChange.bind(this)}>
                                                      </Input>
                                                    </FormGroup>
                                                </Col>
                                              </Row>
                                              <Button color="primary" onClick = {this.handleSubmit.bind(this)} type="submit">Submit</Button>


                                        <br />
                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Email Id</th>
                                                            <th>Bank</th>
                                                            <th>Branch</th>
                                                            <th>Location</th>
                                                            <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody id = "dataTable">
                                                {this.state.firebaseData.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.name}</td>
                                                        <td>{rowData.mobileNumber}</td>
                                                        <td>{rowData.emailId}</td>
                                                        <td>{rowData.bank}</td>
                                                        <td>{rowData.branch}</td>
                                                        <td>{rowData.location}</td>
                                                        <td>{rowData.status}</td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.firebaseData.length}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                          />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SalesReport;
